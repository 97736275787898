<template>
  <div class="wrapper background mx-auto">
    <notification
      v-if="errorNotification"
      type="error"
      color="red"
    >
      Щось пішло не так. Спробуйте ще раз
    </notification>

    <v-card-title
      class="card-title success py-2 py-sm-3 px-3 text-center body-1 font-weight-medium
      text-sm-h6 justify-end white--text"
    >
      <v-icon
        class="close-btn white--text "
        @click="popUpClosedHandler"
      >
        mdi-close
      </v-icon>
    </v-card-title>

    <div class="position-relative">
      <block-preloader
        v-if="(loaderIsDisplaying && bankCardsAreUploaded === false) || blockPreloaderIsVisible"
        background-color="background"
        class="border-radius-10"
      />
      <v-card-text class="content pt-2 pt-sm-5 pa-0 px-4 text-justify">
        <v-subheader
          v-if="active_cards.length"
          class="text--text height-auto body-1 text-md-h6 text-center d-block mb-3"
        >
          <span class="text--text">Оберіть одну з Ваших карт</span>
        </v-subheader>
        <div
          v-else
          class="d-flex flex-column"
        >
          <p
            class="text-center body-2 mt-2 mb-0 mt-sm-0 text-sm-subtitle-1
         text--text mb-2"
          >
            Ви ще не додали жодної карти
          </p>
          <v-btn
            v-if="!active_cards.length"
            color="success"
            rounded
            class="add_card_btn"
            :to="{ name: addNewBankCardPath }"
          >
            Додати картку
          </v-btn>
        </div>

        <v-row
          no-gutters
        >
          <validation-observer
            v-if="active_cards.length >= 0"
            ref="observer"
            class="col-12 col-sm-10 mx-auto"
          >
            <validation-provider
              v-slot="{ errors, validated }"
            >
              <v-radio-group
                v-model="selectedCardId"
                class="d-flex pa-0 mt-3 col-12"
              >
                <v-divider
                  class="cardDarkGray lighten-3 mb-5"
                />
                <v-col
                  v-for="(card, index) of active_cards"
                  :key="index"
                  class="pa-0 d-flex align-center col-auto mb-3 px-1 px-sm-2"
                >
                  <v-radio
                    :id="`${card.silver_id}`"
                    color="success"
                    :value="card.silver_id"
                    :error="!selectedCardId && validated"
                    :success="selectedCardId && validated "
                    class="ma-0"
                  />
                  <label
                    :for="`${card.silver_id}`"
                    class="ma-0 text--text"
                  >
                    {{ card.card_mask }}
                  </label>
                </v-col>
              </v-radio-group>
              <span
                v-if="selectCardNotation"
                class="error-msg error--text v-messages pl-8 mt-n8 position-absolute"
              >
                Цей пункт обов'язковий
                <!--                {{ errors[0] }}-->
              </span>
            </validation-provider>
            <validation-provider
              v-slot="{ errors, valid, validate }"
              name="Номер кредиту*"
              rules="required|alphaNum"
            >
              <v-text-field
                v-model="creditId"
                dense
                filled
                rounded
                outlined
                type="text"
                color="text"
                label="Номер кредиту*"
                :error-messages="errors"
                :success="valid && creditId !== ''"
                @input="validate($event);"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors, valid, validate }"
              name="Сума"
              :rules="
                `required|onlyNumbers|minValue:${isFullPaymentOfTheLoan? loanDebt:0}|`
              "
            >
              <v-text-field
                v-model="sumValue"
                dense
                filled
                rounded
                outlined
                label="Сума*"
                color="text"
                item-color="text"
                :error-messages="errors"
                :menu-props="{ offsetY: false }"
                :success="valid && sum !== ''"
                @change="validate"
              />
            </validation-provider>
          </validation-observer>
        </v-row>
      </v-card-text>

      <v-row class="ma-0 px-2 pb-4 ">
        <v-row class="justify-space-around mt-5 pb-3 d-flex buttonCont">
          <v-btn
            v-if="active_cards.length > 0"
            color="success"
            rounded
            class="butt"
            :disabled="onSFpayPress"
            @click="aheadClickHandler"
          >
            оплатити
          </v-btn>
          <div class="justify-space-around mt-5 pb-3 d-flex buttonCont butt">
            <span
              v-if="active_cards.length > 0"
              class="text_partner text--text"
            >
              або сплатити на
            </span>
            <v-btn
              color="success"
              outlined
              rounded
              class=" mr-2 ml-2 "
              :disabled="onSFpayPress"
              @click="submit(true)"
            >
              {{ active_cards.length > 0 ? 'сторінці партнера'
                    : 'сплатити на сторінці партнера' }}
            </v-btn>
          </div>
        </v-row>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Notification from '@/components/common/Notification';
import blockPreloaderMixin from '@/mixins/blockPreloaderMixin';
import errorNotificationMixin from '@/mixins/errorNotificationMixin';
import successNotificationMixin from '@/mixins/successNotificationMixin';
import BlockPreloader from '@/components/common/BlockPreloader';
import RouteNamesService from '@/services/route-names-service';

export default {
  name: 'LoanPayment',
  components: { BlockPreloader, Notification },
  mixins: [blockPreloaderMixin, errorNotificationMixin, successNotificationMixin],
  props: {
    isFullPaymentOfTheLoan: Boolean,
    cards: Array,
    creditId: String,
    loanDebt: Number,
    minCreditPaymentValue: Number,
    loaderIsDisplaying: Boolean,
  },
  computed: {
    active_cards() {
      // return this.cards;
      return this.cards.filter((card) => card.if_valid != null && card.if_valid === true);
    },
  },
  data() {
    return {
      addNewBankCardPath: RouteNamesService.addNewBankCard,
      bankCardsAreUploaded: false,
      selectedCardId: 0,
      selectCardNotation: false,
      sumValue: null,
      notificationTimeout: '',
      onSFpayPress: false,
    };
  },
  beforeMount() {
    this.getCardsStore()
      // eslint-disable-next-line no-return-assign
      .then(() => this.bankCardsAreUploaded = true);
    if (this.isFullPaymentOfTheLoan) {
      this.sum = this.loanDebt;
    } else if (!this.isFullPaymentOfTheLoan) {
      this.sum = this.minCreditPaymentValue;
    }
    this.writeDownPayment(this.sum);
  },
  methods: {
    ...mapActions('bankCards', [
      'getCardsStore',
    ]),
    ...mapActions('clientInfo', [
      'creditPaymentStore',
    ]),
    aheadClickHandler() {
      if (this.selectedCardId) {
        this.submit(false);
      } else {
        this.selectCardNotation = true;
        setTimeout(() => { this.selectCardNotation = false; }, 2000);
      }
    },
    writeDownPayment(payment) {
      this.sumValue = Math.ceil(+payment);
    },
    getObjectToSend(iPay) {
      if (iPay) {
        return {
          creditId: this.creditId,
          sum: this.sumValue,
        };
      }
      return {
        creditId: this.creditId,
        clientCard: this.selectedCardId,
        sum: this.sumValue,
      };
    },
    submit(iPay) {
      this.$refs.observer.validate()
        .then((isValid) => {
          if (isValid) {
            this.onSFpayPress = true;
            this.toggleBlockPreloader(true);
            const objToSend = this.getObjectToSend(iPay);
            this.creditPaymentStore(objToSend)
              .then((result) => {
                this.toggleBlockPreloader(false);
                this.notificationTimeout = setTimeout(() => {
                  this.$emit('onPopUpClose');
                  clearTimeout(this.notificationTimeout);
                }, 5000);
                if (result.payload) {
                  // eslint-disable-next-line max-len
                  window.location.href = result.payload; /* если в result приходит ссылка то редиректим на нее */
                  this.$emit('onPopUpClose');
                }
                if (iPay === false) {
                  // eslint-disable-next-line no-unused-expressions
                  (result.success ? this.$router.push({ path: '/paymentResult?success=true' })
                    : this.$router.push({ path: '/paymentResult?success=false' }));
                }
                if (iPay && result.payload === null) {
                  this.$router.push({ path: '/paymentResult?success=false' });
                }
              }).catch((error) => {
                console.error(error);
                this.toggleErrorMessage();
              });
          }
        }).catch((error) => console.error(error));
    },
    popUpClosedHandler() {
      this.$emit('onPopUpClose');
    },
  },
  beforeDestroy() {
    this.onSFpayPress = false;
  },
};

</script>

<style lang="scss" scoped>
.wrapper {
  width: 90vw;

  @media screen and (min-width: 600px) {
    width: 540px;
  }
}
@media screen and (max-width: 600px) {
  .buttonCont{
    flex-direction: column;
  }
  .text_partner{
    margin-top: 10px;
    align-self: end;
  }

}

.card-title {
  border-radius: 10px 10px 0 0;
}
.butt{
  margin: 10px 0px 10px 0px;
  max-width: 90%;
  align-self: center;
}
.text_partner{
  align-self: center;
  margin:10px 0px 10px 0px;
  min-width: 120px;
  max-width: 70%;
}
.add_card_btn{
max-width: 70%;
  align-self: center;
}

</style>
