export default class OrderStatusService {
  static _identification = 'identification';

  static _signature = 'signature';

  static _fillOrder = 'fill_order';

  static _processing = 'processing';

  static get identification() {
    // eslint-disable-next-line no-underscore-dangle
    return this._identification;
  }

  static get signature() {
    // eslint-disable-next-line no-underscore-dangle
    return this._signature;
  }

  static get fillOrder() {
    // eslint-disable-next-line no-underscore-dangle
    return this._fillOrder;
  }

  static get processing() {
    // eslint-disable-next-line no-underscore-dangle
    return this._processing;
  }
}
