<template>
  <div>
    <pop-up-window v-if="mapPopUpIsOpen">
      <google-map
        @onPopUpClose="toggleMapPopUp(false)"
      />
    </pop-up-window>

    <v-footer class="secondBg pa-0 py-5 py-md-7">
      <v-container class="page-container page-fluid">
        <v-row
          no-gutters
          class="secondBg flex-column align-center flex-sm-row align-sm-end"
        >
          <v-col class="pa-0 d-flex flex-column flex-md-row align-center align-sm-start topBlock">
            <v-img
              v-if="$vuetify.theme.dark"
              contain
              title="На головну"
              src="@/assets/sf-logo-text.png"
              class="cursor-pointer home-page-img"
              @click="goToHomePage"
            />
            <v-img
              v-else
              contain
              title="На головну"
              src="@/assets/main-logo.png"
              class="cursor-pointer home-page-img"
              @click="goToHomePage"
            />
            <v-list
              class="secondBg col-12 pa-0 d-flex flex-column align-center align-sm-start my-5
                      ma-sm-0 mt-sm-5 flex-md-row mt-md-0 ml-md-6 ml-lg-7 ml-xl-10"
            >
              <v-list-item
                v-for="(item, index) of links"
                :key="index"
                class="pa-0"
              >
                <v-btn
                  v-if="item.routeName"
                  plain
                  class="text--text text-none font-weight-regular subtitle-2 text-lg-subtitle-1
                hover-link pa-0"
                  :to="{ name: item.routeName }"
                  :href="item.href"
                >
                  {{ item.title }}
                </v-btn>
                <v-btn
                  v-if="item.href"
                  plain
                  class="text--text text-none font-weight-regular subtitle-2 text-lg-subtitle-1
                hover-link pa-0"
                  :href="item.href"
                >
                  {{ item.title }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-col>

          <v-col class="pb-sm-2 d-flex flex-column align-center align-sm-end position-relative
                        flex-lg-row justify-lg-end align-lg-center">
            <div class="pa-0 d-flex flex-column align-center align-sm-end">
              <v-col class="d-flex pa-0 col-auto mt-md-2 justify-md-center align-center">
                <v-icon class="text--text d-none d-md-block mr-1">
                  mdi-face-agent
                </v-icon>
                <a
                  href="tel:+38 044 593 66 33"
                  class="text--text text-decoration-none hover-link font-weight-medium subtitle-2"
                >+38 044 593 66 33</a>
              </v-col>
              <span class="caption">
                Служба турботи про клієнтів
              </span>
            </div>

            <v-list
              v-if="isAuthenticated"
              class="icons-list pa-0 secondBg text--text d-flex align-center mt-4 mt-md-5 mt-lg-0
              ml-lg-7 ml-xl-10"
            >
              <v-list-item class="pa-0 d-flex justify-end mx-2 cursor-pointer">
                <a
                  title="Служба турботи про клієнтів"
                  href="tel:+38 044 593 66 33"
                  class="no-underline"
                >
                  <v-icon>
                    mdi-phone-in-talk-outline
                  </v-icon>
                </a>
              </v-list-item>
              <v-list-item class="pa-0 d-flex justify-end mx-2 cursor-pointer">
                <v-icon>
                  mdi-message-reply-text-outline
                </v-icon>
              </v-list-item>
              <v-list-item class="pa-0 icon-pb-1 d-flex justify-end mx-2 cursor-pointer">
                <a
                  title="Надіслати електронний лист"
                  href="mailto:info@sfactor.com.ua"
                  class="no-underline"
                >
                  <v-icon>
                    mdi-email-outline
                  </v-icon>
                </a>
              </v-list-item>
              <v-list-item class="pa-0 icon-pb-1 d-flex justify-end mx-2 cursor-pointer">
                <v-icon
                  title="Ми на карті"
                  @click="toggleMapPopUp"
                >
                  mdi-map-marker-outline
                </v-icon>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>

    <v-footer class="background py-5">
      <v-container>
        <v-row
          no-gutters
          class="background justify-center caption text--text"
        >
          <p
            class="ma-0 text-center"
          >
            © 2024. Всі права захищені | Design
            <a
              href="https://d3dstudio.biz/"
              class="text--text"
            >
              D3D.studio
            </a>
          </p>
          <p
            class="ma-0 mt-1 col-12 d-flex justify-center text-center"
          >
            ТОВ «Сучасний факторинг»- Свідоцтво №13102578 ФК №270 про реєстрацію фінансової
            установи, видане Нацкомфінпослуг 20.01.2011 р.
          </p>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PopUpWindow from '@/components/common/PopUpWindow';
import GoogleMap from '@/components/footer/GoogleMap';
import RouteNamesService from '@/services/route-names-service';

export default {
  name: 'Footer',
  components: { GoogleMap, PopUpWindow },
  data() {
    return {
      mapPopUpIsOpen: false,
      popUpForComplaintIsOpen: false,
      links: [
        {
          routeName: RouteNamesService.userCredits,
          title: 'Сучасний Факторинг',
        },
        {
          href: 'https://sfactor.com.ua/law',
          title: 'Правова інформація',
        },
        {
          href: 'https://sfactor.com.ua/contacts',
          title: 'Контакти',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
  },
  methods: {
    togglePopUpForComplaint(bool) {
      this.popUpForComplaintIsOpen = bool;
    },
    toggleMapPopUp(bool = true) {
      this.mapPopUpIsOpen = bool;
    },
    goToHomePage() {
      const routeName = RouteNamesService.userCredits;
      if (this.$router.history.current.name !== routeName) {
        this.$router.push({ name: routeName });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .home-page-img {
    max-width: 130px;

    @media screen and (min-width: 1264px) {
      max-width: 140px;
    }

    @media screen and (min-width: 1904px) {
      max-width: 150px;
    }
  }

  .v-list-item {
    min-height: auto !important;
  }

  .icons-list {
    @media screen and (min-width: 960px) and (max-width: 1264px) {
      position: absolute;
      top: 42px;
    }
  }

  .icon-pb-1 {
    padding-bottom: 2px !important;
  }

  .opacity {
    opacity: .8;
  }

  .support {
    @media screen and (min-width: 1400px) and (max-width: 1904px) {
      position: absolute;
      right: -20%;
    }
  }
  @media (max-width: 600px){
    .topBlock{
      margin-bottom: 50px;
    }
  }
</style>
