export default function formatPhoneNumber(phoneNumberString) {
  if (!phoneNumberString) return null;

  // eslint-disable-next-line no-param-reassign
  let cleaned = phoneNumberString.replace('(', '').replace(')', '').replace('-', '').trim();

  if (!cleaned.startsWith('+')) {
    // eslint-disable-next-line no-param-reassign
    cleaned = `+${cleaned}`;
  }

  if (cleaned.length !== 13) return cleaned;

  const arr = cleaned.split('');
  arr[3] = `${arr[3]} (`;
  arr[5] = `${arr[5]}) `;
  arr[8] = `${arr[8]}-`;
  arr[10] = `${arr[10]}-`;
  const correctNumber = arr.join('');
  const match = /^\+[0-9]{3}\s\((\d+)\)\s\d{3}-\d{2}-\d{2}/g.test(correctNumber);

  if (match) {
    return correctNumber;
  }

  return cleaned;
}
