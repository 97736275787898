<template>
  <v-scale-transition mode="in-out">
    <v-col
      class="pa-0 position-fixed menu-wrapper d-flex justify-end secondBg"
      @click="closeMenu"
    >
      <v-col
        class="secondBg px-1 px-sm-7 pt-15 left-side"
      >
        <project-navigation
          class="col-sm-10 pa-0"
          background="secondBg"
        />
      </v-col>

      <v-col
        class="pa-0 menu secondBg pt-3 pb-16 pr-3 d-flex flex-column
              justify-space-between right-side"
      >
        <div>
          <v-row
            no-gutters
            class="justify-end"
          >
            <v-icon
              @click="onMenuClose"
            >
              mdi-window-close
            </v-icon>
          </v-row>

          <nav-items />
        </div>

        <div>
          <app-bar
            class="pl-4"
            @onYourCreditsBtnClick="onMenuClose"
          />
        </div>
      </v-col>
    </v-col>
  </v-scale-transition>
</template>

<script>
import NavItems from '@/components/header/auxiliary-items/NavItems';
import AppBar from '@/components/header/auxiliary-items/AppBar';
import ProjectNavigation from '@/components/common/ProjectNavigation';

export default {
  name: 'MobileMenu',
  components: { ProjectNavigation, AppBar, NavItems },
  data() {
    return {
      navigationBtnData: 'navigation-btn',
    };
  },
  methods: {
    onMenuClose() {
      this.$emit('onMenuClose');
    },
    closeMenu(e) {
      // eslint-disable-next-line no-unused-expressions
      (e.target.parentNode.dataset.name === this.navigationBtnData) ? this.onMenuClose() : null;
    },
  },
};
</script>

<style lang="scss" scoped>
  .menu-wrapper {
    min-height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.7);
    overflow-y: auto;
  }

  .right-side {
    width: 53%;
  }

  /*.menu {
    max-width: 75%;
    height: 100%;

    $screensWidth: ('300px': 70%, '350px': 60%, '400px': 250px, '600px': 300px);

    @each $screenWidth, $menuWidth in $screensWidth{
      @media screen and (min-width: $screenWidth) {
        max-width: $menuWidth;
      }
    }
  }*/
</style>
