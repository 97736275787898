import {
  CHANGE_BOOLEAN_STATE,
  CLEAR_FORM_DATA,
  REMOVE_FROM_INVALID_FIELDS,
  REMOVE_FROM_INVALID_OBJECTS,
  SAVE_INVALID_OBJECTS,
  UPDATE_FORM_DATA,
  UPDATE_STEP,
  CONVERT_INVALID_FIELDS,
  // eslint-disable-next-line import/named
  UPDATE_STATE,
} from '@/store/cashForm/mutation-types';
import convertObjToText from '@/utils/convertObjToText';
import formatPhoneNumber from '../../utils/format-phone-number';
import { CashFormAPI } from '../../api/Rest';

const getDefaultState = () => ({
  currentStep: 1,
  cashFormIsEditing: false,
  hasActiveOrder: false,
  bankCardAddedSuccessfully: true,
  userAgeIsMoreThan25: false,
  userAgeIsMoreThan45: false,

  additional_info: {
    additional_income: '',
    basic_income: '',
    busyness: '',
    company_name: '',
    date_of_employment: '',
    date_of_release: '',
    education_id: 0,
    expenses_of_existing_loan: '',
    family_status_id: 0,
    father_full_name: '',
    father_phone: '',
    have_repaid_loans: false,
    main_source_of_income: 0,
    mother_full_name: '',
    mother_phone: '',
    official_id: 0,
    position_id: 0,
    previous_place_of_work: false,
    product: '',
    regular_expenses: '',
    segment_id: 0,
    spouse_full_name: '',
    invalid_fields: [{ error: '', name: '' }],
  },
  address: {
    accommodation: {
      building: '',
      building_add: '',
      district: '',
      flat: '',
      locality: '',
      locality_type_id: 0,
      region_id: 0,
      street: '',
      street_type_id: 0,
      invalid_fields: [{ error: '', name: '' }],
    },
    addresses_match: false,
    registration: {
      building: '',
      building_add: '',
      district: '',
      flat: '',
      locality: '',
      locality_type_id: 0,
      region_id: 0,
      street: '',
      street_type_id: 0,
      invalid_fields: [{ error: '', name: '' }],
    },
  },
  bank_card: {
    client_card: 0,
    invalid_fields: [{ error: '', name: '' }],
  },
  contact_person: {
    contact_type_id: 0,
    first_name: '',
    gives_consent: false,
    invalid_fields: [{ error: '', name: '' }],
    is_not_pzo: false,
    last_name: '',
    mid_name: '',
    mobile_number: '',
  },
  credit_data: {
    auto_payment: false,
    credit_length: 0,
    credit_payment_type: 0,
    credit_value: 0,
    invalid_fields: [{ error: '', name: '' }],
  },
  document_data: {
    doc_date: '',
    doc_issued_by: '',
    doc_number: '',
    doc_record: '',
    doc_series: '',
    doc_type_id: 1,
    doc_valid_by: '',
    has_photo_25_years: false,
    has_photo_45_years: false,
    has_unique_number_in_registration_certificate: false,
    invalid_fields: [{ error: '', name: '' }],
    is_id_card: false,
  },
  personal_data: {
    birthday: '',
    email: '',
    first_name: '',
    gender_id: 0,
    inn: '',
    invalid_fields: [{ error: '', name: '' }],
    last_name: '',
    mid_name: '',
  },
  signing_contract: {
    confirmation_of_loan_agreement: false,
    loan_passport_confirmation: false,
  },

  // personal_data: {
  //   invalid_fields: [],
  //   last_name: '',
  //   first_name: '',
  //   mid_name: '',
  //   birthday: '',
  //   gender_id: 0,
  //   email: '',
  //   inn: '',
  // },
  // credit_data: {
  //   invalid_fields: [],
  //   credit_length: '',
  //   credit_value: '',
  //   credit_payment_type: null, // np or card
  //   auto_payment: false,
  // },
  // contact_person: {
  //   invalid_fields: [],
  //   mobile_number: '',
  //   contact_type_id: 0,
  //   last_name: '',
  //   first_name: '',
  //   mid_name: '',
  //   gives_consent: false,
  //   is_not_pzo: false,
  // },
  // additional_info: {
  //   product: '',
  //   invalid_fields: [],
  //   education_id: 0,
  //   family_status_id: 0,
  //   spouse_full_name: '',
  //   basic_income: '',
  //   additional_income: '',
  //   busyness: '',
  //   official_id: 0,
  //   regular_expenses: '',
  //   expenses_of_existing_loan: '',
  //   have_repaid_loans: false,
  //   previous_place_of_work: false,
  //   company_name: '',
  //   date_of_employment: '',
  //   position_id: 0,
  //   segment_id: 0,
  //   date_of_release: '',
  //   main_source_of_income: 0,
  //   mother_full_name: '',
  //   mother_phone: '',
  //   father_full_name: '',
  //   father_phone: '',
  // },
  // document_data: {
  //   invalid_fields: [],
  //   is_id_card: false,
  //   doc_type_id: 1, // 1 - Passport, 2 - Id card
  //   doc_series: '', // key for passport series
  //   doc_number: '', // key for id card number ad passport number
  //   doc_record: '',
  //   doc_date: '',
  //   doc_valid_by: '',
  //   doc_issued_by: '',
  //   has_unique_number_in_registration_certificate: null,
  //   has_photo_25_years: null,
  //   has_photo_45_years: null,
  // },
  // address: {
  //   addresses_match: false,
  //   registration: {
  //     invalid_fields: [],
  //     region_id: 0,
  //     district: '',
  //     locality_type_id: 0,
  //     locality: '',
  //     street_type_id: 0,
  //     street: '',
  //     building: '',
  //     building_add: '',
  //     flat: '',
  //   },
  //   accommodation: {
  //     invalid_fields: [],
  //     region_id: 0,
  //     district: '',
  //     locality_type_id: 0,
  //     locality: '',
  //     street_type_id: 0,
  //     street: '',
  //     building: '',
  //     building_add: '',
  //     flat: '',
  //   },
  // },
  // bank_card: {
  //   invalid_fields: [],
  //   client_card: null,
  // },
  // signing_contract: {
  //   loan_passport_confirmation: false,
  //   confirmation_of_loan_agreement: false,
  // },
  // invalidObjects: {},
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    [UPDATE_STEP](state, {
      step = 1,
    }) {
      state.currentStep = step;
    },
    /*
    * @param {string} parentObjName - indicate if we change an object in the state
    * @param {string} childObjName - indicate if the parent object that is in the state has a
    * child object and we change the property in child object
    * if parentObjName and childObjName art not specified, the property inside state changes
    * @param {string} attribute - the name of the property that changes
    * @param {string} value - value for the attribute */
    [UPDATE_FORM_DATA](state, {
      parentObjName = '',
      childObjName = '',
      attribute = '',
      value = '',
    }) {
      if (childObjName) {
        state[parentObjName][childObjName][`${attribute}`] = value;
      } else if (parentObjName) {
        state[parentObjName][`${attribute}`] = value;
      } else {
        state[attribute] = value;
      }
    },
    [CLEAR_FORM_DATA](state, {
      parentObjName = '',
      childObjName = '',
    }) {
      function checkType(element) {
        if (typeof element === 'boolean') {
          return element;
        }
        if (element === null) {
          return '';
        }

        let result = '';
        switch (typeof element) {
          case 'object':
            result = Array.isArray(element) ? [] : {};
            break;
          case 'number':
            result = 0;
            break;
          default:
            result = '';
            break;
        }
        return result;
      }

      if (childObjName) {
        Object.keys(state[parentObjName][childObjName])
          .forEach((key) => {
            state[parentObjName][childObjName][`${key}`] = checkType(state[parentObjName][childObjName][`${key}`]);
          });
      } else if (parentObjName) {
        Object.keys(state[parentObjName])
          .forEach((key) => {
            state[parentObjName][`${key}`] = checkType(state[parentObjName][`${key}`]);
          });
      }
    },
    [CHANGE_BOOLEAN_STATE](state, {
      elementName = '',
      booleanValue = false,
    }) {
      state[elementName] = booleanValue;
    },
    [SAVE_INVALID_OBJECTS](state, {
      objToClone = {},
    }) {
      state.invalidObjects = Object.assign(objToClone);
    },
    [CONVERT_INVALID_FIELDS](state, toArr = false) {
      function convert(arr) {
        const result = toArr ? [] : {};
        if (toArr) {
          return result;
        }
        // eslint-disable-next-line no-return-assign,no-unused-expressions
        Array.isArray(arr) ? arr.forEach((item) => result[item.name] = item.error) : null;
        return result;
      }

      function findInvalidFields(obj = state) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in obj) {
          // eslint-disable-next-line no-prototype-builtins
          if (obj.hasOwnProperty(key) && typeof obj[key] === 'object') {
            findInvalidFields(obj[key]);
            // eslint-disable-next-line no-param-reassign,no-unused-expressions
            (key === 'invalidFields') ? obj[key] = convert(obj[key]) : null;
          }
        }
      }

      findInvalidFields();
    },
    /*
   * @param {string} parentObjName - indicate if we change an object in the state
   * @param {string} childObjName - indicate if the parent object that is in the state has a
   * child object and we change the property in child object
   * if parentObjName and childObjName art not specified, the property inside state changes
   * @param {string} propName - the name of the property that changes */
    [REMOVE_FROM_INVALID_FIELDS](state, {
      parentObjName = '',
      childObjName = '',
      propName = '',
    }) {
      if (childObjName) {
        state[parentObjName][childObjName].invalidFields[`${propName}`] = undefined;
        delete state[parentObjName][childObjName].invalidFields[`${propName}`];
      } else {
        state[parentObjName].invalidFields[`${propName}`] = undefined;
        delete state[parentObjName].invalidFields[`${propName}`];
      }
    },
    [REMOVE_FROM_INVALID_OBJECTS](state, {
      objName = '',
    }) {
      delete state.invalidObjects[objName];
    },
    [UPDATE_STATE](state, sourceObj) {
      // eslint-disable-next-line no-param-reassign
      state = Object.assign(state, sourceObj);
    },
  },
  actions: {
    // LOGIC
    updateStep({ commit }, step) {
      commit(UPDATE_STEP, { step });
    },
    removeFromInvalidFields({ commit }, {
      parentObjName,
      childObjName,
      propName,
      condition,
    }) {
      if (condition) {
        commit(REMOVE_FROM_INVALID_FIELDS, {
          parentObjName,
          childObjName,
          propName,
        });
      }
    },
    // eslint-disable-next-line no-unused-vars
    addOrderStore({ commit }, objToSend) {
      return new Promise((resolve, reject) => {
        CashFormAPI.addOrder(convertObjToText(objToSend))
          .then((data) => {
            if (data.success === true) {
              resolve(data.payload);
            } else {
              reject(new Error('error'));
            }
          })
          .catch((err) => console.log(err));
      });
    },
    getStateStore({ commit }, productCode) {
      return new Promise((resolve) => {
        CashFormAPI.getState(productCode)
          .then((data) => {
            if (data.success === true) {
              if (data.payload) {
                const obj = JSON.parse(data.payload);
                // теперь для скоринга отдельный запрос
                // const scoring = JSON.parse(data.data.data.getState.scoring);
                if (obj && typeof obj === 'object') {
                  commit('UPDATE_STATE', obj);
                  resolve();
                }
              }
            }
          })
          .catch((err) => console.log(err));
      });
    },
    async getLastStateStore({ commit, dispatch }) {
      const dontTouchProps = ['hasPhoto25Years', 'hasPhoto45Years',
        'hasUniqueNumberInRegistrationCertificate'];

      function convertNullToStr(obj) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in obj) {
          // eslint-disable-next-line no-prototype-builtins
          if (obj.hasOwnProperty(key) && typeof obj[key] === 'object' && obj[key] !== null) {
            convertNullToStr(obj[key]);
          } else {
            // eslint-disable-next-line no-param-reassign
            obj[key] = obj[key] === null && !dontTouchProps.includes(key) ? '' : obj[key];
          }
        }
      }

      return new Promise((resolve) => {
        CashFormAPI.getLastState().then((data) => {
          if (data.success) {
            // const getLastStateData = data.payload;
            if (data.payload && data.success) {
              const obj = data.payload;
              // eslint-disable-next-line max-len
              obj.contact_person.mobile_number = formatPhoneNumber(obj.contact_person.mobile_number);
              convertNullToStr(obj);
              commit('UPDATE_STATE', obj);
            }
            // dispatch('auth/updateTokensAndCounter', { mutation: data.auth });
            dispatch('auth/updateTokensAndCounter', { mutation: data.auth }, { root: true });
            resolve();
          }
        }).catch((err) => console.log(err));
      });
    },
    getActiveOrderStore(dispatch) {
      return new Promise((resolve) => {
        // eslint-disable-next-line consistent-return
        CashFormAPI.getActiveOrder().then((data) => {
          resolve(data.payload);
          if (data.success === true) {
            const edges = data.payload;
            if (edges) {
              let arr = [];
              // eslint-disable-next-line array-callback-return
              arr = edges.map((item) => {
              // eslint-disable-next-line no-unused-expressions
                item.state.additional_info.product;
              });
              return arr;
            }
            dispatch('auth/updateTokensAndCounter', { mutation: data.auth }, { root: true });

          // dispatch('auth/updateTokensAndCounter', { mutation: data.auth });
          } else {
            console.error('Помилка при виконанні запиту');
          }
        }).catch((err) => console.error(err));
      });
    },
    getSegmentationStore() {
      return new Promise((resolve) => {
        CashFormAPI.getSegmentation()
          .then((data) => {
            if (data.success === true) {
              const segmentation = data.payload;
              if (segmentation) {
                resolve(segmentation);
              }
            }
          })
          .catch((err) => console.log(err));
      });
    },
    // eslint-disable-next-line no-unused-vars
    cashConfirmationStore({ commit }, orderId) {
      return new Promise((resolve, reject) => {
        CashFormAPI.cashConfirmation(orderId)
          .then((data) => {
            if (data.success === true) {
              resolve(data.success);
            }
            reject();
          })
          .catch((err) => console.log(err));
      });
    },
    // eslint-disable-next-line no-unused-vars
    updateExistingOrderStore({ commit }, { orderId, objToSend }) {
      return new Promise((resolve, reject) => {
        CashFormAPI.updateExistingOrder(orderId, objToSend)
          .then((data) => {
            if (data.success === true) {
              if (data.payload) {
                resolve(true);
              } else {
                reject();
              }
            } else {
              reject();
            }
          })
          .catch((err) => console.log(err));
      });
    },
  },
  getters: {
    isIdCard(state) {
      return state.document_data.is_id_card;
    },
    docTypeId(state) {
      return state.document_data.doc_type_id;
    },
    hasPhoto25Years(state) {
      return state.document_data.has_photo_25_years;
    },
    hasPhoto45Years(state) {
      return state.document_data.has_photo_45_years;
    },
    personal_data(state) {
      return state.personal_data;
    },
  },
};
