export default {
  data() {
    return {
      errorNotification: false,
      errorTimeout: '',
    };
  },
  beforeDestroy() {
    this.errorNotification = false;
  },
  methods: {
    toggleErrorMessage(bool = true, time = 5000) {
      this.errorNotification = bool;
      this.errorTimeout = setTimeout(() => {
        this.toggleErrorMessage(false);
        clearTimeout(this.errorTimeout);
      }, time);
    },
  },
};
