export default function convertObjToText(obj) {
  const string = [];

  if (obj && typeof (obj) === 'object' && (obj.join === undefined)) {
    string.push('{');
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const prop in obj) {
      string.push(prop, ': ', convertObjToText(obj[prop]), ',');
    }
    string.push('}');

    // is array
  } else if (obj && typeof (obj) === 'object' && !(obj.join === undefined)) {
    string.push('[');
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const prop in obj) {
      string.push(convertObjToText(obj[prop]), ',');
    }
    string.push(']');
  } else if (obj && typeof (obj) === 'function') {
    string.push(obj.toString());
  } else {
    string.push(JSON.stringify(obj));
  }

  return string.join('');
}
