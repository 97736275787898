export default class ThemeService {
  static key = 'isDark';

  static saveCurrentTheme(boolValue) {
    localStorage.setItem(this.key, boolValue);
  }

  static getCurrentTheme() {
    const localStorageValue = localStorage.getItem(this.key);
    return localStorageValue ?? false;
  }
}
