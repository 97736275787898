import Vue from 'vue';
import VueParticles from 'particles.vue';
import titleMixin from '@/mixins/titleMixin';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './App';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/vee-validate';
// eslint-disable-next-line import/order
import VueMask from 'v-mask';

library.add(fas);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueParticles);

Vue.mixin(titleMixin);

Vue.use(VueMask);

Vue.config.productionTip = false;

// eslint-disable-next-line consistent-return
Vue.filter('sumFormatter', (value) => {
  if (!value) return '';
  // I take all string, we start from 4 sign values, that's why a startNum equals 7 (1000.00)
  const startNum = 7;
  // eslint-disable-next-line no-param-reassign
  const tt = value.toString();
  const result = tt.split('');
  // function add 0 to the end of array, if number of digits after '.' less than 2
  if (result.includes('.')) {
    const techResult = [...result];
    const indexOfDot = result.indexOf('.');
    const numberOfDigitsAfterDot = techResult.splice(indexOfDot + 1, result.length).length;
    if (numberOfDigitsAfterDot < 2) {
      result.push('0');
    }
  }

  if (!result.includes('.')) {
    if (result.length === 4) {
      result.push('.');
      for (let i = 0; i < 7 - result.length; i + 1) {
        result.push('0');
      }
    } else if (result.length === 5) {
      result.push('.');
      for (let i = 0; i < 8 - result.length; i + 1) {
        result.push('0');
      }
    }
  }
  // eslint-disable-next-line no-nested-ternary,no-unused-expressions
  result.length === startNum ? result[0] = `${result[0]} ` : result.length > startNum
    ? result[result.length - startNum] = `${result[result.length - startNum]} ` : null;
  return result.join('');
});

const app = new Vue({
  data: {
    loaderIsVisible: true,
  },
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

/* // loader start
router.beforeEach((to, from, next) => {
  app.loaderIsVisible = true;
  next();
}); */

// if page load failed
router.afterEach(() => {
  // eslint-disable-next-line no-return-assign
  const timeout = setTimeout(() => {
    if (app.loaderIsVisible) {
      app.loaderIsVisible = false;
      clearTimeout(timeout);
    }
  }, 5000);
});
