<template>
  <div class="buttons col-12 pa-0 ">
    <v-row class="ma-0 justify-center flex-column flex-sm-row">
      <v-col class="pa-0 col-sm-auto mr-sm-4">
        <v-btn
          block
          outlined
          rounded
          color="success"
          @click="continueButtonClick"
        >
          <slot name="success-btn">
            Продовжити
          </slot>
        </v-btn>
      </v-col>
      <v-col
        v-if="!hideButton"
        class="pa-0 col-sm-auto mt-3 mt-sm-0"
      >
        <v-btn
          block
          outlined
          rounded
          color="error"
          @click="clearButtonClick"
        >
          <slot name="cancel-btn">
            Очистити
          </slot>
        </v-btn>
      </v-col>
      <v-col
        v-if="showForgot"
        class="pa-0 col-sm-auto mt-3 mt-sm-0"
      >
        <v-btn
          block
          outlined
          rounded
          color="primary"
          @click="forgotBtnHandler"
        >
          <slot name="cancel-btn">
            Я забув пароль
          </slot>
        </v-btn>
      </v-col>
      <v-col
        v-if="showGoOut"
        class="pa-0 col-sm-auto mt-3 mt-sm-0 go_out-btn"
      >
        <v-btn
          block
          outlined
          rounded
          color="error"
          @click="goOutHandler"
        >
          <slot name="cancel-btn">
            Вийти
          </slot>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ButtonsBlock',
  props: ['hideClearButton', 'showGoOut', 'showForgot'],
  data() {
    return {
      hideButton: this.hideClearButton ? this.hideClearButton : false,
    };
  },
  methods: {
    continueButtonClick() {
      this.$emit('continueButtonClick');
    },
    clearButtonClick() {
      this.$emit('clearButtonClick');
    },
    forgotBtnHandler() {
      this.$emit('changePinPopUp');
    },
    goOutHandler() {
      this.logout();
    },
    ...mapActions('auth', [
      'logout',
    ]),
  },
};
</script>

<style lang="scss">
.go_out-btn{
  margin-left: 20px;
}

@media (max-width: 600px) {
  .go_out-btn{
    margin-left: 0px;
  }
}
</style>
