export default {
  data() {
    return {
      successNotification: false,
    };
  },
  beforeDestroy() {
    this.successNotification = false;
  },
  methods: {
    toggleSuccessMessage(bool = true, time = 5000) {
      this.successNotification = bool;
      const timeout = setTimeout(() => {
        this.toggleSuccessMessage(false);
        clearTimeout(timeout);
      }, time);
    },
  },
};
