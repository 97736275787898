<template>
  <v-scale-transition
    mode="out-in"
  >
    <div class="stub">
      <Particles
        id="tsparticles"
        :particles-loaded="initAnimation"
        :options="particleOptions"
      />
    </div>
  </v-scale-transition>
</template>

<script>
import parseJwt from '@/utils/parseJwt';
import { mapActions, mapGetters, mapState } from 'vuex';
import RouteNamesService from '@/services/route-names-service';
import pageLoaderMixin from '../mixins/pageLoaderMixin';
import workWithProductsMixin from '../mixins/getProductByCode';
// eslint-disable-next-line import/no-cycle
import productRedirect from '../utils/productRedirect';
import OrderStatusService from '../services/order-status-service';

export default {
  name: 'StubPage',
  title: 'Сучасний Факторинг',
  mixins: [pageLoaderMixin, workWithProductsMixin],
  props: {
    authApplicantKey: {
      default: undefined,
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      particleOptions: {
        background: {
          color: {
            value: '#424242',
          },
        },
        fpsLimit: 60,
        interactivity: {
          detectsOn: 'canvas',
          events: {
            onClick: {
              enable: true,
              mode: 'push',
            },
            onHover: {
              enable: true,
              mode: 'repulse',
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 0.8,
              size: 40,
            },
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: '#fff',
          },
          links: {
            color: '#fff',
            distance: 150,
            enable: true,
            opacity: 1,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: 'none',
            enable: true,
            outMode: 'bounce',
            random: false,
            speed: 5,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              value_area: 800,
            },
            value: 80,
          },
          opacity: {
            value: 1,
          },
          shape: {
            type: 'circle',
          },
          size: {
            random: false,
            value: 5,
          },
        },
        fullScreen: {
          enable: true,
          zIndex: 1,
        },
        detectRetina: true,
      },
      allowedParams: ['auth', 'ipay', 'product', 'inv', 'download'],
      currentParamName: '',
      currentParamValue: '',
      paths: {
        cashForm: RouteNamesService.cashForm,
        yourCredits: RouteNamesService.userCredits,
        yourProfile: RouteNamesService.userProfile,
        linkingBankCard: RouteNamesService.linkBankCard,
        contractSigning: RouteNamesService.contractSigning,
        identification: 'UserIdentification',
        messages: RouteNamesService.systemMessages,
      },
    };
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
    ...mapState('auth', [
      'isPinCodeSet',
    ]),
    bankCardAddedSuccessfully: {
      get() {
        return this.$store.state.cashForm.bankCardAddedSuccessfully;
      },

      set(booleanValue) {
        this.$store.commit(
          'cashForm/CHANGE_BOOLEAN_STATE',
          {
            elementName: 'bankCardAddedSuccessfully',
            booleanValue,
          },
        );
      },
    },
  },
  created() {
    this.currentParamName = this.getParam(true);
    this.currentParamValue = this.getParam(false);
    if (this.currentParamValue && this.currentParamName) {
      this.handleJWT(this.currentParamName, this.currentParamValue);
    }
  },
  mounted() {
    this.hidePagePreloader();
  },
  methods: {
    ...mapActions('auth', [
      'logout',
      'getClientMainInfo',
      'checkIfThereIsAccessTokenStore',
      'getInfoAboutSettingPinCodeStore',
    ]),
    ...mapActions('cashForm', [
      'getActiveOrderStore',
    ]),
    ...mapActions([
      'redirectNotifierStore',
    ]),
    ...mapActions('userMessages', [
      'sendInvitationQueryData',
      'requestForExactMessage',
      'getAllUserMessages',
    ]),
    initAnimation() {
      const event = new Event('resize');
      const timeout = setTimeout(() => {
        window.dispatchEvent(event);
        clearTimeout(timeout);
      }, 500);
    },
    getAllParams() {
      return this.$route.query;
    },
    getParam(isName) {
      const params = this.getAllParams();
      let result = '';
      for (let i = 0; i < this.allowedParams.length; i += 1) {
        if (params[this.allowedParams[i]]) {
          result = isName ? this.allowedParams[i] : params[this.allowedParams[i]];
          break;
        }
      }
      return result;
    },
    async handleAuth(obj) {
      // try {
      //   if (obj.key !== undefined) {
      //     await this.$store.dispatch('auth/keyAuthStore', { key: obj.key });
      //     const isProductRedirect = productRedirect.checkIfURLIncludesProductParam();
      //     const timerKey = setTimeout(async () => {
      //       if (this.isPinCodeSet) {
      //         let path = this.paths.cashForm;
      //         const getClientMainInfoResult = await this.getClientMainInfo();
      //         if (getClientMainInfoResult) {
      //           path = this.paths.yourCredits;
      //         }
      //         if (isProductRedirect) {
      //           if (window.location.search.includes(productRedirect.paramName2)) {
      //             this.handleJWT(productRedirect.paramName2,
      //               productRedirect.getJWTFromProductParam());
      //           }
      //           if (window.location.search.includes(productRedirect.paramName1)) {
      //             this.handleJWT(productRedirect.paramName1,
      //               productRedirect.getJWTFromProductParam());
      //           }
      //         } else if (this.$router.history.current.name !== path) {
      //           await this.$router.replace({ name: path });
      //         }
      //         this.$store.commit('auth/AUTHENTICATION_SUCCESS');
      //       } else {
      //         const query = {};
      //         if (isProductRedirect) {
      //           query.product = productRedirect.getJWTFromProductParam();
      //         }
      //         if (this.$router.history.current.name !== this.paths.yourProfile) {
      //           await this.$router.push({ name: this.paths.yourProfile, query });
      //         }
      //       }
      //       clearTimeout(timerKey);
      //     }, 1000);
      //   } else {
      //     let path = this.paths.cashForm;
      //     const getClientMainInfoResult = await this.getClientMainInfo();
      //     if (getClientMainInfoResult) {
      //       path = this.paths.yourCredits;
      //     }
      //     await this.$router.replace({ name: path });
      //   }
      // } catch (error) {
      //   console.error(error);
      // }
      if (obj.key !== undefined) {
        this.$store.dispatch('auth/keyAuthStore', { key: obj.key })
          .then(() => {
            const timeout = setTimeout(() => {
              const isProductRedirect = productRedirect.checkIfURLIncludesProductParam();
              if (this.isPinCodeSet) {
                let path = this.paths.cashForm;
                this.getClientMainInfo()
                  .then((result) => {
                    if (result) {
                      path = this.paths.yourCredits;
                    }
                    if (isProductRedirect) {
                      if (window.location.search.includes(productRedirect.paramName2)) {
                        this.handleJWT(productRedirect.paramName2,
                          productRedirect.getJWTFromProductParam());
                      }
                      if (window.location.search.includes(productRedirect.paramName1)) {
                        this.handleJWT(productRedirect.paramName1,
                          productRedirect.getJWTFromProductParam());
                      }
                    } else if (this.$router.history.current.name !== path) {
                      this.$router.replace({ name: path });
                    }
                    this.$store.commit('auth/AUTHENTICATION_SUCCESS');
                  });
              } else {
                const query = {};
                if (isProductRedirect) {
                  if (window.location.search.includes(productRedirect.paramName1)) {
                    query.product = productRedirect.getJWTFromProductParam();
                  }
                  if (window.location.search.includes(productRedirect.paramName2)) {
                    query.inv = productRedirect.getJWTFromProductParam();
                  }
                }
                if (this.$router.history.current.name !== this.paths.yourProfile) {
                  this.$router.push({ name: this.paths.yourProfile, query });
                }
              }
              clearTimeout(timeout);
            }, 1500);
          });
      } else {
        let path = this.paths.cashForm;
        this.getClientMainInfo()
          .then((result) => {
            if (result) {
              path = this.paths.yourCredits;
            }
            this.$router.replace({ name: path });
          });
      }
    },
    handleIPay(obj) {
      if (obj) {
        const refreshToken = obj.refresh;
        localStorage.removeItem(process.env.VUE_APP_REFRESH_STORAGE_KEY);
        localStorage.setItem(process.env.VUE_APP_REFRESH_STORAGE_KEY, refreshToken);
        this.$router.push({ name: obj.redirect ? obj.redirect : this.paths.yourCredits });
      } else {
        this.$router.push({ name: this.paths.yourCredits });
      }
    },
    // eslint-disable-next-line consistent-return
    async handleProduct(obj) {
      try {
        if (!obj || !obj.product) {
          if (this.$router.history.current.name !== this.paths.yourCredits) {
            await this.$router.push({ name: this.paths.yourCredits });
          }
          return false;
        }
        const activeOrders = await this.getActiveOrderStore();
        const currentProduct = activeOrders
          ? activeOrders.find((item) => item.product === obj.product) : null;
        if (!currentProduct) {
          await this.$router.push({ name: this.paths.yourCredits });
          return false;
        }

        this.redirectNotifierStore(currentProduct.order_id);
        const actionType = currentProduct.action_type;

        if (!actionType) {
          const path = currentProduct.product === 'cache_online'
            ? this.paths.cashForm : this.paths.yourCredits;
          if (this.$router.history.current.name !== path) {
            await this.$router.push({ name: path });
          }
          return false;
        }
        switch (actionType.toLowerCase()) {
          case OrderStatusService.signature:
            await this.$router.push({
              name: RouteNamesService.contractSigning,
              params: { currentOrderId: currentProduct.order_id },
              query: { signature: currentProduct.signature },
            });
            break;
          case OrderStatusService.identification:
            await this.$router.push({
              name: this.paths.identification,
              params: { currentOrderId: currentProduct.order_id },
            });
            break;
          case OrderStatusService.fillOrder:
            await this.$router.push({ name: this.paths.cashForm });
            break;
          default:
            await this.$router.push({ name: this.paths.yourCredits });
            break;
        }
      } catch (error) {
        console.error(error);
      }
    },
    handleInv(obj) {
      this.getInfoAboutSettingPinCodeStore()
        .then(() => {
          const invitationId = obj.inv;
          this.$router.push({ path: `/?inv=${invitationId}` });
        });
    },
    handleDownload(obj) {
      const { token } = obj;
      this.$router.push({ path: `/systemMessages?token=${token}` });
    },
    // eslint-disable-next-line consistent-return
    handleJWT(paramName, JWT) {
      const decodedJWT = parseJwt(JWT);
      switch (paramName) {
        case 'auth':
          this.handleAuth(decodedJWT);
          break;
        case 'ipay':
          this.handleIPay(decodedJWT);
          break;
        case 'product':
          this.handleProduct(decodedJWT);
          break;
        case 'inv':
          this.handleInv(decodedJWT);
          break;
        case 'download':
          this.handleDownload(decodedJWT);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#tsparticles {
  width: 100%;
}

.stub {
  /*position: absolute;
  top: 0;
  left: -50vw;
  right: -50vw;*/
  min-height: 50vh;
}
</style>
