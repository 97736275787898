<template>
  <v-overlay
    :value="isVisible"
    :dark="$vuetify.theme.dark"
    color="secondBg"
    opacity="1"
  >
    <v-progress-circular
      indeterminate
      color="success"
      size="90"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'PagePreloader',
  props: {
    isVisible: Boolean,
  },
};
</script>

<style scoped>

</style>
