import router from '@/router';

export default {
  paramName1: 'product',
  paramName2: 'inv',
  paramName3: 'download',
  defaultJWT: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwcm9kdWN0IjoiY2FjaGVfb25saW5lIn0.13JhBzJQeGCT5FNnCbl2WonA1sSCYCyLhEHrqLyBmus',

  addParamToUrl(paramValue) {
    const queryForRedirect = Object.keys(router.currentRoute.query);
    return `${process.env.VUE_APP_AUTH_URL}?${queryForRedirect}=${paramValue}`;
  },
  checkIfURLIncludesProductParam() {
    if (window.location.search && window.location.search.includes(this.paramName2)) {
      return true;
    }
    if (window.location.search && window.location.search.includes(this.paramName1)) {
      return true;
    }
    if (window.location.search && window.location.search.includes(this.paramName3)) {
      return true;
    }
    return false;
  },
  getJWTFromProductParam() {
    if (router.currentRoute.query?.product !== undefined) {
      return router.currentRoute.query?.product;
    }
    if (router.currentRoute.query?.inv !== undefined) {
      return router.currentRoute.query?.inv;
    }
    if (router.currentRoute.query?.download !== undefined) {
      return router.currentRoute.query?.download;
    }
    return this.defaultJWT;
  },
};
