<template>
  <v-expand-transition
    mode="in-out"
  >
    <v-overlay
      v-if="showOverlay && isPinCodeSet"
      :fixed="true"
      :dark="$vuetify.theme.dark"
      opacity="0.7"
    >
      <v-scale-transition
        group
        mode="in-out"
      >
        <v-alert
          v-for="(error) in serverError.nonFieldErrors"
          :key="error.code"
          :value="refreshAccessError"
          outlined
          text
          type="error"
        >
          {{ error.message }}
        </v-alert>
      </v-scale-transition>
      <v-scale-transition
        group
        mode="in-out"
      >
        <v-alert
          v-for="(error) in serverError.pin"
          :key="error.code"
          :value="refreshAccessError"
          outlined
          text
          type="error"
        >
          {{ error.message }}
        </v-alert>
      </v-scale-transition>
      <v-scale-transition
        group
        mode="in-out"
      >
        <v-alert
          v-for="(error) in globalErrors"
          :key="error.code"
          :value="refreshAccessError"
          outlined
          text
          type="error"
        >
          {{ error.message }}
        </v-alert>
      </v-scale-transition>
      <v-container>
        <v-row
          class="fill-height"
          align="center"
          align-content="center"
          justify="center"
        >
          <v-scale-transition
            mode="in-out"
          >
            <PopUpWindow v-if="changePinPopUp">
              <requestPinCodeChange
                @onPinPopUpClose="changePinPopUp = !changePinPopUp"
                @exitAfterPinChange="logout"
              />
            </PopUpWindow>
            <v-col
              cols="12"
            >
              <v-card
                rounded="xl"
                elevation="12"
                class="mt-12"
                color="background"
              >
                <v-card-title class="text--text" style="word-break: keep-all;text-align: center">
                  Введіть пароль, який ви встановили при реєстрації
                </v-card-title>
                <v-card-text class="pb-4">
                  <validation-observer
                    ref="observer"
                  >
                    <form
                      class="password-form"
                      @submit.prevent="submit"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Пароль"
                        vid="mainPin"
                        mode="eager"
                        rules="required|pinCode|min:4|max:20"
                      >
                        <v-text-field
                          v-model="pin"
                          class="text-field"
                          :error-messages="errors"
                          label="Пароль"
                          rounded
                          filled
                          outlined
                          dense
                          color="input"
                          :autocomplete="autocomplete"
                          :success="errors.length === 0 && pin.length >= 4 && pin.length <= 20"
                          :type="showPin ? 'text' : 'password'"
                          :append-icon="showPin ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append="showPin = !showPin"
                        />
                        <v-icon
                          class="clear_icon"
                          @click="clear"
                        >
                          mdi-autorenew
                        </v-icon>
                      </validation-provider>
                      <buttons-block
                        class="mt-0"
                        hide-clear-button="true"
                        show-forgot="true"
                        show-go-out="true"
                        @continueButtonClick="submit"
                        @changePinPopUp="changePinPopUp = !changePinPopUp"
                      />
                      <v-overlay
                        absolute
                        :dark="$vuetify.theme.dark"
                        :value="refreshAccessLoading"
                        class="rounded-xl"
                      >
                        <v-progress-circular
                          :width="3"
                          color="success"
                          indeterminate
                        />
                      </v-overlay>
                    </form>
                  </validation-observer>
                </v-card-text>
              </v-card>
            </v-col>
          </v-scale-transition>
        </v-row>
      </v-container>
    </v-overlay>
  </v-expand-transition>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ButtonsBlock from '@/components/common/ButtonsBlock';
import RouteNamesService from '@/services/route-names-service';
import PopUpWindow from './common/PopUpWindow';
import RequestPinCodeChange from '../views/user-profile/RequestPinCodeChanging';

export default {
  name: 'Overlay',
  components: { ButtonsBlock, RequestPinCodeChange, PopUpWindow },
  data() {
    return {
      showPin: false,
      pin: '',
      autocomplete: 'new-password',
      changePinPopUp: false,
    };
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
    ...mapState('auth', [
      'pinSetStarted',
      'refreshAccessError',
      'refreshAccessLoading',
      'globalErrors',
      'serverError',
      'isPinCodeSet',
    ]),
    isPageForRedirect() {
      return this.$route.name === RouteNamesService.pageForRedirect && this.pinSetStarted === false;
    },
    isAuthentication() {
      return this.$route.name === 'Authentication' && this.pinSetStarted === false;
    },
    isLostPage() {
      return this.$route.name === RouteNamesService.lostPage;
    },
    dontShowOverlay() {
      return this.isAuthentication || this.isLostPage;
    },
    showOverlay() {
      if (this.dontShowOverlay) {
        return false;
      }
      return this.isAuthenticated === false;
    },
  },
  mounted() {
    this.disableTabKeyForEyeIcons();
  },
  methods: {
    disableTabKeyForEyeIcons() {
      document.querySelectorAll('.password-form .v-icon.mdi').forEach((item) => {
        item.setAttribute('tabindex', '-1');
      });
    },
    submit() {
      this.$refs.observer.validate()
        .then((isValid) => {
          if (isValid) {
            this.$store.dispatch('auth/refreshAccessTokenStore', { pin: this.pin })
              .then((data) => {
                if (data.success) {
                  const timer = setTimeout(() => {
                    this.$router.go(0);
                    clearTimeout(timer);
                  }, 500);
                }
              });
          }
        });
    },
    clear() {
      this.pin = '';
      this.$refs.observer.reset();
    },
    ...mapActions('auth', [
      'logout',
    ]),
    ...mapActions('userMessages', [
      'requestForExactMessage',
      'switchNewMessageNotification',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.text-field{
  position: relative;
}
.clear_icon{
  position: absolute;
  top:73px;
  right: 75px;
  cursor: pointer;
}
.buttons{
  display: flex;
}
@media (max-width: 570px) {
  .clear_icon{
    top:104px;
  }
}
</style>
