<template>
  <div class="v-btn--fixed d-flex justify-center align-center notification">
    <v-scale-transition
      mode="out-in"
    >
      <v-alert
        :type="type"
      >
        <slot />
      </v-alert>
    </v-scale-transition>
  </div>
</template>

<script>
export default {
  name: 'Notification',
  props: {
    type: {
      type: String, // possible types "success, info, warning, error"
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
  .notification {
    top: 2em;
    right: 0;
    padding-right: 2em;
    z-index: 3;

    @media screen and (max-width: 500px) {
      left: 0;
      padding: 0 1.5em;
    }
  }
</style>
