<template>
  <v-row
    no-gutters
  >
    <v-col
      v-for="(img, index) of bankLogos"
      :key="index"
      cols="6"
      sm="3"
      class="pa-1 mb-1"
    >
      <img
        :ref="'logo' + index"
        :src="img"
        alt=""
        class="bank-logo cursor-pointer"
        width="100%"
        @click="selectBank('logo' + index)"
      >
    </v-col>
  </v-row>
</template>

<script>
import privatbank from '@/assets/bank-logos/privatbank.png';
import oschadbank from '@/assets/bank-logos/oschadbank.png';
import monobank from '@/assets/bank-logos/monobank.png';
import avalbank from '@/assets/bank-logos/avalbank.png';
import alfabank from '@/assets/bank-logos/alfabank.png';
import pumbbank from '@/assets/bank-logos/pumbbank.png';
import otpbank from '@/assets/bank-logos/otpbank.png';
import vostokbank from '@/assets/bank-logos/vostokbank.png';
import abank from '@/assets/bank-logos/abank.png';
import pivdennijbank from '@/assets/bank-logos/pivdennijbank.png';
import kredobank from '@/assets/bank-logos/kredobank.png';
import mtbbank from '@/assets/bank-logos/mtbbank.png';
import ideabank from '@/assets/bank-logos/ideabank.png';
import akordbank from '@/assets/bank-logos/akordbank.png';
import concordbank from '@/assets/bank-logos/concordbank.png';
import pravexbank from '@/assets/bank-logos/pravexbank.png';
import axios from 'axios';

export default {
  name: 'BankLogos',
  data() {
    return {
      activeLogoClassName: 'active',
      bankUrl: 'https://id.bank.gov.ua/',
      defaultBankLogos: [
        privatbank,
        oschadbank,
        monobank,
        avalbank,
        alfabank,
        pumbbank,
        otpbank,
        vostokbank,
        abank,
        pivdennijbank,
        kredobank,
        mtbbank,
        ideabank,
        akordbank,
        concordbank,
        pravexbank,
      ],
      bankLogos: [],
      desiredLogos: [
        'privatbank', 'oshadbank', 'monobank', 'avalbank', 'alfabank', 'pumbbank', 'otpbank',
        'bankvostok', 'a-bank', 'pivdenny', 'kredobank', 'mtbbank', 'ideabank', 'accordbank',
        'concordbank', 'pravexbank',
      ],
    };
  },
  beforeMount() {
    this.getBankLogos()
      .then((response) => {
        response.data.forEach((item) => {
          // eslint-disable-next-line no-unused-expressions
          (this.desiredLogos.includes(item.id))
            ? this.bankLogos.push(`${this.bankUrl}${item.logoUrl}`) : null;
        });
      }).catch(() => {
        this.bankLogos = this.defaultBankLogos;
      });
  },
  methods: {
    getBankLogos() {
      const url = `${this.bankUrl}api/banks`;
      return axios.get(url);
    },
    clearSelection() {
      Object.keys(this.$refs).forEach((item) => {
        if (item.startsWith('logo')) {
          this.$refs[item][0].classList.remove(this.activeLogoClassName);
        }
      });
    },
    selectBank(ref) {
      this.clearSelection();
      this.$refs[ref][0].classList.add(this.activeLogoClassName);
    },
  },
};
</script>

<style lang="scss" scoped>
.bank-logo {

  &:hover {
    box-shadow: 1px 1px 5px #b8b8b8;
  }

  &.active {
    box-shadow: 1px 1px 5px #31b52a;
  }
}
</style>
