<template>
  <v-app>
    <v-sheet
      id="my-sheet"
      class="overflow-x-hidden fill-height"
      :dark="$vuetify.theme.dark"
    >
      <PopUpWindow v-if="showNewMessagesHandler && requestedMessage.length !== 0 ">
        <InfoMessagePopUp
          :slides="requestedMessage"
          @PopUpclose="isInfoMessageToogle"
        />
      </PopUpWindow>
      <pin-code-pop-up-window />
      <my-header :class="{ 'z-index-2': isPageForRedirect }" />
      <project-navigation
        class="page-container page-fluid d-none d-md-block d-lg-none mx-auto px-2"
      />
      <main
        class="fill-height py-10"
      >
        <v-scroll-y-transition
          mode="out-in"
        >
          <v-container class="main-container page-container px-3">
            <page-preloader :is-visible="$root.loaderIsVisible" />
            <v-row
              no-gutters
              class="d-flex"
            >
              <v-scale-transition
                mode="out-in"
              >
                <project-navigation
                  v-if="isAuthenticated && navIsDisplaying"
                  class="navigation pa-0 d-none d-lg-block mr-10 mr-xl-13"
                />
              </v-scale-transition>

              <router-view
                :class="[{ 'col-lg-9 pl-lg-16': navIsDisplaying },
                         { 'col-lg-12': !navIsDisplaying }]"
              ></router-view>
            </v-row>

            <support
              v-if="isAuthenticated && navIsDisplaying"
              class="pt-5 d-lg-none"
            />
          </v-container>
        </v-scroll-y-transition>
      </main>
      <my-footer
        id="my-footer"
        :class="[{ 'z-index-2': isPageForRedirect }, 'position-relative']"
      />
    </v-sheet>
  </v-app>
</template>

<script>
import MyHeader from '@/components/header';
import PinCodePopUpWindow from '@/components/PinCodePopUpWindow';
import MyFooter from '@/components/footer';
import { mapActions, mapGetters, mapState } from 'vuex';
import Support from '@/components/common/Support';
import pageLoaderMixin from '@/mixins/pageLoaderMixin';
import ProjectNavigation from '@/components/common/ProjectNavigation';
import RouteNamesService from '@/services/route-names-service';
import PagePreloader from './components/common/PagePreloader';
import ThemeService from './services/theme-service';
import PopUpWindow from './components/common/PopUpWindow';
import InfoMessagePopUp from './components/common/InfoWindowModal';

export default {
  name: 'App',
  components: {
    ProjectNavigation,
    Support,
    PagePreloader,
    MyHeader,
    MyFooter,
    PinCodePopUpWindow,
    PopUpWindow,
    InfoMessagePopUp,
  },
  mixins: [pageLoaderMixin],

  data() {
    return {
      timerScope: String,
    };
  },
  computed: {

    data: {
      get() {
        return this.$store.state.cashForm.additional_info;
      },
    },
    navIsDisplaying() {
      const componentsWithNav = [RouteNamesService.userCredits, RouteNamesService.addNewBankCard,
        RouteNamesService.userBonuses, RouteNamesService.userBankCards,
        RouteNamesService.userProfile, RouteNamesService.creditHistory,
        RouteNamesService.systemMessages];
      return componentsWithNav.includes(this.$route.name);
    },
    isPageForRedirect() {
      return this.$route.name === RouteNamesService.pageForRedirect;
    },
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
    ...mapGetters('userMessages', [
      'requestedMessage',
      'showNewMessagesHandler',
    ]),
    ...mapState('auth', [
      'accessExpiresAt',
      'isPinCodeSet',
    ]),
    ...mapState('cashForm', [
      'personal_data',
    ]),
  },
  beforeMount() {
    this.$store.dispatch('auth/checkIfThereIsAccessTokenStore');
    //* нижче запрос кліентського id для коректного редіректу на сторінку identification *//
    this.getClientMainInfo();
    this.setTheme();
    // window.addEventListener('beforeunload', HttpInterceptorService.removeAccessToken);
    this.getReferenceBookStore('DICT_COMM_MODULE_REFLECTION_TYPE');
    this.requestForExactMessage({ is_read: false }).then(() => {
      this.switchNewMessageNotification(true);
    });
  },
  methods: {
    setTheme() {
      const isDark = ThemeService.getCurrentTheme();
      if (isDark === 'true') {
        this.$vuetify.theme.dark = true;
      }
    },
    hideLoader() {
      const ms = 1000;
      const timeout = setTimeout(() => {
        if (!this.isAuthenticated) {
          this.hidePagePreloader();
          clearTimeout(timeout);
        }
      }, ms);
    },
    ...mapActions(['getReferenceBookStore']),
    ...mapActions('userMessages', [
      'requestForExactMessage',
      'switchNewMessageNotification',
    ]),
    ...mapActions('clientInfo', [
      'getClientMainInfo',
    ]),
    ...mapActions('auth', [
      'renewAccessTokenStore',
    ]),
  },

};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');

body, html {
  overflow-x: hidden;
}

html {

  &.no-scroll {
    overflow-y: hidden;
  }
}

.main-container {
  position: relative !important;
  height: 100%;
}

.page-container {
  @media screen and (min-width: 1904px) {
    max-width: 1250px !important;
  }

  @media screen and (max-width: 960px) {
    max-width: 95% !important;
    width: 95% !important;
  }

  &.page-fluid {
    @media screen and (min-width: 960px) and (max-width: 1100px) {
      max-width: 100% !important;
      width: 100% !important;
    }

    @media screen and (min-width: 1100px) and (max-width: 1264px) {
      max-width: 90% !important;
      width: 90% !important;
    }
  }
}

.for-whole-parent {
  top: 40px;
  display: flex;
  right: 55px;
  justify-content: center;
  align-items: center;
  z-index: 3;

  &.loader {
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    background: #1e1e1eb0;
  }
}

.dark-bg {
  background: var(--v-background-base, #121212) !important;
}

.light-bg {
  background: #efefef !important;
}

.opacity-8 {
  opacity: 0.8;
}

.navigation {
  @media screen and (min-width: 1264px) {
    max-width: 250px;
    width: 250px;
  }
}

.height-100 {
  height: 100% !important;
  min-height: 100% !important;
}

.height-auto {
  height: auto !important;
  min-height: auto !important;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-link {
  &:hover {
    text-decoration: underline !important;
  }
}

.no-underline {
  text-decoration: none !important;

  &:hover {
    text-decoration: none !important;
  }
}

.border-radius-5 {
  border-radius: 5px !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-radius-15 {
  border-radius: 15px !important;
}

.border-radius-inherit {
  border-radius: inherit !important;
}

.border-radius-top-inherit {
  border-top-left-radius: inherit !important;
  border-top-right-radius: inherit !important;
}

.border-radius-bottom-inherit {
  border-bottom-left-radius: inherit !important;
  border-bottom-right-radius: inherit !important;
}

.border-radius-bottom-10 {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.border-radius-50per {
  border-radius: 50%;
}

.border-green {
  border: 1px solid #12b422;

  &-3px {
    border: 3px solid #12b422;
  }
}

.border-black {
  border: 1px solid #424242;
}

.border-red {
  border: 1px solid #ff5252;
}

.box-shadow-none {
  box-shadow: none !important;
}

.box-shadow {
  box-shadow: 4px 3px 5px 3px rgba(0, 0, 0, .16);
}

.position-absolute {
  position: absolute !important;
}

.position-relative {
  position: relative !important;
}

.position-fixed {
  position: fixed !important;
}

.text-nowrap {
  white-space: nowrap;
}

.visibility-hidden {
  visibility: hidden;
}

.v-file-input {
  .v-file-input__text {
    padding-top: 0 !important;
  }
}

.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
  opacity: 1 !important;
}

.custom-scroll {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #555252 #97a194;

  &::-webkit-scrollbar {
    width: 5px;

    &-thumb, &-track {
      border-radius: 3px;
    }

    &-thumb {
      background: #555252;
      box-shadow: inset 0 0 1px #ffffff;
    }

    &-track {
      background: #97a194;
    }
  }
}

.progress-stepper {
  .v-stepper__header {
    height: auto !important;
  }

  .v-stepper__step {
    flex-direction: column !important;
    width: 95%;

    @media screen and (min-width: 300px) {
      width: 85%;
    }

    @media screen and (min-width: 599px) {
      width: auto;
    }

    @media screen and (max-width: 599px) {
      flex-direction: row !important;
      border: 1px solid #9a9a9a;
      border-radius: 7px;

      &--active {
        border: 1px solid #36a935;
      }
    }
  }

  .v-stepper__label {
    @media screen and (min-width: 350px) and (max-width: 599px) {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center !important;
    }
  }

  .v-stepper__step__step {
    background: rgb(140 140 140) !important;
  }

  .v-stepper__step__step, .v-stepper__step__step.primary {
    font-size: 1.5em !important;
    padding: 18px;
    z-index: 2;

    @media screen and (min-width: 599px) {
      padding: 22px;
    }

    @media screen and (min-width: 1264px) {
      padding: 25px;
    }

    /*@media screen and (min-width: 1904px) {
      padding: 40px;
    }*/
  }

  .v-stepper__step__step.primary {
    background-color: #36a935 !important;
  }

  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    @media only screen and (max-width: 959px) {
      display: block !important;
    }
  }
}

.progress-stepper {

  .v-stepper__step {
    @media screen and (max-width: 599px) {
      width: 100%;
    }
  }

  .theme--light {
    .progress-stepper {

      &:not(.v-stepper__step--active) {
        .v-stepper__step__step {
          background: #fff !important;
          box-sizing: border-box;
          border: 2px solid #3b3b3b;
          color: #3b3b3b;
        }
      }
    }

    .v-stepper__step__step {
      background-color: #fff;
      color: #8C8C8C;
      box-sizing: border-box;
    }
  }
}

#my-sheet {
  display: flex;
  flex-direction: column;
  background-color: transparent !important;
}

.v-date-picker-title__year {
  font-size: 3em !important;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

/*#my-footer {
  margin-top:auto;
  position: relative;
  z-index: 2;
}*/
</style>
