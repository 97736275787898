<template>
  <div>
    <pop-up-window v-if="howToPayPopUpIsVisible">
      <how-to-pay @onPopUpClose="howToPaytogglePopUp" />
    </pop-up-window>

    <pop-up-window v-if="firstHeaderPopUp">
      <loan-payment-pop-up
        @onClose="togglefirstHeaderPopUp"
        @onYourCreditsBtnClick="onYourCreditsBtnClick"
        @openPaymentPopUp="togglePayByNumberPopUp"
      />
    </pop-up-window>

    <pop-up-window v-if="isPayByNumberPopUpOpened">
      <loan-payment
        :cards="clientCards"
        :is-full-payment-of-the-loan="false"
        :min-credit-payment-value=null
        @onPopUpClose="togglePayByNumberPopUp"
      />
    </pop-up-window>

    <v-col
      class="d-flex flex-column-reverse text--text pa-0 flex-md-row align-md-center
              justify-md-end"
    >
      <v-icon
        v-if="false"
        class="text--text d-none d-lg-block cursor-pointer"
      >
        mdi-bell-ring-outline
      </v-icon>
      <v-icon
        v-if="false"
        class="text--text ml-5 d-none d-lg-block cursor-pointer"
      >
        mdi-cog-outline
      </v-icon>

      <v-icon
        title="Змінити тему"
        class="text--text mr-2 mr-lg-0 d-none d-md-block cursor-pointer"
        @click="changeTheme"
      >
        mdi-theme-light-dark
      </v-icon>

      <v-col
        :class="[{ 'col-auto pa-0 mr-md-3 mx-lg-5 mx-xl-7': isAuthenticated }]"
      >
        <v-col class="d-flex pa-0 col-auto mt-md-2 justify-md-center">
          <v-icon class="text--text d-none d-md-block mr-1">
            mdi-face-agent
          </v-icon>
          <a
            href="tel:+38 044 593 66 33"
            class="text--text text-decoration-none hover-link font-weight-medium subtitle-2
                    mt-7 mt-md-0 pt-md-1"
          >+38 044 593 66 33</a>
        </v-col>
        <span class="caption">
          Служба турботи про клієнтів
        </span>
      </v-col>

      <div class="d-flex flex-column d-md-block">
        <v-btn
          v-if="isAuthenticated"
          rounded
          color="primary"
          class="body-2 mb-3 d-md-none"
          @click="changeTheme"
        >
          Змінити тему
        </v-btn>
        <v-btn
          v-if="isAuthenticated"
          rounded
          color="success"
          class="body-2"
          @click="togglefirstHeaderPopUp"
        >
          Сплатити
        </v-btn>
        <v-btn
          v-if="isAuthenticated"
          rounded
          outlined
          color="success"
          class="mt-3 mt-md-0 ml-md-3 body-2"
          @click="logout"
        >
          Вийти
        </v-btn>
      </div>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PopUpWindow from '@/components/common/PopUpWindow';
import HowToPay from '@/components/header/pop-up-windows/HowToPay';
import LoanPaymentPopUp from '@/components/header/pop-up-windows/LoanPaymentPopUp';
import ThemeService from '../../../services/theme-service';
import LoanPayment from '../../../views/user-credits/pop-up-windows/LoanPayment';

export default {
  name: 'AppBar',
  components: {
    LoanPayment, LoanPaymentPopUp, HowToPay, PopUpWindow,
  },
  data() {
    return {
      firstHeaderPopUp: false,
      howToPayPopUpIsVisible: false,
      isPayByNumberPopUpOpened: false,
    };
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
    clientCards: {
      get() {
        return this.$store.state.bankCards.clientCards;
      },
      set(value) {
        this.$store.commit('bankCards/UPDATE_STATE', {
          key: 'clientCards',
          value,
        });
      },
    },
  },
  methods: {
    ...mapActions('auth', [
      'logout',
    ]),
    togglefirstHeaderPopUp() {
      this.firstHeaderPopUp = !this.firstHeaderPopUp;
    },
    togglePayByNumberPopUp() {
      this.isPayByNumberPopUpOpened = !this.isPayByNumberPopUpOpened;
    },
    onYourCreditsBtnClick() {
      this.firstHeaderPopUp = false;
    },
    howToPaytogglePopUp() {
      this.howToPayPopUpIsVisible = !this.howToPayPopUpIsVisible;
    },
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      ThemeService.saveCurrentTheme(this.$vuetify.theme.dark);
    },
  },
};
</script>

<style scoped>

</style>
