// eslint-disable-next-line import/named
import { CHANGE_STATE, CLEAR_STATE } from '@/store/documents/mutation-types';

const getDefaultState = () => ({
  documentDataIsSending: false,
  newDocumentPopUpIsOpen: false,
  aboutDocumentPopUpIsOpen: false,
  document: {
    type: '',
    series: '',
    number: '',
    dateOfIssue: '',
    endDate: '',
    issuedBy: '',
  },
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    [CHANGE_STATE](state, { key = '', value = '', parentObjName = '' }) {
      // eslint-disable-next-line no-unused-expressions
      parentObjName ? state[parentObjName][key] = value : state[key] = value;
    },
    [CLEAR_STATE](state, { parentObjName = '' }) {
      if (parentObjName) {
        // eslint-disable-next-line no-unused-vars,no-param-reassign,no-return-assign
        Object.keys(state[parentObjName]).forEach((item) => state[parentObjName][item] = '');
      }
    },
  },
  actions: {
    sendData({ commit }) {
      return new Promise((resolve) => {
        commit(CHANGE_STATE, { key: 'documentDataIsSending', value: true });
        const timeout = setTimeout(() => {
          commit(CHANGE_STATE, { key: 'documentDataIsSending', value: false });
          commit(CHANGE_STATE, { key: 'newDocumentPopUpIsOpen', value: false });
          clearTimeout(timeout);
          resolve();
        }, 1500);
      });
    },
  },
};
