<template>
  <div>
    <v-list :class="[background, 'd-md-flex d-lg-block']">
      <v-list-item
        v-for="(item, index) of nav "
        :key="index"
        :class="['pa-0 height-auto', { 'mt-3 mt-sm-4 mt-md-0 ml-md-2 ml-lg-0 mt-lg-3': index > 0 }]"
        data-name="navigation-btn"
      >
        <v-btn
          exact
          active-class="active"
          class="button position-relative border-radius-10 caption height-auto py-2 py-sm-3
                text-sm-body-2"
          color="success"
          data-name="navigation-btn"
          :to="{ name: item.routeName }"
        >
          <v-icon class="icon position-absolute d-none d-sm-block d-md-none d-lg-block">
            mdi-{{ item.iconName }}
          </v-icon>
          {{ item.title }}
        </v-btn>
      </v-list-item>
    </v-list>

    <support class="mt-5 d-none d-lg-flex" />
  </div>
</template>

<script>
import Support from '@/components/common/Support';
import { mapGetters } from 'vuex';
import RouteNamesService from '@/services/route-names-service';

export default {
  name: 'ProjectNavigation',
  components: { Support },
  props: {
    background: {
      type: String,
      default: 'background',
    },
  },
  data() {
    return {
      nav: [
        {
          title: 'Мої кредити',
          routeName: RouteNamesService.userCredits,
          iconName: 'face-woman-outline',
        },
        // {
        //   title: 'Мої бонуси',
        //   routeName: RouteNamesService.userBonuses,
        //   iconName: 'emoticon-happy-outline',
        // },
        {
          title: 'Мої картки',
          routeName: RouteNamesService.userBankCards,
          iconName: 'credit-card-outline',
        },
        {
          title: 'Мій профіль',
          routeName: RouteNamesService.userProfile,
          iconName: 'account-outline',
        },
        { title: 'Історія', routeName: RouteNamesService.creditHistory, iconName: 'archive-outline' },
        {
          title: 'Повідомлення',
          routeName: RouteNamesService.systemMessages,
          iconName: 'forum-outline',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width:1260px) {
  .messageIconWrapper {
    position: absolute;
  }
  .messageIcon {
    font-size: 16px;
    top:-20px;
    right: -15px;
  }
}
@media screen and (max-width: 1060px) {
  .messageIcon {
    top:-40px;
  }
}
@media screen and (max-width: 960px) {
  .messageIcon {
    font-size: 24px;
  }
}
@media screen and (max-width: 600px) {
  .messageIcon {
    font-size: 16px;
  }
}
.button {
    min-width: 100% !important;
    transition: all .3s ease;

    &:hover {
      background-color: #fff !important;
      color: #424242;
      transition: all .3s ease;
    }
  }

  .v-application {
    .active {
      background-color: #fff !important;
      color: #424242;
      transition: all .3s ease;
    }
  }
  .icon {
    left: 8px;
  }
</style>
