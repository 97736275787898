export const RESET_STATE = 'RESET_STATE';

export const SET_TOKENS = 'SET_TOKENS';
export const SET_TIMER_TIME = 'SET_TIMER_TIME';

export const AUTHENTICATION_START = 'AUTHENTICATION_START';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';

export const ASSIGN_IS_PIN_CODE_SET = 'ASSIGN_IS_PIN_CODE_SET';

export const SET_PIN_START = 'SET_PIN_START';
export const SET_PIN_SUCCESS = 'SET_PIN_SUCCESS';
export const SET_PIN_ERROR = 'SET_PIN_ERROR';

export const REFRESH_ACCESS_START = 'REFRESH_ACCESS_START';
export const REFRESH_ACCESS_SUCCESS = 'REFRESH_ACCESS_SUCCESS';
export const REFRESH_ACCESS_ERROR = 'REFRESH_ACCESS_ERROR';

export const SET_INTERVAL_ID = 'SET_INTERVAL_ID';
export const SET_TIMEOUT_ID = 'SET_TIMEOUT_ID';
export const SET_RENEW_TIMEOUT_ID = 'SET_RENEW_TIMEOUT_ID';
export const REMOVE_RENEW_TIMEOUT_ID = 'REMOVE_RENEW_TIMEOUT_ID';
export const REMOVE_TIMEOUT_ID = 'REMOVE_TIMEOUT_ID';

export const SET_USER_ACTIVE = 'SET_USER_ACTIVE';
export const SET_USER_INACTIVE = 'SET_USER_INACTIVE';

export const CLEAR_INTERVAL = 'CLEAR_INTERVAL';
export const SET_NEW_CREDIT_ORDER_UNAVAILABLE = 'SET_NEW_CREDIT_ORDER_UNAVAILABLE';
export const SET_APP_OFF = 'SET_APP_OFF';
export const SET_CODE_FROM_JWT_AUTH = 'SET_CODE_FROM_JWT_AUTH';
