<template>
  <v-row
    no-gutters
    class="flex-column align-center position-relative">
    <!--    <div class="test-backdrop background position-absolute"></div>-->

    <v-subheader class="text-h6 text--text font-weight-medium">
      Потрібна допомога?
    </v-subheader>

    <v-img
      src="https://static.tildacdn.com/tild3638-3931-4630-a538-666237313137/blob4.png"
      width="130px"
      class="border-radius-50per"
    />

    <p class="ma-0 mt-3 text-h6 font-weight-medium text--text">
      Даниил
    </p>
    <p class="ma-0 text--text body-2 opacity-8">
      твій персональний менеджер
    </p>
    <div>
      <v-app id="inspire" style=" max-height: 210px; overflow: hidden">
        <v-col justify="center">
          <v-expansion-panels accordion>
            <v-expansion-panel
              style="border-radius: 20px">
              <v-expansion-panel-header
                hide-actions
                style="background-color: transparent;
                border-radius: 20px;
                color:#12b422;
                box-shadow: none;
                border:2px solid #12b422;height: 10px;font-weight: bold"
              >
                ОНЛАЙН ЧАТ
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  style="
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 10px"
                >
                  <div style="max-width: 40px;margin: 5px 5px"
                       @click="onClickHandler('https://t.me/sfactor_bot')"
                  >
                    <v-img
                      src="@/assets/media-logos/telegram.png"
                      alt="telegram"
                      style="cursor: pointer"
                    />
                  </div>
                  <div style="max-width: 40px;margin: 5px 5px"
                       @click="onClickHandler('viber://pa?chatURI=sfactor_bot')"
                  >
                    <v-img
                      src="@/assets/media-logos/viber.png"
                      alt="viber"
                      style="cursor: pointer"
                    />
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-app>
    </div>
  </v-row>
</template>

<script>
export default {
  name: 'Support',
  methods: {
    onClickHandler(link) {
      window.open(link, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.test-backdrop {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  opacity: 0.5;
}
</style>
