import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import auth from '@/store/auth';
import clientInfo from '@/store/clientInfo';
import cashForm from '@/store/cashForm';
import bankCards from '@/store/bankCards';
import documents from '@/store/documents';
import userIdentification from '@/store/userIdentification';
import userMessages from '@/store/userMessages';

// eslint-disable-next-line import/named
import {
  SAVE_REFERENCE_BOOK,
  UPDATE_STATE,
  CLEAR_STATE,
  // eslint-disable-next-line import/named
  SAVE_PRODUCTS,
  // eslint-disable-next-line import/named
  REMOVE_ITEM_FROM_REFERENCE_BOOK,
  // eslint-disable-next-line import/named
  UPDATE_ATTACHED_FILES,
  SORT_ATTACHED_FILES,
} from '@/store/mutation-types';
import { ClientInfoApi, MainAPI } from '../api/Rest';

const debug = process.env.NODE_ENV !== 'production';

const graphql = import('@/api/graphql');

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    products: {},
    productsAreUploaded: false,
    referenceBookNodes: [],
    defaultDataForSelect: [
      { value: 'Дані не надані', silverId: 0 },
    ],
    attachedFileTypes: {
      /* passportOfCredit: 2,
      loanAgreement: 1, */
    },
    // attachedFiles: {},
    attachedFiles: [],
  },
  getters: {
    toolBarLoading: (state) => state.auth.authLoading,
    /*
    * @param {string} filterName - the name that is in the object that comes from the backend */
    // eslint-disable-next-line max-len
    referenceBooks: (state) => (filterName) => state.referenceBookNodes.filter((item) => item.name === filterName),
    // eslint-disable-next-line max-len
    getCreditPassport: (state) => (orderId) => state.attachedFiles.find((item) => item.code === `credit_passport${orderId}`),
    // eslint-disable-next-line max-len
    getCreditContract: (state) => (orderId) => state.attachedFiles.find((item) => item.code === `credit_contract${orderId}`),
  },
  mutations: {
    [SAVE_REFERENCE_BOOK](state, { elements }) {
      state.referenceBookNodes.push(...elements);
    },
    [SAVE_PRODUCTS](state, obj) {
      state.products = { ...obj };
    },
    [UPDATE_ATTACHED_FILES](state, files) {
      state.attachedFiles = files;
    },
    [SORT_ATTACHED_FILES](state) {
      state.attachedFiles = state.attachedFiles.sort((a) => {
        if (a.code.includes('credit_passport')) return -1;
        return 1;
      });
    },
    [REMOVE_ITEM_FROM_REFERENCE_BOOK](state, { itemName }) {
      state.referenceBookNodes = state.referenceBookNodes.filter((item) => item.name !== itemName);
    },
    /*
   * @param {string} moduleName - the name of module
   * @param {string} parentObjName - indicate if we change an object in the state
   * @param {string} childObjName - indicate if the parent object that is in the state has a
   * child object, and we change the property in child object
   * if parentObjName and childObjName art not specified, the property inside state changes
   * @param {string} attribute - the name of the property that changes
   * @param {string} value - value for the attribute */
    [UPDATE_STATE](state, {
      moduleName = '',
      parentObjName = '',
      childObjName = '',
      key = '',
      value = '',
    }) {
      const root = moduleName ? state[moduleName] : state;
      if (parentObjName) {
        if (childObjName) {
          root[parentObjName][childObjName][key] = value;
        } else {
          root[parentObjName][key] = value;
        }
      } else {
        root[key] = value;
      }
    },
    [CLEAR_STATE](state, {
      moduleName = '',
      key = '',
      value = '',
    }) {
      const root = moduleName ? state[moduleName] : state;
      root[key] = value;
    },
  },
  modules: {
    auth,
    clientInfo,
    cashForm,
    bankCards,
    documents,
    userIdentification,
    userMessages,
  },
  actions: {
    checkIfAttachedFilesArrHasItem({ state }, { orderId }) {
      let result = false;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < state.attachedFiles.length; i++) {
        if (state.attachedFiles[i].orderId === orderId) {
          result = true;
          break;
        }
      }
      return result;
    },
    getAttachedFileTypes({ commit, getters, dispatch }) {
      return new Promise((resolve) => {
        dispatch('getReferenceBookStore', 'DICT_ATTACHED_FILE_TYPE')
          .then(() => {
            getters.referenceBooks('dict_attached_file_type').forEach((item) => {
              commit('UPDATE_STATE', {
                parentObjName: 'attachedFileTypes',
                key: item.silver_id,
                value: {
                  code: item.code,
                  value: item.value,
                },
              });
            });
            resolve();
          });
      });
    },
    // eslint-disable-next-line consistent-return
    async getAttachedFilesStore({ state, dispatch, commit }, orderId) {
      try {
        const getAttachedFilesData = await ClientInfoApi.getAttachedFiles(orderId);
        const edges = getAttachedFilesData.payload;
        if (edges === null) {
          return false;
        }

        commit('UPDATE_ATTACHED_FILES', []);
        await dispatch('getAttachedFileTypes');
        const arr = [];
        edges.forEach((edge) => {
          const currentFileType = state.attachedFileTypes[edge.file_type];
          if (currentFileType) {
            // eslint-disable-next-line no-param-reassign
            edge.name = currentFileType.value;
            // eslint-disable-next-line no-param-reassign
            edge.code = `${currentFileType.code}${edge.order_id}`;
            arr.push(edge);
          }
        });
        commit('UPDATE_ATTACHED_FILES', arr);
        commit('SORT_ATTACHED_FILES');
        return true;
      } catch (error) {
        console.error(error);
      }
    },
    /* @param {string} payload - takes a string of constants that we want to get */
    getReferenceBookStore({ commit }, payload) {
      MainAPI.getReferenceBook(payload)
        .then((data) => {
          if (!data.errors) {
            const arr = [];
            data.payload.forEach((item) => {
              arr.push(item);
            });
            commit(SAVE_REFERENCE_BOOK, {
              elements: arr,
            });
          }
        })
        .catch((err) => {
          if (err) {
            // eslint-disable-next-line no-console
            console.error(err);
          }
        });
    },
    // eslint-disable-next-line no-unused-vars
    getProductsStore({ state, commit }, payload) {
      return new Promise((resolve) => {
        if (state.productsAreUploaded) {
          resolve();
        } else {
          commit(UPDATE_STATE, { key: 'productsAreUploaded', value: true });
          MainAPI.getProducts()
            .then((data) => {
              if (data.success === true && data.payload.length > 0) {
                commit(CLEAR_STATE, {
                  key: 'products',
                  value: [],
                });
                const obj = {};
                data.payload.forEach((item) => {
                  obj[item.code] = item.name;
                });
                commit(SAVE_PRODUCTS, obj);
                resolve(obj);
              }
            });
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    redirectNotifierStore({ commit }, orderId) {
      graphql.then(({ redirectNotifier }) => {
        redirectNotifier(orderId)
          .then(() => {})
          .catch((err) => console.error(err));
      });
    },
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
