<template>
  <div class="wrapper secondBg">
    <v-card-title
      class="card-title white--text success py-2 py-sm-3 px-3 text-center body-1 font-weight-medium
      text-sm-h6 text-sm-left justify-space-between"
    >
      <span>Сплатити кредит</span>
      <v-icon
        class="cursor-pointer white--text"
        @click="onClose"
      >
        mdi-close
      </v-icon>
    </v-card-title>

    <v-card-text
      class="content pt-2 pt-sm-3 text-justify custom-scroll py-5 d-sm-flex justify-space-between
            position-relative border-radius-10 overflow-hidden"
    >
      <block-preloader
        v-if="blockPreloaderIsVisible"
        background-color="secondBg"
        class="border-bottom-radius-10"
      />

      <template>
        <v-btn
          large
          rounded
          :block="$vuetify.breakpoint.xsOnly"
          color="success"
          class="px-5 font-weight-bold subtitle-2 mt-3
                border-radius"
          @click="goToUserCredits"
        >
          Сплатити мої кредити
        </v-btn>

        <v-btn
          large
          outlined
          rounded
          :block="$vuetify.breakpoint.xsOnly"
          color="success"
          class="px-5 font-weight-bold subtitle-2 mt-5 mt-sm-3"
          @click="togglePayByNumberPopUp"
        >
          Сплатити по номеру
        </v-btn>
      </template>
    </v-card-text>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BlockPreloader from '@/components/common/BlockPreloader';
import RouteNamesService from '@/services/route-names-service';
import blockPreloaderMixin from '../../../mixins/blockPreloaderMixin';

export default {
  name: 'Adding',
  components: { BlockPreloader },
  mixins: [blockPreloaderMixin],
  data() {
    return {};
  },
  computed: {},
  beforeMount() {
    this.blockPreloaderIsVisible = true;
    this.getCardsStore()
      .then(() => {
        this.blockPreloaderIsVisible = false;
        if (this.clientCards && this.clientCards.length <= 0) {
          this.togglePayByNumberPopUp();
        }
      })
      .catch((err) => console.error(err));
  },
  methods: {
    ...mapActions('bankCards', [
      'getCardsStore',
    ]),
    onClose() {
      this.$emit('onClose');
    },
    onYourCreditsBtnClick() {
      this.$emit('onYourCreditsBtnClick');
      this.onClose();
    },
    goToUserCredits() {
      const path = RouteNamesService.userCredits;

      if (this.$router.history.current.name !== path) {
        this.$router.replace({ name: path });
        this.onClose();
      }
      this.onYourCreditsBtnClick();
      this.onClose();
    },
    togglePayByNumberPopUp() {
      this.$emit('openPaymentPopUp');
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 90vw;

  @media screen and (min-width: 600px) {
    width: 500px;
  }

  @media screen and (min-width: 1264px) {
    width: 600px;
  }
}

.content {
  min-height: 100px;
  max-height: 80vh;
  overflow-y: auto;
}

.card-title {
  border-radius: 10px 10px 0 0;
}

.divider {
  height: 1px;
}

.go-to-cabinet-btn {
  letter-spacing: 0;
  height: auto !important;
  margin-top: -2px;

  &:hover {
    text-decoration: underline;
  }
}
</style>
