import router from '@/router';
import {
  AUTHENTICATION_ERROR,
  AUTHENTICATION_START,
  AUTHENTICATION_SUCCESS,
  CLEAR_INTERVAL,
  // eslint-disable-next-line import/named
  ASSIGN_IS_PIN_CODE_SET,
  // eslint-disable-next-line import/named
  REFRESH_ACCESS_ERROR,
  REFRESH_ACCESS_START,
  REFRESH_ACCESS_SUCCESS,
  REMOVE_RENEW_TIMEOUT_ID,
  REMOVE_TIMEOUT_ID,
  RESET_STATE,
  SET_USER_ACTIVE,
  SET_USER_INACTIVE,
  SET_INTERVAL_ID,
  SET_PIN_ERROR,
  SET_PIN_START,
  SET_PIN_SUCCESS,
  SET_RENEW_TIMEOUT_ID,
  SET_TIMEOUT_ID,
  SET_TIMER_TIME,
  SET_TOKENS,
  SET_NEW_CREDIT_ORDER_UNAVAILABLE,
  SET_APP_OFF,
} from '@/store/auth/mutation-types';
import HttpInterceptorService from '@/services/http-interceptor-service';
import RouteNamesService from '@/services/route-names-service';
import productRedirect from '../../utils/productRedirect';

import { AuthAPI } from '../../api/Rest';

const getDefaultState = () => ({
  authLoading: false,
  authError: false,
  authStarted: false,
  refreshAccessLoading: false,
  refreshAccessError: false,
  sendPinLoading: false,
  sendPinError: false,
  globalErrors: [],
  serverError: [],
  timerTime: 0,
  accessExpiresAt: undefined,
  accessValid: false,
  tokensAreGot: false,
  tokensAreSet: false,
  isPinCodeSet: false,
  pinSetStarted: false,
  pinSetEnded: false,
  intervalId: undefined,
  timeoutId: undefined,
  renewTimeoutId: undefined,
  userIsActive: true,
  isNewCreditAvailable: false,
});

function getDifferenceInMillis({ accessExpiresAt }) {
  const accessExpiresAtTimestamp = new Date(accessExpiresAt).getTime();
  const nowUTCTimestamp = new Date(Date.now() + (new Date().getTimezoneOffset() * 60000)).getTime();
  let time = accessExpiresAtTimestamp - nowUTCTimestamp;
  if (Number.isNaN(time) || time < 0) {
    time = 0;
  }
  return time;
}

export default {
  namespaced: true,

  state: getDefaultState(),
  getters: {
    isAuthenticated(state) {
      return state.accessValid === true
        && state.tokensAreSet === true
        && state.accessExpiresAt !== undefined
        && state.isPinCodeSet;
    },
    showSetPinForm(state, getters) {
      return state.isPinCodeSet === false
        && state.pinSetStarted === true
        && state.pinSetEnded === false
        && getters.isAuthenticated === true;
    },
    pinCodeSet(state) {
      return state.isPinCodeSet === true;
    },
    isNewCreditAvailable(state) {
      return state.isNewCreditAvailable;
    },
  },
  mutations: {
    [RESET_STATE](state) {
      Object.assign(state, getDefaultState());
    },
    [SET_TIMER_TIME](state, timerTime) {
      if (Number.isNaN(timerTime) || timerTime <= 0) {
        state.accessValid = false;
      } else {
        state.timerTime = timerTime;
        state.accessValid = true;
      }
    },
    [SET_TOKENS](state, {
      mutation,
      intervalId = undefined,
      timeoutId = undefined,
    }) {
      state.tokensAreGot = true;
      if (intervalId !== undefined) {
        clearInterval(intervalId);
      }
      if (timeoutId !== undefined) {
        clearTimeout(timeoutId);
      }
      const refresh = mutation.refresh === undefined
        ? localStorage.getItem(process.env.VUE_APP_REFRESH_STORAGE_KEY)
        : mutation.refresh;
      if (mutation.access !== undefined) {
        // localStorage.setItem(process.env.VUE_APP_ACCESS_STORAGE_KEY, mutation.access);
        localStorage.setItem(process.env.VUE_APP_ACCESS_STORAGE_KEY, mutation.access);
        localStorage.setItem(process.env.VUE_APP_REFRESH_STORAGE_KEY, refresh);
        state.accessExpiresAt = mutation.accessExpiresAt;
        state.tokensAreSet = true;
      } else {
        state.tokensAreSet = false;
      }
    },
    [AUTHENTICATION_START](state) {
      state.authLoading = true;
      state.authError = false;
      state.authStarted = true;
    },
    [SET_APP_OFF](state) {
      state.accessValid = false;
    },
    [AUTHENTICATION_SUCCESS](state, loading = false) {
      state.authLoading = loading;
      state.authError = false;
    },
    [AUTHENTICATION_ERROR](state, {
      loading = false,
      globalErrors = [],
      serverError = [],
    }) {
      state.authError = true;
      state.authLoading = loading;
      state.serverError = serverError;
      state.globalErrors = globalErrors;
    },
    [ASSIGN_IS_PIN_CODE_SET](state, {
      isPinCodeSet = false,
    }) {
      state.isPinCodeSet = isPinCodeSet;
    },
    [SET_PIN_START](state) {
      state.pinSetStarted = true;
      state.pinSetEnded = false;
    },
    [SET_PIN_SUCCESS](state) {
      state.pinSetStarted = true;
      state.pinSetEnded = true;
      state.isPinCodeSet = true;
    },
    [SET_PIN_ERROR](state) {
      state.pinSetStarted = true;
      state.pinSetEnded = false;
      state.isPinCodeSet = false;
    },
    [SET_INTERVAL_ID](state, intervalId) {
      state.intervalId = intervalId;
    },
    [SET_TIMEOUT_ID](state, timeoutId) {
      state.timeoutId = timeoutId;
    },
    [REFRESH_ACCESS_START](state) {
      state.refreshAccessLoading = true;
      state.refreshAccessError = false;
      state.globalErrors = [];
      state.serverError = [];
    },
    [REFRESH_ACCESS_SUCCESS](state, loading = false) {
      state.refreshAccessLoading = loading;
      state.refreshAccessError = false;
    },
    [REFRESH_ACCESS_ERROR](state, {
      loading = false,
      globalErrors = [],
      serverError = [],
    }) {
      state.refreshAccessError = true;
      state.refreshAccessLoading = loading;
      state.serverError = serverError;
      state.globalErrors = globalErrors;
    },
    [SET_USER_ACTIVE](state) {
      state.userIsActive = true;
    },
    [SET_USER_INACTIVE](state) {
      HttpInterceptorService.removeAccessToken();
      state.userIsActive = false;
      state.renewTimeoutId = undefined;
    },
    [SET_RENEW_TIMEOUT_ID](state, timeoutId) {
      state.renewTimeoutId = timeoutId;
    },
    [REMOVE_RENEW_TIMEOUT_ID](state, timeoutId) {
      clearTimeout(timeoutId);
      state.renewTimeoutId = undefined;
    },
    [REMOVE_TIMEOUT_ID](state, timeoutId) {
      clearTimeout(timeoutId);
      state.timeoutId = undefined;
    },
    [CLEAR_INTERVAL](state, { idOfInterval, timerTime }) {
      clearInterval(idOfInterval);
      state.intervalId = undefined;

      if (Number.isNaN(timerTime) || timerTime <= 0) {
        state.accessValid = false;
        state.timerTime = 0;
        state.accessExpiresAt = undefined;
      } else {
        state.timerTime = timerTime;
        state.accessValid = true;
      }
    },
    [SET_NEW_CREDIT_ORDER_UNAVAILABLE](state, value) {
      state.isNewCreditAvailable = value;
    },
  },
  actions: {
    checkIfThereIsAccessTokenStore({ dispatch }) {
      return new Promise((res, rej) => {
        // eslint-disable-next-line import/no-named-as-default-member
        AuthAPI.newAccessToken().then((data) => {
          if (data.success) {
            dispatch('renewAccessTokenStore');
            res(data);
          } else {
            rej(data);
          }
        }).catch((err) => console.error(err));
      });
    },
    logout({ commit, dispatch, state }) {
      dispatch('removeRenewTimeoutId');
      if (state.intervalId !== undefined) {
        commit(CLEAR_INTERVAL, state.intervalId);
      }
      if (state.timeoutId !== undefined) {
        commit(REMOVE_TIMEOUT_ID, state.timeoutId);
      }
      localStorage.removeItem(process.env.VUE_APP_REFRESH_STORAGE_KEY);
      localStorage.removeItem(process.env.VUE_APP_ACCESS_STORAGE_KEY);
      // localStorage.removeItem(process.env.VUE_APP_ACCESS_STORAGE_KEY);
      commit(RESET_STATE);
      dispatch('setTimeoutAndRedirect', { seconds: 0 });
    },
    setUserInactive({ commit, dispatch }) {
      dispatch('checkUserActivity', false);
      commit(SET_USER_INACTIVE);
    },
    isStaffToogle({ commit, dispatch }) {
      AuthAPI.checkUserScope()
        .then((data) => {
          if (data.success === true) {
            const valueAccess = data.payload[0].value;
            commit(SET_NEW_CREDIT_ORDER_UNAVAILABLE, valueAccess);
            dispatch('updateTokensAndCounter', { mutation: data.auth });
          }
        })
        .catch((err) => console.log(err));
    },
    startActivityCheck({ dispatch, commit }) {
      const idOfRenewTimeout = setTimeout(() => {
        dispatch('setUserInactive');
      }, ((Number(process.env.VUE_APP_SECONDS_TIMER_WILL_SHOW) - 1) * 1000));
      commit(SET_RENEW_TIMEOUT_ID, idOfRenewTimeout);
    },
    removeRenewTimeoutId({ commit, state }) {
      if (state.renewTimeoutId !== undefined) {
        commit(REMOVE_RENEW_TIMEOUT_ID, state.renewTimeoutId);
      }
    },
    resetActivityCheck({ commit, dispatch }, keepUserActive = true) {
      dispatch('removeRenewTimeoutId');
      if (keepUserActive) {
        commit(SET_USER_ACTIVE);
      }
    },
    setupActivityCheck({ dispatch }) {
      dispatch('checkUserActivity');
      dispatch('startActivityCheck');
    },
    checkUserActivity({ dispatch }, userIsActive = true) {
      function onUserAction(keepUserActive = true) {
        document.removeEventListener('mousemove', onUserAction);
        document.removeEventListener('mousedown', onUserAction);
        document.removeEventListener('keypress', onUserAction);
        document.removeEventListener('touchmove', onUserAction);
        dispatch('resetActivityCheck', keepUserActive);
      }

      if (userIsActive) {
        document.addEventListener('mousemove', onUserAction, false);
        document.addEventListener('mousedown', onUserAction, false);
        document.addEventListener('keypress', onUserAction, false);
        document.addEventListener('touchmove', onUserAction, false);
      } else {
        onUserAction(false);
      }
    },
    setPin({ commit }) {
      commit(SET_PIN_START);
    },
    startInterval({ commit, state, dispatch }, {
      accessExpiresAt,
    }) {
      dispatch('resetActivityCheck');
      const idOfInterval = setInterval(() => {
        const timerTime = getDifferenceInMillis(accessExpiresAt);
        if (
          timerTime === 0
              || timerTime > Number(process.env.VUE_APP_SECONDS_TIMER_WILL_SHOW) * 1000
        ) {
          commit(CLEAR_INTERVAL, { idOfInterval, timerTime });
          if (state.isPinCodeSet === false) {
            window.location.href = process.env.VUE_APP_AUTH_URL;
          }
        } else if (
          state.renewTimeoutId === undefined
          && timerTime < (10 * 1000)
          && state.userIsActive === true
        ) {
          dispatch('renewAccessTokenStore');
        } else {
          commit(SET_TIMER_TIME, timerTime);
        }
      }, 250);
      commit(SET_INTERVAL_ID, idOfInterval);
      dispatch('setupActivityCheck');
    },
    switchPinFormActivated({ commit }, accessExpiresAt) {
      const dyeTimer = setTimeout(() => {
        commit(SET_APP_OFF);
        clearTimeout(dyeTimer);
      }, Math.floor(getDifferenceInMillis(accessExpiresAt)));
    },
    startCountdown({ commit, dispatch }, accessExpiresAt) {
      if (
        getDifferenceInMillis(accessExpiresAt)
        > (Number(process.env.VUE_APP_SECONDS_TIMER_WILL_SHOW) * 1000)
      ) {
        commit(SET_TIMER_TIME, getDifferenceInMillis(accessExpiresAt));
        const idOfTimeout = setTimeout(() => {
          commit(SET_TIMEOUT_ID, undefined);
          dispatch('startInterval', { accessExpiresAt });
          // dispatch('startInterval', { accessExpiresAt: '2021-08-30T13:40:53.139076' });
        }, (getDifferenceInMillis(accessExpiresAt)
          - Number(process.env.VUE_APP_SECONDS_TIMER_WILL_SHOW) * 1000
        ));
        commit(SET_TIMEOUT_ID, idOfTimeout);
      } else if (
        getDifferenceInMillis(accessExpiresAt) < (
          Number(process.env.VUE_APP_SECONDS_TIMER_WILL_SHOW) * 1000)
      ) {
        dispatch('startInterval', { accessExpiresAt });
      }
    },
    setTimeoutAndRedirect({ commit }, {
      seconds = Number(process.env.VUE_APP_DEFAULT_TIMEOUT_TO_READ),
    }) {
      if (process.env.VUE_APP_DO_REDIRECT === 'true') {
        const timeout = setTimeout(() => {
          commit(RESET_STATE);
          window.location.href = process.env.VUE_APP_AUTH_URL;
          clearTimeout(timeout);
        }, 100 * seconds);
      }
    },
    updateTokensAndCounter({ commit, dispatch }, {
      mutation,
      intervalId = undefined,
      timeoutId = undefined,
    }) {
      commit(SET_TOKENS, { mutation, intervalId, timeoutId });
      dispatch('switchPinFormActivated', {
        accessExpiresAt: mutation.accessExpiresAt,
      });
      dispatch('startCountdown', {
        accessExpiresAt: mutation.accessExpiresAt,
      });
      dispatch('clientInfo/updateClientInfo', undefined, { root: true });
    },
    keyAuthStore({ dispatch, commit }, payload) {
      localStorage.removeItem(process.env.VUE_APP_REFRESH_STORAGE_KEY);
      localStorage.removeItem(process.env.VUE_APP_ACCESS_STORAGE_KEY);
      return new Promise((resolve) => {
        commit(AUTHENTICATION_START);
        AuthAPI.keyAuth(payload)
          .then((data) => {
            if (data.success === false) {
              commit(AUTHENTICATION_ERROR, {
                globalErrors: data.errors,
              });
              dispatch('setTimeoutAndRedirect', {});
              resolve();
            } else if (data.payload === null) {
              commit(AUTHENTICATION_ERROR, {
                serverError: data.data.keyAuth.errors,
              });
              dispatch('setTimeoutAndRedirect', {});
              resolve();
            } else {
              dispatch('updateTokensAndCounter', { mutation: data.auth });
              commit(ASSIGN_IS_PIN_CODE_SET, { isPinCodeSet: data.payload.isPinSet });

              if (!data.payload.isPinSet) {
                commit(AUTHENTICATION_SUCCESS);
                dispatch('setPin');
              }
              resolve();
            }
          })
          .catch((err) => {
            if (err) {
              commit(AUTHENTICATION_ERROR, {
                globalErrors: [{
                  message: 'Щось пішло не по плану... Спробуйте трішки пізніше',
                  code: 'serverError',
                }],
              });
              dispatch('setTimeoutAndRedirect', {});
            }
          });
      });
    },
    // eslint-disable-next-line no-empty-pattern
    getClientMainInfo({}, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve) => {
        const dataToSend = payload || 'clientId';
        AuthAPI.getMe(dataToSend)
          .then((data) => {
            const me = data.payload;
            if (me) {
              resolve(me.client_id);
            } else { resolve(false); }
          })
          .catch((err) => console.error(err));
      });
    },
    setClientsPinStore({ commit, dispatch, state }, payload) {
      return new Promise((resolve) => {
        AuthAPI.setClientsPin(payload)
          .then((data) => {
            dispatch('updateTokensAndCounter', {
              mutation: data.auth,
              intervalId: state.intervalId,
              timeoutId: state.timeoutId,
            });
            if (data.errors !== undefined && '') {
              commit(SET_PIN_ERROR);
              commit(AUTHENTICATION_ERROR, {
                globalErrors: data.errors,
              });
              resolve(data);
            } else if (data.success === false) {
              commit(SET_PIN_ERROR);
              commit(AUTHENTICATION_ERROR, {
                serverError: data.payload.errors,
              });
            } else {
              commit(SET_PIN_SUCCESS);
              const timeout = setTimeout(() => {
                clearTimeout(timeout);
                resolve(data);
              }, 300);
            }
          })
          .catch((err) => {
            if (err) {
              commit(SET_PIN_ERROR);
              commit(AUTHENTICATION_ERROR, {
                globalErrors: [{
                  message: 'Щось пішло не по плану... Спробуйте трішки пізніше',
                  code: 'serverError',
                }],
              });
              dispatch('setTimeoutAndRedirect', {});
            }
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    updateClientsPinStore({ commit }, { oldPin = '', newPin = '' }) {
      return new Promise((resolve, reject) => {
        AuthAPI.updateClientsPin(oldPin, newPin)
          .then((data) => {
            if (data.status === 200) {
              resolve(data.payload);
            }
            reject();
          })
          .catch((err) => console.log(err));
      });
    },
    // refreshAccessTokenStore({ commit, dispatch, state }, payload) {
    //   commit(REFRESH_ACCESS_START);
    //   AuthAPI.refreshAccessToken(payload)
    //     .then((data) => {
    //       if (data.errors) {
    //         commit(REFRESH_ACCESS_ERROR, {
    //           globalErrors: [{ pin: [{ message: data.errors, code: 'wrongPin' }] }],
    //         });
    //       } else if (data.success === false) {
    //         Object.values(data.errors).forEach((elem) => {
    //           if (elem.code === 'invalidPin') {
    //             window.location.href = process.env.VUE_APP_AUTH_URL;
    //           }
    //         });
    //         if (data.data.refreshAccessToken.errors.refresh) {
    //           window.location.href = process.env.VUE_APP_AUTH_URL;
    //         }
    //         commit(REFRESH_ACCESS_ERROR, {
    //           serverError: data.errors,
    //         });
    //       } else {
    //         commit(REFRESH_ACCESS_SUCCESS);
    //         dispatch('updateTokensAndCounter', {
    //           mutation: data.auth,
    //           intervalId: state.intervalId,
    //           timeoutId: state.timeoutId,
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       if (err) {
    //         commit(REFRESH_ACCESS_ERROR, {
    //           globalErrors: [{
    //             message: 'Щось пішло не по плану... Спробуйте трішки пізніше',
    //             code: 'serverError',
    //           }],
    //         });
    //         dispatch('setTimeoutAndRedirect', {});
    //       }
    //     });
    // },
    refreshAccessTokenStore({ commit, dispatch, state }, payload) {
      return new Promise((resolve) => {
        commit(REFRESH_ACCESS_START);
        AuthAPI.refreshAccessToken(payload)
          .then((data) => {
            if (data.errors && data.success === false) {
              if (data.errors === 'invalidPin') {
                commit(REFRESH_ACCESS_ERROR, {
                  serverError: { pin: [{ message: 'Пін код не вірний', code: 'wrongPin' }] },
                });
              }
            } else {
              commit(REFRESH_ACCESS_SUCCESS);
              dispatch('updateTokensAndCounter', {
                mutation: data.auth,
                intervalId: state.intervalId,
                timeoutId: state.timeoutId,
              });
              resolve(data);
            }
          })
          .catch((err) => {
            if (err) {
              commit(REFRESH_ACCESS_ERROR, {
                globalErrors: [{
                  message: 'Щось пішло не по плану... Спробуйте трішки пізніше',
                  code: 'serverError',
                }],
              });
              dispatch('setTimeoutAndRedirect', {});
            }
          });
      });
    },
    renewAccessTokenStore({ dispatch, state }) {
      AuthAPI.getMe().then((data) => {
        if (data.success === true) {
          dispatch('updateTokensAndCounter', {
            mutation: data.auth,
            intervalId: state.intervalId,
            timeoutId: state.timeoutId,
          });
        }
      })
        .catch((err) => {
          if (err) {
            console.error(err);
          }
        })
        .catch((err) => {
          if (err) {
            console.error(err);
          }
        });
    },
    // getInfoAboutSettingPinCodeStore({
    //   commit, getters, state,
    // }) {
    //   AuthAPI.getInfoAboutSettingPinCode({
    //     refresh: localStorage.getItem(process.env.VUE_APP_REFRESH_STORAGE_KEY),
    //   })
    //     .then((data) => {
    //       // eslint-disable-next-line no-debugger
    //       if (data.payload === true) {
    //         // eslint-disable-next-line no-debugger
    //         commit(ASSIGN_IS_PIN_CODE_SET, { isPinCodeSet: data.payload });
    //       } else {
    //         const isNotPageForRedirect = Object.keys(router.history.current.query).length
    //                 <= 0 && router.history.current.name !== RouteNamesService.pageForRedirect;
    //         // eslint-disable-next-line max-len
    //         if (isNotPageForRedirect && router.history.current.name
    //                 !== RouteNamesService.userProfile) {
    //           router.push({ name: RouteNamesService.userProfile }).catch(() => {});
    //         }
    //         console.error('Щось пішло не по плану');
    //       }
    //       if (
    //         !localStorage.getItem('REFRESH_TOKEN_STORAGE_KEY')
    //               && !getters.isAuthenticated && !state.authStarted
    //               && !router.currentRoute.query.key
    //       ) {
    //         if (router.currentRoute.name === 'Authentication') {
    //         // eslint-disable-next-line no-unused-expressions
    //           !router.currentRoute.query.key
    //             ? window.location.href = process.env.VUE_APP_AUTH_URL : null;
    //         } else {
    //           const isProductRedirect = productRedirect.checkIfURLIncludesProductParam();
    //           let redirectUrl = process.env.VUE_APP_AUTH_URL;
    //           if (isProductRedirect) {
    //             const JWTToken = productRedirect.getJWTFromProductParam();
    //             redirectUrl = productRedirect.addParamToUrl(JWTToken);
    //           }
    //           window.location.href = redirectUrl;
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //     });
    // },
    getInfoAboutSettingPinCodeStore({ commit, getters, state }) {
      return new Promise((resolve, reject) => {
        AuthAPI.getInfoAboutSettingPinCode(
          { refresh: localStorage.getItem(process.env.VUE_APP_REFRESH_STORAGE_KEY) },
        )
          .then((data) => {
            if (data.payload === true && data.success === true) {
              commit(ASSIGN_IS_PIN_CODE_SET, { isPinCodeSet: data.payload });
              resolve(data.payload);
            } else if (data.payload === false && data.success === true) {
              const isNotPageForRedirect = Object.keys(router.history.current.query).length
                    <= 0 && router.history.current.name !== RouteNamesService.pageForRedirect;

              // eslint-disable-next-line max-len
              if (isNotPageForRedirect && router.history.current.name !== RouteNamesService.userProfile) {
                router.push({ name: RouteNamesService.userProfile }).catch(() => {});
              }
              reject(new Error('Щось пішло не по плану'));
            } else if (data.payload === false && data.success === false
              && state.isPinCodeSet === false) {
              window.location.href = process.env.VUE_APP_AUTH_URL;
            }

            if (
              !localStorage.getItem('REFRESH_TOKEN_STORAGE_KEY')
                  && !getters.isAuthenticated && !state.authStarted
                  && !router.currentRoute.query.key
            ) {
              if (router.currentRoute.name === 'Authentication') {
                // eslint-disable-next-line no-unused-expressions
                !router.currentRoute.query.key
                  ? window.location.href = process.env.VUE_APP_AUTH_URL : null;
              } else {
                const isProductRedirect = productRedirect.checkIfURLIncludesProductParam();
                let redirectUrl = process.env.VUE_APP_AUTH_URL;
                if (isProductRedirect) {
                  const JWTToken = productRedirect.getJWTFromProductParam();
                  redirectUrl = productRedirect.addParamToUrl(JWTToken);
                }
                window.location.href = redirectUrl;
              }
            }
          })
          .catch((err) => {
            if (err) {
              reject(new Error('Щось пішло не по плану'));
            }
          });
      });
    },
    addOrderAttempt(product) {
      AuthAPI.addOrderAttempt(product);
    },
    // eslint-disable-next-line no-empty-pattern
    setNewPinCode({}, payload) {
      return new Promise((resolve, reject) => {
        AuthAPI.forgotPassword(payload).then((data) => {
          if (data.success === true) {
            resolve(data);
          }
          reject();
        }).catch((err) => console.log(err));
      });
    },
  },
};
