<template>
  <v-row no-gutters>
    <v-img
      v-if="$vuetify.theme.dark"
      contain
      title="На головну"
      src="@/assets/sf-logo-text.png"
      class="cursor-pointer home-page-img d-none d-md-block"
      @click="goToHomePage"
    />
    <v-img
      v-else
      contain
      title="На головну"
      src="@/assets/main-logo.png"
      class="cursor-pointer home-page-img d-none d-md-block"
      @click="goToHomePage"
    />
    <v-list class="secondBg d-flex flex-column flex-md-row ml-lg-3 ml-xl-6">
      <v-list-item
        v-for="(item, index) of nav"
        :key="index"
        class="pa-0"
      >
        <v-btn
          v-if="item.routeName"
          plain
          class="text--text text-none font-weight-regular subtitle-2 text-lg-subtitle-1 hover-link
                pa-0"
          :to="{ name: item.routeName }"
          :href="item.href"
        >
          {{ item.text }}
        </v-btn>
        <v-btn
          v-if="item.href"
          plain
          class="text--text text-none font-weight-regular subtitle-2 text-lg-subtitle-1 hover-link"
          :href="item.href"
        >
          {{ item.text }}
        </v-btn>
      </v-list-item>
    </v-list>
  </v-row>
</template>

<script>
import RouteNamesService from '@/services/route-names-service';

export default {
  name: 'NavItems',
  data() {
    return {
      nav: [
        {
          text: 'Про нас',
          routeName: '',
          href: 'http://sfactor.com.ua/',
        },
        {
          text: 'Кредит',
          routeName: '',
          href: 'http://sfactor.com.ua/',
        },
        {
          text: 'Акції',
          routeName: '',
          href: 'http://sfactor.com.ua/',
        },
        {
          text: 'Питання-відповідь',
          routeName: '',
          href: '/FAQ',
        },
      ],
    };
  },
  methods: {
    goToHomePage() {
      const routeName = RouteNamesService.userCredits;
      if (this.$router.history.current.name !== routeName) {
        this.$router.push({ name: routeName });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .home-page-img {
    max-width: 130px;

    @media screen and (min-width: 1264px) {
      max-width: 140px;
    }

    @media screen and (min-width: 1904px) {
      max-width: 150px;
    }
  }
</style>
