<template>
  <v-scale-transition mode="in-out">
    <div
      v-if="isAuthenticated"
      class="border-radius-10 border-green py-5 px-3 px-sm-4 pb-sm-10 box-shadow px-md-16 pt-md-10
             pb-md-14"
    >
      <notification
        v-if="errorNotification"
        type="error"
      >
        Щось пішло не так. Спробуйте ще раз
      </notification>

      <notification
        v-if="successNotification"
        type="success"
      >
        Фото документів успішно відправлені!
      </notification>

      <v-subheader class="text--text pa-0 col-12 text-h6">
        Ідентифікація
      </v-subheader>

      <template v-if="false">
        <v-row
          no-gutters
          class="mt-3 col-12"
        >
          <p>
            Оберіть банк де ви маєте картку або скористайтесь додатком
            <span class="font-italic hover-link cursor-pointer">Дія</span>
          </p>
        </v-row>

        <bank-logos class="col-12 justify-center justify-md-start mt-md-10 col-md-10" />

        <v-row
          no-gutters
          class="col-12 mt-5 justify-center justify-md-start"
        >
          <v-btn
            outlined
            :block="$vuetify.breakpoint.xsOnly"
            color="success darken-1"
          >
            Продовжити
          </v-btn>

          <v-btn
            outlined
            :block="$vuetify.breakpoint.xsOnly"
            color="success darken-1"
            class="mt-5 mt-sm-0 ml-sm-10"
          >
            Підключити Дія
          </v-btn>
        </v-row>
      </template>

      <v-row
        v-if="false"
        no-gutters
      >
        <v-checkbox
          v-model="dontHasBankCard"
          label="Я не маю картку банка"
          color="success darken-2"
          class="dark-checkbox pa-0"
          hide-details
          :light="true"
        />
      </v-row>

      <template v-if="dontHasBankCard">
        <validation-observer ref="observer">
          <v-row
            no-gutters
            class="text--text"
          >
            <p class="col-12 ma-0 pa-0 body-2">
              <template v-if="firstName">
                {{ firstName }}, додайте
              </template>
              <template v-else>
                Додайте
              </template>
              , будь ласка, скан або фото вашого паспорту або ІПН
            </p>
            <p class="col-12 ma-0 pa-0 mt-5 body-2">
              Це дозволить нам захистити вас від несанкціонованих спроб отримати кошти на Ваше ім'я
              в майбутньому
            </p>
          </v-row>

          <v-row
            no-gutters
            class="justify-end justify-lg-space-between mt-10"
          >
            <v-col
              v-if="isIdCard"
              class="pa-0 first-page-of-passport col-12 col-sm-5 col-lg-3"
            >
              <p class="subtitle-1 font-weight-medium mb-0">
                Лицьовий бік ID-карти
              </p>
              <p class="opacity-8 body-2 my-2">
                Завантажте фото або скан-копію оригіналу документа
              </p>
              <validation-provider
                v-slot="{ errors, valid, validate }"
                name="Лицьовий бік ID-карти"
                rules="requiredImage|image"
              >
                <div class="col-11 pa-0">
                  <v-file-input
                    ref="firstPageOfPassport"
                    :value="firstPageOfPassport"
                    class="file-input body-2"
                    placeholder="Завантажити фото"
                    dense
                    rounded
                    outlined
                    filled
                    color="success"
                    prepend-icon=""
                    :error-messages="errors"
                    :success="valid && !Array.isArray(firstPageOfPassport)"
                    @change="onAddPhoto($event,'firstPageOfPassport'); validate($event);"
                  />
                </div>
              </validation-provider>
            </v-col>
            <v-col
              v-else
              class="pa-0 first-page-of-passport col-12 col-sm-5 col-lg-3"
            >
              <p class="subtitle-1 font-weight-medium mb-0">
                1 сторінка паспорту
              </p>
              <p class="opacity-8 body-2 my-2">
                Завантажте фото або скан-копію оригіналу документа
              </p>
              <validation-provider
                v-slot="{ errors, valid, validate }"
                name="1 сторінка паспорту"
                rules="requiredImage|image"
              >
                <div class="col-11 pa-0">
                  <v-file-input
                    ref="firstPageOfPassport"
                    :value="firstPageOfPassport"
                    class="file-input body-2"
                    placeholder="Завантажити фото"
                    dense
                    rounded
                    outlined
                    filled
                    color="success"
                    prepend-icon=""
                    :error-messages="errors"
                    :success="valid && !Array.isArray(firstPageOfPassport)"
                    @change="onAddPhoto($event,'firstPageOfPassport'); validate($event);"
                  />
                </div>
              </validation-provider>
            </v-col>

            <v-col
              class="pa-0 pl-3 pl-sm-0 d-sm-flex justify-center align-center mt-8 mb-5 ma-sm-0
                    col-12 col-sm-2 col-lg-1"
            >
              <img
                src="@/assets/chevron.svg"
                :class="['chevron chevron-first', { 'invert': $vuetify.theme.dark }]"
                alt=""
              >
            </v-col>
            <v-col
              v-if="isIdCard"
              class="pa-0 second-and-third-pages-of-passport col-12
      col-sm-5 col-lg-3"
            >
              <p class="subtitle-1 font-weight-medium mb-0">
                Зворотній бік ID-карти
              </p>
              <p class="opacity-8 body-2 my-2">
                Завантажте фото або скан-копію оригіналу документа
              </p>
              <validation-provider
                v-slot="{ errors, valid, validate }"
                name="Зворотній бік ID-карти"
                rules="requiredImage|image"
              >
                <div class="col-11 pa-0">
                  <v-file-input
                    ref="secondAndThirdPagesOfPassport"
                    :value="secondAndThirdPagesOfPassport"
                    class="file-input body-2"
                    placeholder="Завантажити фото"
                    dense
                    filled
                    rounded
                    outlined
                    color="text"
                    prepend-icon=""
                    :error-messages="errors"
                    :success="valid && !Array.isArray(secondAndThirdPagesOfPassport)"
                    @change="onAddPhoto($event, 'secondAndThirdPagesOfPassport'); validate($event);"
                  />
                </div>
              </validation-provider>
            </v-col>
            <v-col
              v-else
              class="pa-0 second-and-third-pages-of-passport col-12 col-sm-5 col-lg-3"
            >
              <p class="subtitle-1 font-weight-medium mb-0">
                2 та 3 сторінки паспорту
              </p>
              <p class="opacity-8 body-2 my-2">
                Завантажте фото або скан-копію оригіналу документа
              </p>
              <validation-provider
                v-slot="{ errors, valid, validate }"
                name=" 2 та 3 сторінки паспорту"
                rules="requiredImage|image"
              >
                <div class="col-11 pa-0">
                  <v-file-input
                    ref="secondAndThirdPagesOfPassport"
                    :value="secondAndThirdPagesOfPassport"
                    class="file-input body-2"
                    placeholder="Завантажити фото"
                    dense
                    filled
                    rounded
                    outlined
                    color="text"
                    prepend-icon=""
                    :error-messages="errors"
                    :success="valid && !Array.isArray(secondAndThirdPagesOfPassport)"
                    @change="onAddPhoto($event, 'secondAndThirdPagesOfPassport'); validate($event);"
                  />
                </div>
              </validation-provider>
            </v-col>
            <v-col
              class="pa-0 pl-3 pl-sm-0 d-sm-flex justify-end justify-lg-center mt-8 mb-5 mb-sm-8
                      col-12 col-lg-1"
            >
              <div class="col-sm-5 pa-0 d-sm-flex justify-center">
                <img
                  src="@/assets/chevron.svg"
                  :class="['chevron', { 'invert': $vuetify.theme.dark }]"
                  alt=""
                >
              </div>
            </v-col>

            <v-col
              v-if="isIdCard"
              class="pa-0 first-page-of-passport
             col-12 col-sm-5 col-lg-3"
            >
              <p class="subtitle-1 font-weight-medium mb-0">
                Довідка про реєстрацію місця проживання (паперовий додаток до ID-карти)
              </p>
              <p class="opacity-8 body-2 my-2">
                Завантажте фото або скан-копію оригіналу документа
              </p>
              <validation-provider
                v-slot="{ errors, valid, validate }"
                name="Паперовий додаток до ID-карти"
                rules="requiredImage|image"
              >
                <div class="col-11 pa-0">
                  <v-file-input
                    ref="registrationPageOfPassport"
                    :value="registrationPageOfPassport"
                    class="file-input body-2"
                    placeholder="Завантажити фото"
                    dense
                    rounded
                    outlined
                    filled
                    color="success"
                    prepend-icon=""
                    :error-messages="errors"
                    :success="valid && !Array.isArray(registrationPageOfPassport)"
                    @change="onAddPhoto($event,'registrationPageOfPassport'); validate($event);"
                  />
                </div>
              </validation-provider>
            </v-col>
            <v-col
              v-if="!isIdCard && hasPhoto25Years || hasPhoto45Years"
              class="pa-0 second-and-third-pages-of-passport
             col-12 col-sm-5 col-lg-3"
            >
              <p class="subtitle-1 font-weight-medium mb-0">
                4 та 5 сторінки паспорту
              </p>
              <p class="opacity-8 body-2 my-2">
                Завантажте фото або скан-копію оригіналу документа
              </p>
              <validation-provider
                v-slot="{ errors, valid, validate }"
                name="4 та 5 сторінки паспорту"
                rules="requiredImage|image"
              >
                <div class="col-11 pa-0">
                  <v-file-input
                    ref="fourthAndFifthPageOfPassport"
                    :value="fourthAndFifthPageOfPassport"
                    class="file-input body-2"
                    placeholder="Завантажити фото"
                    dense
                    filled
                    rounded
                    outlined
                    color="text"
                    prepend-icon=""
                    :error-messages="errors"
                    :success="valid && !Array.isArray(fourthAndFifthPageOfPassport)"
                    @change="onAddPhoto($event, 'fourthAndFifthPageOfPassport'); validate($event);"
                  />
                </div>
              </validation-provider>
            </v-col>
            <v-col
              v-if="!isIdCard && hasPhoto45Years"
              class="pa-0 second-and-third-pages-of-passport
             col-12 col-sm-5 col-lg-3"
            >
              <p class="subtitle-1 font-weight-medium mb-0">
                6 та 7 сторінки паспорту
              </p>
              <p class="opacity-8 body-2 my-2">
                Завантажте фото або скан-копію оригіналу документа
              </p>
              <validation-provider
                v-slot="{ errors, valid, validate }"
                name="6 та 7 сторінки паспорту"
                rules="requiredImage|image"
              >
                <div class="col-11 pa-0">
                  <v-file-input
                    ref="sixthAndSeventhPagesOfPassport"
                    :value="sixthAndSeventhPagesOfPassport"
                    class="file-input body-2"
                    placeholder="Завантажити фото"
                    dense
                    filled
                    rounded
                    outlined
                    color="text"
                    prepend-icon=""
                    :error-messages="errors"
                    :success="valid && !Array.isArray(sixthAndSeventhPagesOfPassport)"
                    @change="onAddPhoto($event, 'sixthAndSeventhPagesOfPassport');
                             validate($event);"
                  />
                </div>
              </validation-provider>
            </v-col>

            <v-col
              v-if="!isIdCard"
              class="pa-0 first-page-of-passport col-12 col-sm-5 col-lg-3"
            >
              <p class="subtitle-1 font-weight-medium mb-0">
                Сторінка паспорту з останньою пропискою
              </p>
              <p class="opacity-8 body-2 my-2">
                Завантажте фото або скан-копію оригіналу документа
              </p>
              <validation-provider
                v-slot="{ errors, valid, validate }"
                name="Сторінка з останньою пропискою"
                rules="requiredImage|image"
              >
                <div class="col-11 pa-0">
                  <v-file-input
                    ref="registrationPageOfPassport"
                    :value="registrationPageOfPassport"
                    class="file-input body-2"
                    placeholder="Завантажити фото"
                    dense
                    rounded
                    outlined
                    filled
                    color="success"
                    prepend-icon=""
                    :error-messages="errors"
                    :success="valid && !Array.isArray(registrationPageOfPassport)"
                    @change="onAddPhoto($event,'registrationPageOfPassport'); validate($event);"
                  />
                </div>
              </validation-provider>
            </v-col>
            <v-col class="pa-0 ipn col-12 col-sm-5 col-lg-3">
              <p class="subtitle-1 font-weight-medium mb-0">
                ІПН
              </p>
              <p class="opacity-8 body-2 my-2">
                Завантажте фото або скан-копію оригіналу документа
              </p>
              <validation-provider
                v-slot="{ errors, valid, validate }"
                name="ІПН"
                rules="requiredImage|image"
              >
                <div class="col-11 pa-0">
                  <v-file-input
                    ref="IPN"
                    :value="IPN"
                    class="file-input body-2"
                    placeholder="Завантажити фото"
                    dense
                    filled
                    rounded
                    outlined
                    color="text"
                    prepend-icon=""
                    :error-messages="errors"
                    :success="valid && !Array.isArray(IPN)"
                    @change="onAddPhoto($event, 'IPN'); validate($event);"
                  />
                </div>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            class="mt-16"
          >
            <p
              v-if="isIdCard"
              class="mb-0 col-12 font-weight-medium subtitle-1"
            >
              Ваше фото з ID-картою (лицьовий бік паспорту із Вашим фото)
            </p>
            <p
              v-else
              class="mb-0 col-12 font-weight-medium subtitle-1"
            >
              Ваше фото з розгорнутою сторінкою паспорту із останнім вклеєним фото
            </p>

            <p
              v-if="isIdCard"
              class="opacity-8 body-2 my-2"
            >
              Завантажте фото на якому ви тримаєте лицьовий бік ID-карти і дивитесь у фотокамеру.
              <br>
              На фото повинно бути видно Ваше обличчя і рука (від плеча до зап`ястя)
            </p>
            <p
              v-else
              class="opacity-8 body-2 my-2"
            >
              Завантажте фото на якому Ви тримаєте розгорнуту
              сторінку паспорта і дивитесь у фотокамеру.
              <br>
              На фото повинно бути видно Ваше обличчя і рука повністю (від плеча до зап'ястя)
            </p>
            <v-row
              no-gutters
              class="col-12"
            >
              <v-col
                v-if="isIdCard"
                class="pa-0 col-12 col-sm-6 col-md-4 user-photo"
              >
                <validation-provider
                  v-slot="{ errors, valid, validate }"
                  name="Ваше фото з ID-картою (лицьовий бік) "
                  rules="requiredImage|image"
                >
                  <div class="col-11 pa-0">
                    <v-file-input
                      ref="userPhoto"
                      :value="userPhoto"
                      class="file-input body-2"
                      placeholder="Завантажити фото"
                      dense
                      filled
                      rounded
                      outlined
                      color="text"
                      prepend-icon=""
                      :error-messages="errors"
                      :success="valid && !Array.isArray(userPhoto)"
                      @change="onAddPhoto($event, 'userPhoto'); validate($event);"
                    />
                  </div>
                </validation-provider>
              </v-col>
              <v-col
                v-else
                class="pa-0 col-12 col-sm-6 col-md-4 user-photo"
              >
                <validation-provider
                  v-slot="{ errors, valid, validate }"
                  name="Ваше фото з розгорнутою 1-ю сторінкою паспорту"
                  rules="requiredImage|image"
                >
                  <div class="col-11 pa-0">
                    <v-file-input
                      ref="userPhoto"
                      :value="userPhoto"
                      class="file-input body-2"
                      placeholder="Завантажити фото"
                      dense
                      filled
                      rounded
                      outlined
                      color="text"
                      prepend-icon=""
                      :error-messages="errors"
                      :success="valid && !Array.isArray(userPhoto)"
                      @change="onAddPhoto($event, 'userPhoto'); validate($event);"
                    />
                  </div>
                </validation-provider>
              </v-col>
            </v-row>

            <v-row
              no-gutters
              class="justify-center mt-10 position-relative"
            >
              <block-preloader
                v-if="blockPreloaderIsVisible"
              />
              <v-btn
                :disabled="!buttonIsActive"
                :class="{ 'visibility-hidden': blockPreloaderIsVisible }"
                rounded
                :block="$vuetify.breakpoint.xsOnly"
                color="success"
                @click="submit"
              >
                Відправити
              </v-btn>
            </v-row>
          </v-row>
        </validation-observer>
      </template>
    </div>
  </v-scale-transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BankLogos from '@/components/common/BankLogos';
import Notification from '@/components/common/Notification';
import blockPreloaderMixin from '@/mixins/blockPreloaderMixin';
import errorNotificationMixin from '@/mixins/errorNotificationMixin';
import successNotificationMixin from '@/mixins/successNotificationMixin';
import BlockPreloader from '@/components/common/BlockPreloader';
import RouteNamesService from '@/services/route-names-service';
import pageLoaderMixin from '../mixins/pageLoaderMixin';
import buttonDisablerMixin from '../mixins/buttonDisablerMixin';

export default {
  name: 'Index',
  title: 'Ідентифікація',
  components: {
    BlockPreloader,
    BankLogos,
    Notification,
  },
  mixins: [blockPreloaderMixin, errorNotificationMixin, successNotificationMixin, pageLoaderMixin,
    buttonDisablerMixin],
  // props: ['currentOrderId'],

  data() {
    return {
      photos: [],
      firstName: '',
      dontHasBankCard: true,
      moduleName: 'userIdentification',
      currentOrderId: '',
    };
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated']),
    ...mapGetters('cashForm', [
      'isIdCard',
      'hasPhoto25Years',
      'hasPhoto45Years',
    ]),
    clientId: {
      get() {
        return this.$store.state.clientInfo.clientData.clientId;
      },
    },
    firstPageOfPassport: {
      get() {
        return this.$store.state.userIdentification.firstPageOfPassport;
      },
      set(value) {
        this.setValueForProp('firstPageOfPassport', value);
      },
    },
    secondAndThirdPagesOfPassport: {
      get() {
        return this.$store.state.userIdentification.secondAndThirdPagesOfPassport;
      },
      set(value) {
        this.setValueForProp('secondAndThirdPagesOfPassport', value);
      },
    },
    fourthAndFifthPageOfPassport: {
      get() {
        return this.$store.state.userIdentification.fourthAndFifthPageOfPassport;
      },
      set(value) {
        this.setValueForProp('fourthAndFifthPageOfPassport', value);
      },
    },
    sixthAndSeventhPagesOfPassport: {
      get() {
        return this.$store.state.userIdentification.sixthAndSeventhPagesOfPassport;
      },
      set(value) {
        this.setValueForProp('sixthAndSeventhPagesOfPassport', value);
      },
    },
    registrationPageOfPassport: {
      get() {
        return this.$store.state.userIdentification.registrationPageOfPassport;
      },
      set(value) {
        this.setValueForProp('registrationPageOfPassport', value);
      },
    },
    IPN: {
      get() {
        return this.$store.state.userIdentification.IPN;
      },
      set(value) {
        this.setValueForProp('IPN', value);
      },
    },
    userPhoto: {
      get() {
        return this.$store.state.userIdentification.userPhoto;
      },
      set(value) {
        this.setValueForProp('userPhoto', value);
      },
    },

    /* documentPhotos: {
      get() {
        return this.$store.state.userIdentification.documentPhotos;
      },
      set(value) {
        this.setValueForProp('documentPhotos', value);
      },
    }, */
  },
  beforeMount() {
    this.getLastStateStore();
    if (this.$route.params.currentOrderId !== '' || null) {
      this.currentOrderId = this.$route.params.currentOrderId;
    }
    this.getClientMainInfo('firstName')
      .then(() => {
        this.firstName = this.$store.state.clientInfo.clientData.firstName;
        this.hidePagePreloader();
      })
      .catch((err) => console.log(err));
  },
  beforeDestroy() {
    this.documentPhotos = [];
  },
  methods: {
    ...mapActions('userIdentification', [
      'verificationPhotoStore',
    ]),
    ...mapActions('cashForm', [
      'getStateStore',
      'getLastStateStore']),
    ...mapActions('clientInfo', [
      'getClientMainInfo',
    ]),

    setValueForProp(key, value) {
      this.$store.commit('UPDATE_STATE', {
        moduleName: this.moduleName,
        key,
        value,
      });
    },
    nameDetection() {
      // eslint-disable-next-line no-unused-expressions
      return this.isIdCard ? 'Лицьовий бік ID-карти' : '1 сторінка паспорту';
    },
    updateDocumentPhotos(fileObj) {
      this.$store.commit('userIdentification/UPDATE_DOCUMENT_PHOTOS', {
        fileObj,
      });
    },
    onAddPhoto(fileObject, ref) {
      if (fileObject && fileObject.type && fileObject.type.includes('image/')) {
        this[ref] = fileObject;
        // eslint-disable-next-line no-param-reassign
        fileObject.id = ref;
        this.photos.push(fileObject);
      } else {
        this[ref] = [];
        this.photos = this.photos.filter((item) => item.id !== ref);
      }
    },
    goToUserCredits(time = 5000) {
      const timeout = setTimeout(() => {
        if (this.$router.history.current.name !== RouteNamesService.userCredits) {
          this.$router.push({ name: RouteNamesService.userCredits });
        }
        this.toggleBlockPreloader(false);
        clearTimeout(timeout);
      }, time);
    },
    async submit() {
      try {
        const isValid = await this.$refs.observer.validate();
        if (isValid) {
          this.toggleBlockPreloader(true);
          if (this.currentOrderId) {
            this.verificationPhotoStore({
              orderId: this.currentOrderId,
              documentPhotos: this.photos,
            }).then((res) => {
              if (res) {
                this.toggleBlockPreloader(false);
                this.makeTheButtonInactive();
                this.toggleSuccessMessage();
              } else {
                this.toggleErrorMessage();
              }
            }).catch(() => {
              this.toggleErrorMessage();
              this.makeTheButtonInactive();
            }).finally(() => {
              this.goToUserCredits(7000);
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.file-input {
  max-width: 250px;

  @media screen and (min-width: 600px) and (max-width: 620px) {
    font-size: .8em !important;
  }
}

.chevron {
  width: 30px;
  transform: rotate(90deg);

  &.invert {
    filter: invert(1);
  }

  @media screen and (min-width: 600px) {
    width: 35px;
  }

  @media screen and (min-width: 1264px) {
    transform: rotate(0);
    width: 40px;
  }

  &-first {
    @media screen and (min-width: 600px) {
      transform: rotate(0);
    }
  }
}
</style>
