import HttpInterceptorService from '@/services/http-interceptor-service';
import session from './session';

const { VUE_APP_BACKEND_API_URL } = process.env;
// const VUE_APP_BACKEND_API_URL = 'http://localhost:8001/';

export function getAccessToken() {
  return localStorage.getItem(process.env.VUE_APP_ACCESS_STORAGE_KEY);
}
function setAuthHeader(additionalHeaders = {}) {
  return {
    headers: {
      Authorization: `JWT ${getAccessToken()}`,
      ...additionalHeaders,
    },
  };
}
function getRefresh() {
  if (
    localStorage.getItem(process.env.VUE_APP_REFRESH_STORAGE_KEY) !== undefined
    || localStorage.getItem(process.env.VUE_APP_REFRESH_STORAGE_KEY) !== null
  ) {
    return `${localStorage.getItem(process.env.VUE_APP_REFRESH_STORAGE_KEY)}`;
  }
  return null;
}
const auth = {
  auth: {
    username: 'admin',
    password: 'admin',
  },
};
// eslint-disable-next-line import/prefer-default-export
export const AuthAPI = {
  newAccessToken() {
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/auth/new_access`, {}, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.error(err);
      });
  },
  getInfoAboutSettingPinCode(payload) {
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/auth/is_pin_set`, payload, auth)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  keyAuth(payload) {
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/auth/key_auth`, { key: payload.key }, auth)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  async getMe() {
    await HttpInterceptorService.makeDelay();
    return session.get(`${VUE_APP_BACKEND_API_URL}api/v1/auth/get_me`, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => err);
  },
  checkUserScope() {
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/globals/check_scope`, {}, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  setClientsPin(payload) {
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/auth/set_pin`, { pin: payload.pin }, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  refreshAccessToken(payload) {
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/auth/new_refresh`,
      { pin: payload.pin, refresh: getRefresh() }, auth)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  forgotPassword(payload) {
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/auth/forgot_pin`,
      { itn: payload.itn, new_pin: payload.newPin, refresh: getRefresh() }, auth)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  updateClientsPin(oldPin, newPin) {
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/auth/update_pin`,
      { new_pin: newPin, old_pin: oldPin }, setAuthHeader());
  },
  addOrderAttempt(product) {
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/orders/add_order_attempt`, { product }, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
};
export const MainAPI = {
  getReferenceBook() {
    return session.get(`${VUE_APP_BACKEND_API_URL}api/v1/reference_books/get_reference_books`, auth)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  getProducts() {
    return session.get(`${VUE_APP_BACKEND_API_URL}api/v1/products/get_products`, auth)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
};
export const CashFormAPI = {
  async getActiveOrder() {
    await HttpInterceptorService.makeDelay();
    return session.get(`${VUE_APP_BACKEND_API_URL}api/v1/orders/get_active_order`, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  async getLastState() {
    await HttpInterceptorService.makeDelay();
    return session.get(`${VUE_APP_BACKEND_API_URL}api/v1/orders/get_last_state`, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  addOrder(payload = {}) {
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/orders/add_order`, payload, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  getState() {
    return session.get(`${VUE_APP_BACKEND_API_URL}api/v1/orders/get_state`, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  getSegmentation() {
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/globals/segmentation`, { product: 'cache_online' }, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  updateExistingOrder(orderId, objToSend) {
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/orders/update_order`, { order_id: '999999', state: objToSend },
      setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  cashConfirmation(orderId) {
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/orders/confirmation`, { order_id: orderId },
      setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
};
export const ClientInfoApi = {
  async getCredits() {
    await HttpInterceptorService.makeDelay();
    return session.get(`${VUE_APP_BACKEND_API_URL}api/v1/credits/get_credits`, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  getCreditReference(creditId) {
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/credits/credit_reference`, { credit_id: [creditId] }, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  getPaymentHistory(creditId) {
    return session.get(`${VUE_APP_BACKEND_API_URL}api/v1/credits/get_payment_history/${creditId}`, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  getMinMonthlyPayment(creditId) {
    return session.get(`${VUE_APP_BACKEND_API_URL}api/v1/credits/get_monthly_payment/${creditId}`, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  creditPayment(creditId = '', clientCard, sum = 0) {
    const dataPicker = () => {
      if (clientCard) {
        return { client_card: clientCard, credit_id: creditId, sum: +sum };
      }
      return { credit_id: creditId, sum: +sum };
    };
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/credits/credit_payment`, dataPicker(),
      setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  clientComplaint(msg) {
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/globals/complaint`, { text: msg }, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  getAttachedFiles(orderId) {
    return session.get(`${VUE_APP_BACKEND_API_URL}api/v1/files/get_attached_files/${orderId}`, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
};
export const BankCardsApi = {
  async getCards() {
    await HttpInterceptorService.makeDelay();
    return session.get(`${VUE_APP_BACKEND_API_URL}api/v1/cards/get_client_cards`, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  addClientsCard({ cardNumber, cardName = 'clientCard', redirect }) {
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/cards/add_new_card`,
      { card: cardNumber, card_name: cardName, redirect }, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  makeBankCardMain(cardId) {
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/cards/set_card_default`, { silver_id: cardId }, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
};
export const MassagesApi = {
  getMessages() {
    return session.get(`${VUE_APP_BACKEND_API_URL}api/v1/globals/get_messages`, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  async sendInvitationFromQuery(id) {
    await HttpInterceptorService.makeDelay();
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/globals/process_action`, { event_id: id }, auth)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  updateMessageState() {
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/globals/fetch_new_messages`, {}, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  getExactMessage(payload) {
    // eslint-disable-next-line no-unused-vars
    let typeOfFilter = '';
    let payloadType = '';
    if (payload.id !== undefined && payload.id.length > 1) {
      typeOfFilter = 'message_id';
      payloadType = payload.id;
    } else {
      typeOfFilter = 'is_read';
      payloadType = payload.is_read;
    }
    return session.get(`${VUE_APP_BACKEND_API_URL}api/v1/globals/get_messages?${typeOfFilter}=${payloadType}`, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  // { message_id: payload.id, is_read: payload.is_read },
  sandClientsReflection(payload) {
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/globals/push_reflection`, { message_id: payload.messageId, button_handler: payload.buttonHandler, reflection: payload.reflection }, setAuthHeader())
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
};
export const UserIdentification = {
  verificationPhoto(orderId = '', documentPhotos = []) {
    const formData = new FormData();
    documentPhotos.forEach((item, index) => {
      // eslint-disable-next-line no-param-reassign
      delete item.id;
      formData.append(`${index + 1}`, item);
    });
    formData.append('order_id', orderId);
    return session.post(`${VUE_APP_BACKEND_API_URL}api/v1/orders/push_documents`, formData, setAuthHeader({
      'Content-Type': 'multipart/form-data',
      // boundary: 'asd',
    }))
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
};
