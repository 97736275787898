import Vue from 'vue';
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';
import {
  // eslint-disable-next-line camelcase
  alpha_num,
  // eslint-disable-next-line camelcase
  alpha_dash,
  max,
  min,
  required,
  image,
  // eslint-disable-next-line camelcase
  email,
} from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('image', {
  ...image,
  message: 'Тип файлу повинен мати один з наступних форматів: jpg, png, bmp, svg.',
});

extend('email', {
  ...email,
  message: 'Ви вказали неправильну e-mail адресу.',
});

extend('onlyNumbers', {
  params: ['dot', 'slash'],
  validate(value, { dot, slash }) {
    if (slash) {
      return /^[\d/]+$/.test(value);
    }
    return dot ? /^(?=.*\d)\d*(?:\.\d{0,2})?$/.test(value) : /^\d+$/.test(value);
  },
  message: '{_field_} має складатися лише з цифр!',
});

extend('isInvalid', {
  params: ['errorMsg'],
  validate(value, { errorMsg }) {
    return errorMsg === 'undefined';
  },
  message: '{errorMsg}',
});

extend('isOldValue', {
  params: ['oldValue'],
  validate(value, { oldValue }) {
    return value === oldValue;
  },
  message: 'Ви відправляєте старе значення.',
});

extend('pinCode', {
  validate(value) {
    return /^[а-яА-ЯёЁa-zA-Z-іІїЇґҐєЄ`0-9/.-]+$/.test(value);
  },
  message: 'Пароль може складатися з кирилиці, латиниці, цифр, крапок, а також тире.',
});

extend('pinCodesMatch', {
  params: ['oldPin'],
  validate(value, { oldPin }) {
    return value !== oldPin;
  },
  message: 'Пін-код співпадає з старим.',
});

extend('required', {
  ...required,
  validate(value) {
    return value !== '' && value !== 0;
  },
  message: '{_field_} не може бути пустим!',
});

extend('requiredImage', {
  ...required,
  validate(value) {
    return value instanceof File;
  },
  message: '{_field_} не може бути пустим!',
});

extend('checkboxRequired', {
  validate(value) {
    return !!value === true;
  },
  message: 'Цей пункт обов\'язковий',
});

extend('alphaNum', {
  // eslint-disable-next-line camelcase
  ...alpha_num,
  message: '{_field_} має складатися лише з букв та цифр!',
});

extend('alphaDash', {
  // eslint-disable-next-line camelcase
  ...alpha_dash,
  validate(value) {
    return /^[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ'`\s-]+$/.test(value);
  },
  message: '{_field_} має складатися лише з українських букв і можливо дефізу!',
});

extend('onlyLetters', {
  // eslint-disable-next-line camelcase
  ...alpha_dash,
  validate(value) {
    return /^[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ'`\s]+$/.test(value);
  },
  message: '{_field_} має складатися лише з українських букв!',
});

extend('companyName', {
  // eslint-disable-next-line camelcase
  ...alpha_dash,
  validate(value) {
    // return /^[а-яА-ЯёЁa-zA-Z-0-9-іІїЇґҐєЄ'`\s]+$/.test(value);
    return /^[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ'`0-9\s-.]+$/.test(value);
  },
  message: '{_field_} має складатися лише з українських букв, цифр і можливо дефізу!',
});

extend('alphaSpaceNums', {
  // eslint-disable-next-line camelcase
  ...alpha_dash,
  params: ['slash', 'dot'],
  validate(value, { slash, dot }) {
    let result = null;

    if (slash && dot) {
      result = /^[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ'‘`0-9\s-/.]+$/.test(value);
    } else if (slash) {
      result = /^[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ'‘`0-9\s-/]+$/.test(value);
    } else if (dot) {
      result = /^[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ'‘`0-9\s-.]+$/.test(value);
    } else {
      result = /^[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ'‘`0-9\s-]+$/.test(value);
    }
    return result;
  },
  message: '{_field_} має складатися лише з українських букв, цифр і можливо дефізу!',
});

extend('creditCardMax', {
  ...max,
  message: '{_field_} має складатися з 16 знаків!',
});

extend('creditCardMin', {
  ...min,
  message: '{_field_} має складатися з 16 знаків!',
});

extend('max', {
  ...max,
  message: '{_field_} має бути не більше {length} знаків!',
});

extend('min', {
  ...min,
  message: '{_field_} має бути більше {length} знаків!',
});

extend('minValue', {
  params: ['target'],
  validate(value, { target }) {
    return +value > +target;
  },
  message: '{_field_} має бути більше ніж {target}!',
});

extend('pinConfirmed', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Пін-коди не співпадають',
});

extend('uaPhone', {
  validate(value) {
    return /^\+[0-9]{3}\s\((\d+)\)\s\d{3}-\d{2}-\d{2}/g.test(value)
      && value.length === 19;
  },
  message: 'Телефон має мати наступний вигляд: +380 (**)-***-**-**',
});

extend('creditCard', {
  validate(value) {
    // eslint-disable-next-line no-param-reassign
    value = value.replace(/\s/g, '');
    // eslint-disable-next-line max-len
    return /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/.test(value)
      && value.length === 16;
  },
  message: 'Ви ввели некоректний номер банківської картки.',
});

/* extend('passport', {
  validate(value) {
    return /^\d{6}/.test(value);
  },
  message: 'Ви ввели неправильний номер паспорту',
}); */

extend('adult', {
  validate(value) {
    const date = value.split('.');
    const birthday = new Date(`${date[2]}-${date[1]}-${date[0]}`);
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970) >= 18;
  },
  message: 'Вам має бути 18 років',
});

extend('documentRecord', {
  validate(value) {
    return /^[\d-]+$/.test(value);
  },
  message: 'Ви ввели неправильний номер запису',
});

Vue.component('validation-observer', ValidationObserver);

Vue.component('validation-provider', ValidationProvider);
