<template>
  <v-row
    class="ma-0 pa-0 loader-wrapper flex-column justify-center"
    :class="backgroundColor"
    v-if="!unSuccess"
  >
    <v-progress-circular
      indeterminate
      :color="color"
      class="align-self-center"
      style="margin: 5px"
    />
    <h3>дані завантажуються...</h3>
  </v-row>
  <v-row
    class="col-9 col-sm-12 col-lg-8 col-xl-5 mx-auto mt-n2
         mt-3 pa-0 flex-column justify-center"
    v-else
  >
    <v-img
      class="align-self-center mt-5 mb-5"
      title="помилка"
      src="@/assets/error.png"
      max-width="50px"
      alt="помилка"
    />
    <div class="text-center ">
      <h3 class="align-self-center">
        Помилка завантаження даних. Спробуйте повторити пізніше
      </h3>
    </div>
  </v-row>
</template>

<script>
export default {
  name: 'BlockPreloader',
  props: {
    color: {
      type: String,
      default: 'success',
    },
    backgroundColor: String,
    unSuccess: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-content: center;
}
</style>
