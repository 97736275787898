import { UPDATE_DOCUMENT_PHOTOS } from '@/store/userIdentification/mutation-types';
// eslint-disable-next-line no-unused-vars
import convertObjToText from '@/utils/convertObjToText';
import { UserIdentification } from '../../api/Rest';

const getDefaultState = () => ({
  currentProduct: {},
  firstPageOfPassport: [],
  secondAndThirdPagesOfPassport: [],
  fourthAndFifthPageOfPassport: [],
  sixthAndSeventhPagesOfPassport: [],
  registrationPageOfPassport: [],
  IPN: [],
  userPhoto: [],
  documentPhotos: [],
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    [UPDATE_DOCUMENT_PHOTOS](state, { fileObj }) {
      state.documentPhotos.push(fileObj);
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    verificationPhotoStore({ state }, { orderId = '', documentPhotos = [] }) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        // verificationPhoto(orderId, convertObjToText(documentPhotos))
        UserIdentification.verificationPhoto(orderId, documentPhotos)
          .then((data) => {
            if (data.success) {
              resolve(data.success);
            } else {
              reject(data.success);
            }
          }).catch((err) => {
            reject(err);
          });
      });
    },
  },
};
