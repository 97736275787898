export const UPDATE_STEP = 'UPDATE_STEP';

export const CLEAR_FORM_DATA = 'CLEAR_FORM_DATA';
export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';

export const UPDATE_STATE = 'UPDATE_STATE';

export const CHANGE_BOOLEAN_STATE = 'CHANGE_BOOLEAN_STATE';

export const SAVE_INVALID_OBJECTS = 'SAVE_INVALID_OBJECTS';

export const REMOVE_FROM_INVALID_FIELDS = 'REMOVE_FROM_INVALID_FIELDS';
export const REMOVE_FROM_INVALID_OBJECTS = 'REMOVE_FROM_INVALID_OBJECTS';

export const CONVERT_INVALID_FIELDS = 'CONVERT_INVALID_FIELDS';
