// eslint-disable-next-line import/named
import {
  SAVE_CLIENT_CARD, UPDATE_STATE, SORT_CLIENT_CARDS,
} from '@/store/bankCards/mutation-types';
import { BankCardsApi } from '../../api/Rest';

const getDefaultState = () => ({
  clientCards: [],
  bankCardsAreUploaded: false,
  bankCardNumber: '',
  bankCardName: '',
  addCardDialogIsOpen: false,
  bankCardIsSending: false,
  successPopUpIsOpen: false,
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    [SAVE_CLIENT_CARD](state, cardObj) {
      state.clientCards.push(cardObj);
    },
    [SORT_CLIENT_CARDS](state) {
      state.clientCards = state.clientCards.sort((a, b) => b.isDefault - a.isDefault);
    },
    [UPDATE_STATE](state, {
      key = '',
      value = '',
    }) {
      state[key] = value;
    },
  },
  actions: {
    getCardsStore({ state, commit }) {
      return new Promise((resolve) => {
        if (state.bankCardsAreUploaded) {
          resolve();
        } else {
          BankCardsApi.getCards()
            .then((data) => {
              if (data.success === true) {
                const cardsArr = data.payload ? data.payload : [];
                if (cardsArr) {
                  cardsArr.forEach((item) => {
                    commit('SAVE_CLIENT_CARD', item);
                  });
                  commit('SORT_CLIENT_CARDS');
                }
              }
              commit('UPDATE_STATE', {
                key: 'bankCardsAreUploaded',
                value: true,
              });
              resolve();
              // eslint-disable-next-line no-console
            }).catch((err) => console.error(err));
        }
        // eslint-disable-next-line no-console
      });
    },
    // eslint-disable-next-line no-unused-vars
    addClientsCardStore({ commit }, payload) {
      return new Promise((resolve, reject) => {
        BankCardsApi.addClientsCard(payload)
          .then((data) => {
            if (data.success === true) {
              const iPayURL = data.payload;
              if (iPayURL) {
                window.location.href = iPayURL;
              }
              resolve(iPayURL);
            } else {
              reject();
            }
          }).catch((err) => console.error(err));
      });
    },
    makeBankCardMainStore({ commit }, cardId) {
      return new Promise((resolve) => {
        commit('UPDATE_STATE', {
          key: 'bankCardsAreUploaded',
          value: false,
        });
        commit('UPDATE_STATE', {
          key: 'clientCards',
          value: [],
        });
        BankCardsApi.makeBankCardMain(cardId)
          .then((data) => {
            if (data.success === true) {
              resolve(data.success);
            } else {
              resolve();
            }
          }).catch((err) => console.error(err));
      });
    },
  },
};
