<template>
  <div class="toolbar secondBg">
    <v-container class="page-container page-fluid secondBg tool-bar px-md-5">
      <v-row
        no-gutters
        class="py-5"
      >
        <div class="d-flex justify-space-between col-12 pa-0">
          <v-img
            v-if="$vuetify.theme.dark"
            contain
            src="@/assets/sf-logo-text.png"
            class="cursor-pointer home-page-img d-md-none"
            @click="goToHomePage"
          />
          <v-img
            v-else
            contain
            src="@/assets/main-logo.png"
            class="cursor-pointer home-page-img d-md-none"
            @click="goToHomePage"
          />
          <v-icon
            color="text"
            class="d-md-none icon-menu"
            @click="toggleMobileMenu"
          >
            mdi-menu
          </v-icon>

          <transition name="slide-fade">
            <mobile-menu
              v-if="mobileMenuIsOpen"
              class="d-md-none"
              @onMenuClose="toggleMobileMenu(false)"
            />
          </transition>

          <desktop-menu class="d-none d-md-flex" />
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import MobileMenu from '@/components/header/auxiliary-items/MobileMenu';
import DesktopMenu from '@/components/header/auxiliary-items/DesktopMenu';
import RouteNamesService from '@/services/route-names-service';

export default {
  name: 'ToolBar',
  components: {
    DesktopMenu,
    MobileMenu,
  },
  data() {
    return {
      mobileMenuIsOpen: false,
    };
  },
  computed: {
    ...mapGetters([
      'toolBarLoading',
    ]),
    ...mapGetters('auth', [
      'isAuthenticated',
      'pinCodeSet',
    ]),
    ...mapState('clientInfo', [
      'clientData',
    ]),
  },
  methods: {
    ...mapActions('auth', [
      'logout',
    ]),
    goToHomePage() {
      const routeName = RouteNamesService.userCredits;
      if (this.$router.history.current.name !== routeName) {
        this.$router.push({ name: routeName });
      }
    },
    toggleMobileMenu(bool = true) {
      this.mobileMenuIsOpen = bool;
      const classToDisableScroll = 'no-scroll';
      if (bool) {
        document.documentElement.classList.add(classToDisableScroll);
      } else {
        document.documentElement.classList.remove(classToDisableScroll);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  /*.toolbar {
    z-index: 3;
  }*/

  .home-page-img {
    max-width: 130px;
  }

  .mobile-menu {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .icon-menu {
    font-size: 1.8rem;
  }

  .slide-fade-enter-active {
    transition: all .4s ease;
  }
  .slide-fade-leave-active {
    transition: all .4s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10%);
    opacity: 0;
  }
</style>
