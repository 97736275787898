export default class HttpInterceptorService {
  static getAccessToken() {
    return localStorage.getItem(process.env.VUE_APP_ACCESS_STORAGE_KEY);
  }

  static removeAccessToken() {
    localStorage.removeItem(process.env.VUE_APP_ACCESS_STORAGE_KEY);
  }

  static delayHttpRequest(ms = 2000) {
    // const isPageForRedirect = router.history.current.name === RouteNamesService.pageForRedirect;
    return new Promise((resolve) => {
      const interval = setTimeout(() => {
        clearTimeout(interval);
        resolve(true);
      }, ms);
    });
  }

  // eslint-disable-next-line consistent-return
  static async makeDelay() {
    if (!this.getAccessToken()) {
      return this.delayHttpRequest();
    }
  }
}
