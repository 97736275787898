export default {
  beforeRouteLeave(to, from, next) {
    const whenToRedirect = 1000;
    this.$root.loaderIsVisible = true;
    const timeout = setTimeout(() => {
      next();
      clearTimeout(timeout);
    }, whenToRedirect);
  },
  methods: {
    showPagePreloader() {
      this.$root.loaderIsVisible = true;
    },
    hidePagePreloader() {
      const ms = 500;
      // eslint-disable-next-line
      const interval = setInterval(() => {
        if (this.$store.state.auth.tokensAreGot) {
          this.$root.loaderIsVisible = false;
          clearInterval(interval);
        }
      }, ms);
    },
  },
};
