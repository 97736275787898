import {
  // eslint-disable-next-line import/named
  SAVE_CLIENT_CREDITS,
  UPDATE_CLIENT_DATA,
  UPDATE_STATE,
} from '@/store/clientInfo/mutation-types';
import { CLEAR_FORM_DATA, UPDATE_FORM_DATA } from '@/store/cashForm/mutation-types';
import { AuthAPI, ClientInfoApi } from '../../api/Rest';

const getDefaultState = () => ({
  clientData: {
    last_name: '',
    first_name: '',
    middle_name: '',
    client_id: '',
    full_name: '',
    patronymic: '',
    date_of_birth: '',
    gender: '',
    phone: '',
    email: '',
    inn: '',
    credits: [],
    credits_are_uploaded: false,
    payments_for_one_loan: [],
  },
});

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    activeCredits: (state) => state.clientData.credits
      .filter((item) => item.mounthly_payment !== null),
    clientId: (state) => state.clientData.clientId,
  },
  mutations: {
    /*
   * @param {string} parentObjName - indicate if we change an object in the state
   * @param {string} childObjName - indicate if the parent object that is in the state has a
   * child object and we change the property in child object
   * if parentObjName and childObjName art not specified, the property inside state changes
   * @param {string} attribute - the name of the property that changes
   * @param {string} value - value for the attribute */
    [UPDATE_FORM_DATA](state, {
      parentObjName = '',
      childObjName = '',
      attribute = '',
      value = '',
    }) {
      if (parentObjName) {
        if (childObjName) {
          state[parentObjName][childObjName][`${attribute}`] = value;
        } else {
          state[parentObjName][`${attribute}`] = value;
        }
      } else {
        state[attribute] = value;
      }
    },
    [CLEAR_FORM_DATA](state, {
      parentObjName = '',
      childObjName = '',
    }) {
      const obj = state[parentObjName][childObjName] || state[parentObjName];
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          obj[key] = '';
        }
      }
    },
    [UPDATE_CLIENT_DATA](state, {
      data,
    }) {
      if (state.clientData === undefined) {
        state.clientData = data;
      } else {
        state.clientData = Object.assign(state.clientData, data);
      }
    },
    [SAVE_CLIENT_CREDITS](state, { obj = {} }) {
      state.clientData.credits.push(obj);
    },
    [UPDATE_STATE](state, { key = '', value = '' }) {
      state.clientData[key] = value;
    },
  },
  actions: {
    updateClientInfo({ dispatch, state }) {
      if (state.clientData === undefined) {
        dispatch('getClientMainInfo');
      }
    },
    getClientMainInfo({ commit, dispatch }) {
      return new Promise((resolve) => {
        AuthAPI.getMe()
          .then((data) => {
            if (data.success && data.payload !== null) {
              commit(UPDATE_CLIENT_DATA, { data: data.payload });
            }
            dispatch('auth/getInfoAboutSettingPinCodeStore', null, { root: true });
            resolve(data);
          })
          .catch((err) => {
            dispatch('auth/getInfoAboutSettingPinCodeStore', null, { root: true });
            console.error(err);
          });
      });
    },
    getCreditsStore({ state, commit }, saveCredits = true) {
      return new Promise((resolve) => {
        if (state.clientData.creditsAreUploaded) {
          resolve();
        } else {
          ClientInfoApi.getCredits()
            .then((data) => {
              if (data.success === true) {
                const credits = data.payload;
                if (credits && saveCredits) {
                  credits.forEach((item) => {
                    commit('SAVE_CLIENT_CREDITS', { obj: item });
                  });
                }
                resolve(credits);
              }
            }).catch((err) => console.error(err));
          commit('UPDATE_STATE', {
            key: 'creditsAreUploaded',
            value: true,
          });
        }
      });
    },
    getPaymentHistoryStore({ commit }, creditId = '') {
      return new Promise((resolve) => {
        ClientInfoApi.getPaymentHistory(creditId)
          .then((data) => {
            if (data.success === true) {
              const path = data.payload;
              if (path) {
                const arr = [];
                path.forEach((item) => arr.push(item));
                commit('UPDATE_FORM_DATA', {
                  parentObjName: 'clientData',
                  attribute: 'payments_for_one_loan',
                  value: arr,
                });
                resolve(arr);
              }
              resolve();
            }
          }).catch((err) => console.error(err));
      });
    },
    // eslint-disable-next-line no-unused-vars
    getMinMonthlyPaymentStore({ commit }, { creditId = '' }) {
      return new Promise((resolve, reject) => {
        ClientInfoApi.getMinMonthlyPayment(creditId)
          .then((data) => {
            if (data.success === true) {
              const minMonthlyPaymentValue = data.payload;
              resolve(minMonthlyPaymentValue);
            } else {
              reject(new Error(data.errors));
            }
          }).catch((err) => console.error(err));
      });
    },
    // eslint-disable-next-line no-unused-vars
    creditPaymentStore({ commit }, { creditId = '', clientCard = 0, sum = 0 }) {
      return new Promise((resolve, reject) => {
        ClientInfoApi.creditPayment(creditId, clientCard, sum)
          .then((data) => {
            if (data.success === true) {
              const creditPaymentResult = data;
              resolve(creditPaymentResult);
            } else {
              reject(new Error(data.error));
            }
          }).catch((err) => console.error(err));
      });
    },
    // eslint-disable-next-line no-unused-vars
    clientComplaintStore({ commit }, {
      msg = '',
      name = '',
      email = '',
      phone = '',
    }) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve) => {
        ClientInfoApi.clientComplaint(msg, name, email, phone)
          .then((data) => {
            if (data.success === true) {
              resolve(data.success);
            }
            resolve();
          }).catch((err) => console.error(err));
      });
    },
  },
};
