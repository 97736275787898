<template>
  <div class="wrapper">
    <v-card-title
      class="py-2 px-2 success border-radius-top-inherit d-flex justify-space-between white--text
            text-md-h6 px-md-3"
    >
      Ми на карті
      <v-icon
        color="white"
        class="cursor-pointer"
        @click="onPopUpClose"
      >
        mdi-window-close
      </v-icon>
    </v-card-title>

    <v-card-text
      class="content pa-0 text-justify custom-scroll border-green-3px border-radius-bottom-inherit"
    >
      <iframe
        class="google-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1796.926495589845!2d30.51553168074537!3d50.436670746918544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4cefc4a0d0c3f%3A0xcce27c7d6276b46e!2z0YPQuy4g0JHQvtC70YzRiNCw0Y8g0JLQsNGB0LjQu9GM0LrQvtCy0YHQutCw0Y8sIDM5LCDQmtC40LXQsiwgMDIwMDA!5e0!3m2!1sru!2sua!4v1629364069874!5m2!1sru!2sua"
        width="100%"
        height="100%"
        style="border:0;"
        allowfullscreen=""
        loading="lazy"
      />
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: 'GoogleMap',
  methods: {
    onPopUpClose() {
      this.$emit('onPopUpClose');
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 90vw;

  @media screen and (min-width: 800px) {
    width: 700px;
  }
}

.google-map {
  height: 68vh;

  @media screen and (min-width: 600px) {
    height: 60vh;
  }
}
</style>
