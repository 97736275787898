<template>
  <div>
    <v-slide-x-reverse-transition
      group
      mode="in-out"
    >
      <v-alert
        v-for="(error) in serverError.nonFieldErrors"
        :key="error.code"
        :value="authError"
        outlined
        text
        type="error"
      >
        {{ error.message }}
      </v-alert>
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition
      group
      mode="in-out"
    >
      <v-alert
        v-for="(error) in serverError.key"
        :key="error.code"
        :value="authError"
        outlined
        text
        type="error"
      >
        {{ error.message }}
      </v-alert>
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition
      group
      mode="in-out"
    >
      <v-alert
        v-for="(error) in globalErrors"
        :key="error.code"
        :value="authError"
        outlined
        text
        type="error"
      >
        {{ error.message }}
      </v-alert>
    </v-slide-x-reverse-transition>

    <notification
      v-if="errorNotification"
      type="error"
    >
      Щось пішло не так. Спробуйте ще раз
    </notification>

    <notification
      v-if="successNotification"
      type="success"
    >
      Пін-код успішно змінено
    </notification>

    <v-card
      elevation="6"
      class="wrapper border-radius-10 pb-4"
      color="background"
    >
      <v-card-title class="d-flex justify-space-between align-center text--text">
        <template>
          <span>Редагування паролю</span>

          <v-icon
            class="close-btn"
            @click="onPinPopUpClose"
          >
            mdi-close
          </v-icon>
        </template>
      </v-card-title>
      <v-card-text class="pb-0">
        <notification
          v-if="errorNotificationToogle"
          type="error"
        >
          Нажаль ІПН не співпадає з даними в системі, перевірте правильність вводу ІПН
        </notification>
        <validation-observer
          ref="observer"
        >
          <form
            @submit.prevent="submit"
          >
            <template>
              <validation-provider
                v-slot="{ errors }"
                name="РНОКПП"
                vid="Itn"
                :rules="{
                  required: true,
                  alphaNum: true,
                  min: 10,
                  max: 10,
                }"
              >
                <v-text-field
                  v-model="itn"
                  :error-messages="errors"
                  label="Введіть Ваш РНОКПП(Раніше ІПН):"
                  rounded
                  filled
                  outlined
                  dense
                  color="text"
                  :success="errors.length === 0 && itn.length === 10 && itn.length <= 10"
                  type="text"
                  @click:append="ipnShow = !ipnShow"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="Новий пароль"
                vid="newPin"
                :rules="{
                  required: true,
                  alphaNum: true,
                  min: 4,
                  max: 20,
                }"
              >
                <v-text-field
                  v-model="pin2"
                  :error-messages="errors"
                  label="Новий пароль"
                  rounded
                  filled
                  outlined
                  dense
                  color="text"
                  :success="errors.length === 0 && pin2.length >= 4 && pin2.length <= 20"
                  :type="show2 ? 'text' : 'password'"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show2 = !show2"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="Підтвердити новий пароль"
                :rules="{
                  required: true,
                  alphaNum: true,
                  min: 4,
                  max: 20,
                  pinConfirmed: '@newPin'
                }"
              >
                <v-text-field
                  v-model="pin3"
                  :error-messages="errors"
                  label="Підтвердити новий пароль"
                  rounded
                  filled
                  outlined
                  dense
                  color="text"
                  :success="errors.length === 0 && pin3.length >= 4 && pin3.length <= 20"
                  :type="show3 ? 'text' : 'password'"
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show3 = !show3"
                />
              </validation-provider>
            </template>

            <div class="position-relative">
              <block-preloader
                v-if="blockPreloaderIsVisible"
              />

              <buttons-block
                :class="{ 'visibility-hidden': blockPreloaderIsVisible }"
                hide-clear-button="true"
                show-go-out="true"
                @continueButtonClick="submit"
                @clearButtonClick="clear"
              />
            </div>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ButtonsBlock from '@/components/common/ButtonsBlock';
import { mapActions, mapGetters, mapState } from 'vuex';
import blockPreloaderMixin from '@/mixins/blockPreloaderMixin';
import errorNotificationMixin from '@/mixins/errorNotificationMixin';
import successNotificationMixin from '@/mixins/successNotificationMixin';
import Notification from '@/components/common/Notification';
import BlockPreloader from '@/components/common/BlockPreloader';
import RouteNamesService from '@/services/route-names-service';
import productRedirect from '../../utils/productRedirect';
import getProductByCode from '../../mixins/getProductByCode';

export default {
  name: 'PinCodeChanging',
  components: { BlockPreloader, Notification, ButtonsBlock },
  mixins: [blockPreloaderMixin, errorNotificationMixin, successNotificationMixin, getProductByCode],
  data() {
    return {
      ipnShow: false,
      show2: false,
      show3: false,
      itn: '',
      pin2: '',
      pin3: '',
      errorNotificationToogle: false,
    };
  },
  computed: {
    ...mapState('auth', [
      'timerTime',
      'authError',
      'globalErrors',
      'serverError',
      'isPinCodeSet',
    ]),
    ...mapGetters('auth', [
      'pinCodeSet',
    ]),
  },
  mounted() {
    this.disableTabKeyForEyeIcons();
  },
  methods: {
    ...mapActions('auth', [
      'setNewPinCode',
      'getClientMainInfo',
    ]),
    ...mapActions('cashForm', [
      'getActiveOrderStore',
    ]),
    ...mapActions('auth', [
      'logout',
    ]),
    toggleErrorNotification() {
      this.errorNotificationToogle = true;
      const interval = setTimeout(() => {
        this.errorNotificationToogle = false;
        clearTimeout(interval);
      }, 5000);
    },
    disableTabKeyForEyeIcons() {
      document.querySelectorAll('.password-form .v-icon.mdi').forEach((item) => {
        item.setAttribute('tabindex', '-1');
      });
    },
    onPinPopUpClose() {
      this.$emit('onPinPopUpClose');
    },
    clearPinCodes() {
      // eslint-disable-next-line no-multi-assign
      this.pin1 = this.pin2 = this.pin3 = '';
      this.$refs.observer.reset();
    },
    handleProductRedirect() {
      this.$router.push({
        name: 'Redirect',
        query: {
          product: productRedirect.getJWTFromProductParam(),
        },
      });
    },
    async handleCommonRedirect() {
      let path = RouteNamesService.cashForm;
      const getClientMainInfoResult = await this.getClientMainInfo();
      if (getClientMainInfoResult) {
        path = RouteNamesService.userCredits;
      }
      if (this.$router.history.current.name !== path) {
        await this.$router.push({ name: path });
      }
    },
    async submit() {
      try {
        const isValid = await this.$refs.observer.validate();
        if (isValid) {
          if (this.isPinCodeSet) {
            this.toggleBlockPreloader(true);
            await this.$store.dispatch('auth/setNewPinCode', { itn: this.itn, newPin: this.pin2 })
              .then((res) => {
                console.log(res);
                if (res.success === true) {
                  this.toggleSuccessMessage();
                  const timeout = setTimeout(() => {
                    this.toggleBlockPreloader(false);
                    this.logout();
                    clearTimeout(timeout);
                  }, 2000);
                } else {
                  this.toggleErrorNotification();
                  this.toggleBlockPreloader(false);
                }
              })
              .catch(() => {
                this.toggleErrorMessage();
              });
          }
        }
      } catch (error) {
        this.toggleBlockPreloader(false);
        this.toggleErrorMessage();
        console.error(error);
      }
    },
    clear() {
      this.pin1 = '';
      this.pin2 = '';
      this.$refs.observer.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  @media screen and (min-width: 600px) {
    min-width: 500px;
  }
}

.close-btn {
  cursor: pointer;
}
</style>
