import Vue from 'vue';
import VueRouter from 'vue-router';
import RouteNamesService from '@/services/route-names-service';
import PageForRedirect from '../views/PageForRedirect';
import Identification from '../views/UserIdentification';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: RouteNamesService.userCredits,
    component: () => import(
      /* webpackChunkName: "user-credits" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default", "named"] */
      '@/views/user-credits'
    ),
  },
  {
    path: '/your-profile',
    name: RouteNamesService.userProfile,
    component: () => import(
      /* webpackChunkName: "user-profile" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default", "named"] */
      '@/views/user-profile'
    ),
  },
  {
    path: '/your-bonuses',
    name: RouteNamesService.userBonuses,
    component: () => import(
      /* webpackChunkName: "user-bonuses" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default", "named"] */
      '@/views/UserBonuses'
    ),
  },
  {
    path: '/your-bank-cards',
    name: RouteNamesService.userBankCards,
    component: () => import(
      /* webpackChunkName: "user-bank-cards" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default", "named"] */
      '@/views/UserBankCards'
    ),
  },
  {
    path: '/history',
    name: RouteNamesService.creditHistory,
    component: () => import(
      /* webpackChunkName: "credit-history" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default", "named"] */
      '@/views/credit-history'
    ),
  },
  /* {
    path: '/confirmation',
    name: 'ConfirmationOfCreditConditions',
    component: () => import(
      /!* webpackChunkName: "confirmation-of-credit-conditions" *!/
      /!* webpackMode: "lazy" *!/
      /!* webpackExports: ["default", "named"] *!/
      '@/views/ConfirmationOfCreditConditions'
    ),
  }, */
  // {
  //   path: '/identification/:currentOrderId',
  //   name: RouteNamesService.userIdentification,
  //   component: () => import(
  //     /* webpackChunkName: "user-identification" */
  //     /* webpackMode: "lazy" */
  //     /* webpackExports: ["default", "named"] */
  //     '@/views/UserIdentification'
  //   ),
  //   props: true,
  // },
  {
    path: '/identification/:currentOrderId',
    name: RouteNamesService.userIdentification,
    component: Identification,
  },
  {
    path: '/signing-a-contract/:currentOrderId',
    name: RouteNamesService.contractSigning,
    component: () => import(
      /* webpackChunkName: "contract-signing" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default", "named"] */
      '@/views/contract-signing/index'
    ),
    props: true,
  },
  // скрыт до получения доступа к сервису
  /* {
    path: '/registration-via-bank-id',
    name: 'RegistrationViaBankId',
    component: () => import(
      /!* webpackChunkName: "registration-via-bank-id" *!/
      /!* webpackMode: "lazy" *!/
      /!* webpackExports: ["default", "named"] *!/
      '@/views/RegistrationViaBankId'
    ),
  }, */
  {
    path: '/registration-of-a-cash-loan',
    name: RouteNamesService.cashForm,
    component: () => import(
      /* webpackChunkName: "cash-form" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default", "named"] */
      '@/views/CashForm'
    ),
  },
  {
    path: '/linking-a-bank-card',
    name: RouteNamesService.linkBankCard,
    component: () => import(
      /* webpackChunkName: "link-bank-card" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default", "named"] */
      '@/views/link-bank-card'
    ),
  },
  {
    path: '/add-a-new-bank-card',
    name: RouteNamesService.addNewBankCard,
    component: () => import(
      /* webpackChunkName: "add-a-new-bank-card" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default", "named"] */
      '@/views/AddNewBankCard'
    ),
  },
  {
    path: '/filing-an-application',
    name: RouteNamesService.creditCalculator,
    component: () => import(
      /* webpackChunkName: "credit-calculator" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default", "named"] */
      '@/views/CreditCalculator'
    ),
  },
  // {
  //   path: '/redirect',
  //   name: RouteNamesService.pageForRedirect,
  //   component: () => import(
  //     /* webpackChunkName: "page-for-redirect" */
  //     /* webpackMode: "lazy" */
  //     /* webpackExports: ["default", "named"] */
  //     '@/views/PageForRedirect'
  //   ),
  // },
  {
    path: '/redirect',
    name: RouteNamesService.pageForRedirect,
    component: PageForRedirect,
  },

  {
    path: '/paymentResult',
    name: RouteNamesService.paymentResult,
    component: () => import(
      /* webpackChunkName: "payment-result" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default", "named"] */
      '@/views/payment-results/paymentResult'
    ),
  },
  {
    path: '/FAQ',
    name: RouteNamesService.FAQ,
    component: () => import(
      /* webpackChunkName: "faq" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default", "named"] */
      '@/views/frequentlyAskedQuestions'
    ),
  },
  {
    path: '/systemMessages',
    name: RouteNamesService.systemMessages,
    component: () => import(
      /* webpackChunkName: "system-messages" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default", "named"] */
      '@/views/system-messages/index'
    ),
  },
  {
    path: '*',
    name: RouteNamesService.lostPage,
    component: () => import(
      /* webpackChunkName: "lost-page" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default", "named"] */
      '@/views/LostPage'
    ),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes,
});

export default router;
