import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ['@/styles/variables.scss'],
  treeShake: true,
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      dark: {
        background: '#1f1f1f',
        secondBg: '#111111',
        text: '#fff',
        loader: '#1f1f1f',
        white: '#fff',
        darkGray: '#bfbfbf',
        stepperDivider: '#8c8c8c',
        success: '#12b422',
        darkMain: '#111111',
        darkSecond: '#171717',
        appBar: '#0c0c0c',
        cardRed: '#d43149',
        cardBlue: '#249EFA',
        cardLightGray: '#BFBFBF',
        cardDarkGray: '#3B3B3B',
        cardYellow: '#ffd600',
        lightGray: '#efefef',
      },
      light: {
        background: '#fff',
        secondBg: '#efefef',
        text: '#424242',
        loader: '#ebebeb',
        white: '#fff',
        darkGray: '#bfbfbf',
        stepperDivider: '#4f4f4f',
        success: '#12b422',
        darkMain: '#111111',
        darkSecond: '#171717',
        appBar: '#0c0c0c',
        cardRed: '#d43149',
        cardBlue: '#249EFA',
        cardLightGray: '#BFBFBF',
        cardDarkGray: '#3B3B3B',
        cardYellow: '#ffd600',
        lightGray: '#efefef',
      },
    },
  },
});
