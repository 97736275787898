var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper background mx-auto"},[(_vm.errorNotification)?_c('notification',{attrs:{"type":"error","color":"red"}},[_vm._v(" Щось пішло не так. Спробуйте ще раз ")]):_vm._e(),_c('v-card-title',{staticClass:"card-title success py-2 py-sm-3 px-3 text-center body-1 font-weight-medium\n    text-sm-h6 justify-end white--text"},[_c('v-icon',{staticClass:"close-btn white--text ",on:{"click":_vm.popUpClosedHandler}},[_vm._v(" mdi-close ")])],1),_c('div',{staticClass:"position-relative"},[((_vm.loaderIsDisplaying && _vm.bankCardsAreUploaded === false) || _vm.blockPreloaderIsVisible)?_c('block-preloader',{staticClass:"border-radius-10",attrs:{"background-color":"background"}}):_vm._e(),_c('v-card-text',{staticClass:"content pt-2 pt-sm-5 pa-0 px-4 text-justify"},[(_vm.active_cards.length)?_c('v-subheader',{staticClass:"text--text height-auto body-1 text-md-h6 text-center d-block mb-3"},[_c('span',{staticClass:"text--text"},[_vm._v("Оберіть одну з Ваших карт")])]):_c('div',{staticClass:"d-flex flex-column"},[_c('p',{staticClass:"text-center body-2 mt-2 mb-0 mt-sm-0 text-sm-subtitle-1\n       text--text mb-2"},[_vm._v(" Ви ще не додали жодної карти ")]),(!_vm.active_cards.length)?_c('v-btn',{staticClass:"add_card_btn",attrs:{"color":"success","rounded":"","to":{ name: _vm.addNewBankCardPath }}},[_vm._v(" Додати картку ")]):_vm._e()],1),_c('v-row',{attrs:{"no-gutters":""}},[(_vm.active_cards.length >= 0)?_c('validation-observer',{ref:"observer",staticClass:"col-12 col-sm-10 mx-auto"},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
return [_c('v-radio-group',{staticClass:"d-flex pa-0 mt-3 col-12",model:{value:(_vm.selectedCardId),callback:function ($$v) {_vm.selectedCardId=$$v},expression:"selectedCardId"}},[_c('v-divider',{staticClass:"cardDarkGray lighten-3 mb-5"}),_vm._l((_vm.active_cards),function(card,index){return _c('v-col',{key:index,staticClass:"pa-0 d-flex align-center col-auto mb-3 px-1 px-sm-2"},[_c('v-radio',{staticClass:"ma-0",attrs:{"id":("" + (card.silver_id)),"color":"success","value":card.silver_id,"error":!_vm.selectedCardId && validated,"success":_vm.selectedCardId && validated}}),_c('label',{staticClass:"ma-0 text--text",attrs:{"for":("" + (card.silver_id))}},[_vm._v(" "+_vm._s(card.card_mask)+" ")])],1)})],2),(_vm.selectCardNotation)?_c('span',{staticClass:"error-msg error--text v-messages pl-8 mt-n8 position-absolute"},[_vm._v(" Цей пункт обов'язковий ")]):_vm._e()]}}],null,false,1139138337)}),_c('validation-provider',{attrs:{"name":"Номер кредиту*","rules":"required|alphaNum"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validate = ref.validate;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","type":"text","color":"text","label":"Номер кредиту*","error-messages":errors,"success":valid && _vm.creditId !== ''},on:{"input":function($event){return validate($event);}},model:{value:(_vm.creditId),callback:function ($$v) {_vm.creditId=$$v},expression:"creditId"}})]}}],null,false,311624726)}),_c('validation-provider',{attrs:{"name":"Сума","rules":("required|onlyNumbers|minValue:" + (_vm.isFullPaymentOfTheLoan? _vm.loanDebt:0) + "|")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validate = ref.validate;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","label":"Сума*","color":"text","item-color":"text","error-messages":errors,"menu-props":{ offsetY: false },"success":valid && _vm.sum !== ''},on:{"change":validate},model:{value:(_vm.sumValue),callback:function ($$v) {_vm.sumValue=$$v},expression:"sumValue"}})]}}],null,false,1476704976)})],1):_vm._e()],1)],1),_c('v-row',{staticClass:"ma-0 px-2 pb-4 "},[_c('v-row',{staticClass:"justify-space-around mt-5 pb-3 d-flex buttonCont"},[(_vm.active_cards.length > 0)?_c('v-btn',{staticClass:"butt",attrs:{"color":"success","rounded":"","disabled":_vm.onSFpayPress},on:{"click":_vm.aheadClickHandler}},[_vm._v(" оплатити ")]):_vm._e(),_c('div',{staticClass:"justify-space-around mt-5 pb-3 d-flex buttonCont butt"},[(_vm.active_cards.length > 0)?_c('span',{staticClass:"text_partner text--text"},[_vm._v(" або сплатити на ")]):_vm._e(),_c('v-btn',{staticClass:" mr-2 ml-2 ",attrs:{"color":"success","outlined":"","rounded":"","disabled":_vm.onSFpayPress},on:{"click":function($event){return _vm.submit(true)}}},[_vm._v(" "+_vm._s(_vm.active_cards.length > 0 ? 'сторінці партнера' : 'сплатити на сторінці партнера')+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }