export default {
  methods: {
    getProductByCode(data = [], productCode) {
      if (!Array.isArray(data)) return false;
      let cashOnlineObj = {};

      data.forEach((item) => {
        if (item.product === productCode) {
          cashOnlineObj = item;
        }
      });

      return cashOnlineObj;
    },
  },
};
