<template>
  <v-carousel
    hide-delimiters
    show-arrows-on-hover
    :show-arrows="slides.length > 1"
  >
    <v-carousel-item v-for="(slide, i) in slides" :key="i">
      <div id="div-bg" class="carouselStyle"
           v-bind:style="{ 'background-image': 'url(' + slide.background_image + ')',
            backgroundColor: slide.background_color }">
      <div
        class="cardBg"
      >
        <v-alert v-if="notification"
                 elevation="5"
                 color="green"
                 type="success"
                 class="alert_class"
        >Дякуємо за вашу відповідь!)
        </v-alert>
        <v-card-title :style="{ 'color': slide.text_color }">
          {{ slide.title }}
        </v-card-title>
        <v-icon class="closeIcon"
                :style="{ 'color': slide.text_color }"
                @click="onCloseHandler(slide.message_id)">
          mdi-close-circle-outline
        </v-icon >
        <v-divider/>
        <div class="text_container">
        <v-card-text v-html="slide.text_message"
                     class="textStyle" :style="{ 'color': slide.text_color }">
<!--          {{ slide.textMessage }}-->
        </v-card-text>
        </div>
      </div>
        <strong v-if="slides.length > 1" class="currentNew">{{i+1 + "/" + slides.length}}</strong>
      <div class="buttonsBlock">
        <h5 :style="{ 'color': slide.text_color }">цікаво?</h5>
        <v-btn
          v-for="(btn, i) in slide.buttons"
          :key="i"
          :loading="loading"
          :disabled="loading"
          :color= btn.background_color
          class="ml-3 button_style"
          @click="reflectionButtonHandler(
            slide.message_id,
          btn.handler,
          referenceBooks('dict_comm_module_reflection_type')[3].code )"
        >
          <span :style="{ 'color': btn.text_color }">{{ btn.button_name }}</span>
          <font-awesome-icon :icon="btn.icon" :style="{ 'color': btn.text_color }" />
          <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
          </template>
        </v-btn>
      </div>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MessageInPopUp',
  title: 'Повідомлення',
  data() {
    return {
      loader: null,
      loading: false,
      notification: false,
    };
  },
  computed: {
    ...mapGetters('userMessages', [
      'messageButtons',
      'requestedMessage',
    ]),
    ...mapGetters([
      'referenceBooks',
    ]),
  },
  methods: {
    nextSlideHandler() {
      const nextButton = document.querySelector('.v-window__next button');
      if (nextButton !== null) {
        nextButton.click();
      }
    },
    onCloseHandler(messageId) {
      this.reflectionButtonHandler(messageId, '', this.referenceBooks('dict_comm_module_reflection_type')[4].code);
      this.switchNewMessageNotification(false);
    },
    ...mapActions(['getReferenceBookStore']),
    ...mapActions('userMessages', [
      'sendReactionOfUserOnMessage',
      'switchNewMessageNotification',
      'cleanRequestedMessages',
    ]),
    notificationHandler() {
      this.notification = true;
      const timeout = setTimeout(() => {
        this.notification = false;
        if (this.slides.length === 1) {
          this.$emit('PopUpclose');
        }
        if (this.slides.length > 1) {
          this.nextSlideHandler();
        }
        clearTimeout(timeout);
      }, 2000);
    },
    reflectionButtonHandler(messageId, handler, reflectionCode) {
      if (reflectionCode !== 'message_viewed' && reflectionCode !== 'message_closed') {
        this.loader = 'loading';
        this.notificationHandler(messageId);
      } else {
        this.$emit('PopUpclose');
      }
      const payloadForViewed = ({
        messageId,
        buttonHandler: '',
        reflection: this.referenceBooks('dict_comm_module_reflection_type')[2].code,
      });
      const payload = ({
        messageId,
        buttonHandler: handler,
        reflection: reflectionCode,
      });
      if (reflectionCode !== 'message_viewed') {
        this.sendReactionOfUserOnMessage(payload);
        this.sendReactionOfUserOnMessage(payloadForViewed);
      } else {
        this.sendReactionOfUserOnMessage(payload);
      }
    },
  },
  props: {
    slides: {
      type: Array,
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      // eslint-disable-next-line no-return-assign
      const timer = setTimeout(() => { (this[l] = false); clearTimeout(timer); }, 3000);
      this.loader = null;
    },
  },
  beforeDestroy() {
    this.cleanRequestedMessages();
  },
};
</script>
<style lang="scss" scoped>
.textStyle{
    max-width: 50%;
    word-break: keep-all;
    font-weight: 400;
    font-size: 1rem;
  }
.buttonsBlock{
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  align-items: center;
}
.closeIcon{
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  font-size: xx-large;
}
.carouselStyle{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  flex-wrap: wrap;
  padding: 20px 10px 15px 10px;
  min-width: 500px;
}
#div-bg {
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.alert_class{
  max-width: 80%;
  position: absolute;
  top: 10px;
  left: 10px;
}
.currentNew{
  color: #494646;
  position: absolute;
  bottom: 10px;
  left: 20px;
  font-size: 24px;
}
@media screen and (max-width: 840px) {
  .textStyle {
    max-width: 350px;
    word-break: keep-all;
  }
}
@media screen and (max-width: 740px) {
  .alert_class{
    max-width: 100%;
  }
  .carouselStyle{
    min-width: 350px;
  }
.textStyle {
  word-break: keep-all;
  }
  .buttonsBlock {
    flex-direction: column;
    margin: 0 auto;
    .button_style{
      margin-bottom: 5px;
    }
  }
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
