<template>
  <v-overlay
    fixed
    :dark="$vuetify.theme.dark"
    opacity="0.7"
    class="px-5"
  >
    <v-scale-transition
      mode="in-out"
    >
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            :elevation="hover ? onHover : shadow"
            class="card border-radius-10"
          >
            <slot></slot>
          </v-card>
        </template>
      </v-hover>
    </v-scale-transition>
  </v-overlay>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PopUpWindow',
  props: {
    shadow: {
      type: Number,
      default: 24,
    },
    onHover: {
      type: Number,
      default: 24,
    },
    rounded: {
      type: String,
      default: 'xl',
    },
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
  },
};
</script>

<style lang="scss" scoped>

</style>
