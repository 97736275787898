export default {
  data() {
    return {
      blockPreloaderIsVisible: false,
    };
  },
  beforeDestroy() {
    this.blockPreloaderIsVisible = false;
  },
  methods: {
    toggleBlockPreloader(boolValue) {
      this.blockPreloaderIsVisible = boolValue;
    },
  },
};
