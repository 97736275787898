export default class RouteNamesService {
  static pageForRedirect = 'PageForRedirect';

  static userCredits = 'UserCredits';

  static userProfile = 'UserProfile';

  static userBonuses = 'UserBonuses';

  static userBankCards = 'UserBankCards';

  static creditHistory = 'CreditHistory';

  static userIdentification = 'UserIdentification';

  static contractSigning = 'ContractSigning';

  static cashForm = 'CashForm';

  static linkBankCard = 'LinkBankCard';

  static addNewBankCard = 'AddNewBankCard';

  static creditCalculator = 'CreditCalculator';

  static lostPage = 'LostPage';

  static paymentResult = 'paymentResult';

  static systemMessages = 'systemMessages';

  static FAQ = 'frequentlyAskedQuestions';
}
