<template>
  <div class="wrapper cardDarkGray mx-auto">
    <v-card-title
      class="card-title success py-2 py-sm-4 px-3 darken-1 text-center body-1 font-weight-medium
      text-sm-h6 text-sm-left"
    >
    </v-card-title>

    <v-card-text class="content pt-2 pt-sm-3 text-justify custom-scroll">
      Шановний клієнт! Ви можете здійснити оплату по кредиту у будь-якому відділенні,
      будь якого банку України, через термінали I-box, Easy Pay, City24.
      Через відділення компанії "Нова Пошта"
      та через
      <v-btn
        class="go-to-cabinet-btn pa-0 text-lowercase font-weight-regular success--text"
        plain
        @click="onPopUpClose"
      >
        особистий кабінет
      </v-btn>
      на сайті нашої компанії
    </v-card-text>

    <v-row class="ma-0">
      <p class="divider col-12 cardLightGray pa-0 ma-0" />
      <v-btn
        rounded
        color="success darken-1"
        class="my-3 mx-auto"
        @click="$emit('onPopUpClose')"
      >
        Продовжити
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import RouteNamesService from '@/services/route-names-service';

export default {
  name: 'HowToPay',
  methods: {
    onPopUpClose() {
      this.$emit('onPopUpClose');
      if (this.$router.history.current.name !== RouteNamesService.userCredits) {
        this.$router.push({ name: RouteNamesService.userCredits });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 90%;

  @media screen and (min-width: 600px) {
    width: 500px;
  }
}

.content {
  max-height: 55vh;
  overflow-y: auto;
}

.card-title {
  border-radius: 10px 10px 0 0;
}

.divider {
  height: 1px;
}

.go-to-cabinet-btn {
  letter-spacing: 0;
  height: auto !important;
  margin-top: -2px;

  &:hover {
    text-decoration: underline;
  }
}
</style>
