<template>
  <div class="col-12 pa-0">
    <v-row>
      <nav-items />
      <app-bar />
    </v-row>
  </div>
</template>

<script>
import NavItems from '@/components/header/auxiliary-items/NavItems';
import AppBar from '@/components/header/auxiliary-items/AppBar';

export default {
  name: 'DesktopMenu',
  components: { AppBar, NavItems },
};
</script>

<style scoped>

</style>
