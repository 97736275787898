// eslint-disable-next-line import/named
import {
  SAVE_CLIENT_MESSAGES,
  SAVE_REDIRECT_AND_MESSAGE_DATA,
  SAVE_REQUESTED_MESSAGE,
  CLEAN_REQUESTED_MESSAGES,
  CLEAN_CLIENT_MESSAGES_STATE,
  MESSAGE_NOTIFICATION_SWITCHER,
} from '@/store/userMessages/mutation-types';
import sortArrByDateAndTime from '../../utils/sortArrByDateAndTime';
// eslint-disable-next-line import/named
import { MassagesApi } from '../../api/Rest';

const getDefaultState = () => ({
  messages: [],
  appAction: 'invitation',
  requestedMessage: [],
  showNewMessagesHandler: false,
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    [SAVE_CLIENT_MESSAGES](state, { message }) {
      state.messages.push(...message);
    },
    [CLEAN_CLIENT_MESSAGES_STATE](state) {
      state.messages = [];
    },
    [SAVE_REDIRECT_AND_MESSAGE_DATA](state, messInfo) {
      state.appAction = messInfo.action;
      state.messageId = messInfo.objectId;
    },
    [SAVE_REQUESTED_MESSAGE](state, achievedArray) {
      state.requestedMessage = achievedArray;
    },
    [CLEAN_REQUESTED_MESSAGES](state) {
      state.requestedMessage = [];
    },
    [MESSAGE_NOTIFICATION_SWITCHER](state, isOn) {
      state.showNewMessagesHandler = isOn;
    },
  },
  actions: {
    getAllUserMessages({ commit }) {
      MassagesApi.getMessages().then((res) => {
        if (res.success === true) {
          const receivedMessages = res.payload;
          if (receivedMessages) {
            commit(CLEAN_CLIENT_MESSAGES_STATE);
            const arr = [];
            receivedMessages.forEach((item) => {
              arr.push(item);
            });
            const sortedArr = sortArrByDateAndTime(arr);
            commit(SAVE_CLIENT_MESSAGES, {
              message: sortedArr,
            });
          }
        }
      })
        .catch((err) => { console.error(err); });
    },

    sendInvitationQueryData({ commit }, payload) {
      return new Promise((res, rej) => {
        MassagesApi.sendInvitationFromQuery(payload).then((data) => {
          if (data.success === true) {
            const messInfo = data;
            res(messInfo);
            commit(SAVE_REDIRECT_AND_MESSAGE_DATA, messInfo);
          } else {
            rej();
          }
        });
      }).catch((err) => {
        console.error(err);
      });
    },
    requestForNewMessages() {
      return new Promise((res, rej) => {
        MassagesApi.updateMessageState().then((data) => {
          if (data.success === true) {
            res(data);
          } else {
            rej();
          }
        }).catch((err) => {
          console.error(err);
        });
      });
    },
    requestForExactMessage({ commit }, payload) {
      commit(CLEAN_REQUESTED_MESSAGES);
      return new Promise((res) => {
        MassagesApi.getExactMessage(payload).then((data) => {
          const requestedMessage = data.payload;
          if (!requestedMessage) {
            res(data);
          }
          if (requestedMessage.length === 1) {
            commit(SAVE_REQUESTED_MESSAGE, requestedMessage);
            res(data);
          }
          const sortedArr = sortArrByDateAndTime(requestedMessage);
          res(sortedArr);
          commit(SAVE_REQUESTED_MESSAGE, sortedArr);
        }).catch((err) => console.error(err));
      });
    },
    // eslint-disable-next-line no-unused-vars
    sendReactionOfUserOnMessage({ commit }, payload) {
      return new Promise(() => {
        MassagesApi.sandClientsReflection(payload).then(() => {
        }).catch((err) => {
          console.error(err);
        });
      });
    },
    cleanRequestedMessages({ commit }) {
      commit(CLEAN_REQUESTED_MESSAGES);
    },
    switchNewMessageNotification({ commit }, isOn) {
      commit(MESSAGE_NOTIFICATION_SWITCHER, isOn);
    },
  },
  getters: {
    messages: (state) => state.messages,
    redirectPath: (state) => state.redirectPath,
    messageId: (state) => state.messageId,
    requestedMessage: (state) => state.requestedMessage,
    showNewMessagesHandler: (state) => state.showNewMessagesHandler,
  },
};
