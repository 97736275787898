export default {
  data() {
    return {
      buttonIsActive: true,
    };
  },
  methods: {
    makeTheButtonInactive() {
      this.buttonIsActive = false;
    },
    makeTheButtonActive() {
      this.buttonIsActive = true;
    },
  },
};
